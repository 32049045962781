import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import jobifiedAuthConfig from "../../axiosConfig";
import SectionLoader from "../helpers/SectionLoader";

import CollegeCard from "../includes/CollegeCard";
import AddCollegeModal from "../includes/modals/AddCollegeModal";

function AssociatedColleges() {
    const [items, setItems] = useState([]);

    // editing option
    const [isEdit, setEdit] = useState(false);

    // add new college
    const [isAdd, setAdd] = useState(false);

    // pagination_______________________
    const [pageNumber, setPageNumber] = useState(1);
    const [paginate, setPaginate] = useState({
        startingCount: 0,
        endingCount: 0,
        totalCount: 0,
        totalItems: 0,
    });
    const [searchKeyword, setSearch] = useState("");
    const [isLoading, setLoading] = useState(false);

    const fetchCampuses = () => {
        setLoading(true);

        jobifiedAuthConfig
            .get("/campuses/chief/campuses/", {
                params: {
                    page: pageNumber,
                    q: searchKeyword,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                console.log(data);

                if (statusCode === 6000) {
                    setItems(data.data);
                    setPaginate({
                        endingCount: data.last_item,
                        startingCount: data.first_item,
                        totalCount: data.total_pages,
                        totalItems: data.total_items,
                    });
                }

                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCampuses();
    }, [pageNumber]);

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    };

    return (
        <>
            {isAdd && (
                <AddCollegeModal
                    setAdd={setAdd}
                    setPageNumber={setPageNumber}
                />
            )}
            <Container>
                <Section>
                    <TopSection>
                        <Heading>Associated Colleges</Heading>
                        <RightSection>
                            <SearchSection>
                                <img
                                    src={
                                        require("../../assets/images/search-normal.svg")
                                            .default
                                    }
                                    alt="search"
                                />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={onSearchChange}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            fetchCampuses();
                                            console.log(searchKeyword);
                                        }
                                    }}
                                />
                            </SearchSection>
                            <Edit
                                onClick={() => {
                                    setEdit(!isEdit);
                                }}
                            >
                                Edit
                            </Edit>
                            <Add onClick={() => setAdd(!isAdd)}>Add</Add>
                        </RightSection>
                    </TopSection>

                    <MiddleSection>
                        <Bottom className={isLoading ? "loading" : ""}>
                            {isLoading ? (
                                <SectionLoader status="followup_executive" />
                            ) : (
                                <>
                                    <Contents>
                                        <CollegeCard
                                            isEdit={isEdit}
                                            item={items}
                                            setItems={setItems}
                                        />
                                    </Contents>
                                </>
                            )}
                            <Pages className={isLoading ? "loading" : ""}>
                                <Left>
                                    <LeftSection>
                                        Showing{" "}
                                        <span>{paginate.startingCount}</span> to{" "}
                                        <sapn>{paginate.endingCount}</sapn> of{" "}
                                        <span>{paginate.totalItems}</span>{" "}
                                        entries
                                    </LeftSection>
                                </Left>
                                <Right>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={paginate.totalCount}
                                        onPageChange={({ selected }) =>
                                            setPageNumber(selected + 1)
                                        }
                                        containerClassName={"paginationButtons"}
                                        previousLinkClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        breakLabel={"..."}
                                        disabledLinkClassName="disabled-button"
                                        breakLinkClassName="break-link"
                                        breakClassName={"item break-me "}
                                        nextClassName={"item next"}
                                    />
                                </Right>
                            </Pages>
                        </Bottom>
                    </MiddleSection>
                </Section>
            </Container>
        </>
    );
}

export default AssociatedColleges;
const Container = styled.div`
    padding: 58px 0 0 273px;
    @media all and (max-width: 1280px) {
        padding-left: 81px;
    }
`;
const Section = styled.div`
    padding: 30px 30px 0 30px;
    min-height: 90vh;
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 26px;
    margin-bottom: 8px;
    @media all and (max-width: 1280px) {
        font-size: 24px;
    }
    @media all and (max-width: 1080px) {
        font-size: 22px;
    }

    @media all and (max-width: 980px) {
        font-size: 20px;
    }
`;
const RightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 37%;
    @media all and (max-width: 1280px) {
        width: 45%;
    }
    @media all and (max-width: 1080px) {
        font-size: 22px;
    }

    @media all and (max-width: 980px) {
        font-size: 20px;
        width: 53%;
    }
`;
const SearchSection = styled.div`
    width: 60%;
    border-radius: 8px;
    border: 2px solid #c4c1c1;
    height: 38px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    img {
        width: 20px;
        display: block;
        margin-right: 10px;
    }
    input {
        width: 100%;
        &::placeholder {
            color: #adadad;
            font-size: 14px;
            @media all and (max-width: 980px) {
                font-size: 12px;
            }
        }
    }
    @media all and (max-width: 980px) {
        width: 60%;
        height: 30px;
    }
`;
const Edit = styled.span`
    color: #747474;
    font-family: gordita_medium;
    font-size: 14px;
    border: 2px solid #c4c1c1;
    height: 38px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        width: 70px;
    }
    @media all and (max-width: 980px) {
        width: 57px;
        height: 30px;
        font-size: 12px;
    }
`;
const Add = styled.span`
    color: #fff;
    font-family: gordita_medium;
    font-size: 14px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    height: 38px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media all and (max-width: 1080px) {
        width: 70px;
    }
    @media all and (max-width: 980px) {
        width: 57px;
        height: 30px;
        font-size: 12px;
    }
`;
const Div = styled.div`
    height: 66vh;
`;
const MiddleSection = styled.div`
    /* margin-top: 30px; */
`;
const Bottom = styled.div`
    height: 75vh;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    color: #fff;
    /* height: fit-content; */
    padding: 20px 0;
    position: relative;
    justify-content: space-between;

    &.loading {
        justify-content: center;
        align-items: center;
    }
    @media all and (max-width: 1280px) {
        height: 77vh;
    }
    @media all and (max-width: 1080px) {
        height: 82vh;
    }
`;
const Left = styled.div``;
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    width: 230px;
    .paginationButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;

        .disabled-button {
            cursor: not-allowed;
            /* background: #808080; */
        }
        li {
            width: 30px;
            color: #747474;
            font-size: 14px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            a {
                width: 30px;
                color: #747474;
                font-size: 14px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                :hover {
                    background: #2da99b;
                    border-radius: 4px;
                    color: #fff;
                }
            }
            &.paginationActive > a {
                background: #2da99b;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
`;
const Pages = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 45px;

    &.loading {
        display: none;
    }
`;
const Contents = styled.div`
    width: 100%;
    gap: 25px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 72vh;
    @media all and (max-width: 1280px) {
        grid-template-columns: 1fr 1fr;
        height: 68vh;
        gap: 30px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 1080px) {
        grid-template-columns: 1fr 1fr;
    }
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    @media all and (max-width: 1280px) {
        margin-top: 18px;
    }
    @media all and (max-width: 1080px) {
        margin-top: 20px;
    }
`;
const LeftSection = styled.div`
    color: #adadad;
    span {
        font-size: 14px;
        color: #adadad;
    }
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
`;
const Numbers = styled.span`
    width: 30px;
    color: #747474;
    font-size: 14px;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
        background: #2da99b;
        border-radius: 4px;
        color: #fff;
    }
`;
const Arrow = styled.div`
    width: 8px;
    cursor: pointer;
`;
