import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

import jobifiedAuthConfig from "../../../axiosConfig";
import SectionLoader from "../../helpers/SectionLoader";

function EditCollegeModal({
    setCollege,
    activeCampusId,
    activeCampus,
    updateCampusHandler,
}) {
    const [edit, setEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        name: activeCampus.name,
        campusCode: activeCampus.campus_code,
        phone: activeCampus.contact_number,
        location: activeCampus.location,
        pinCode: activeCampus.pin_code,
        image: {
            name: "",
            src: "",
            preview: activeCampus.image,
        },
    });

    const inputRef = useRef(null);

    const fetchCampus = () => {
        setLoading(true);

        jobifiedAuthConfig
            .get(`/campuses/chief/campuses/${activeCampusId}/`)
            .then((res) => {
                const { statusCode, data } = res.data;

                if (statusCode === 6000) {
                    setInputs({
                        name: data.data.name,
                        campusCode: data.data.campus_code,
                        location: data.data.location,
                        phone: data.data.contact_number,
                        pinCode: data.data.pin_code,
                        image: {
                            name: "",
                            preview: data.data.image,
                            src: "",
                        },
                    });
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    // useEffect(() => {
    //     fetchCampus()
    // }, [])

    const onInputChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    };

    function handleFileChange(event) {
        if (event.target.files.length) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setInputs({
                    ...inputs,
                    image: {
                        name: event.target.files[0].name,
                        src: event.target.files[0],
                        preview: e.target.result,
                    },
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    const submitHandler = () => {
        const formData = new FormData();

        formData.append("name", inputs.name);
        formData.append("contact_number", inputs.phone);
        formData.append("pin_code", inputs.pinCode);
        formData.append("location", inputs.location);

        if (!!inputs.image.src) {
            formData.append("image", inputs.image.src);
        }

        for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }

        jobifiedAuthConfig
            .post(`/campuses/chief/campuses/edit/${activeCampusId}/`, formData)
            .then((res) => {
                const { statusCode, data } = res.data;
                if (statusCode === 6000) {
                    updateCampusHandler(data.data);
                    setCollege(false);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <>
            <Container>
                <Overlay
                    onClick={() => {
                        setCollege(false);
                    }}
                ></Overlay>

                <Section className={isLoading ? "loading" : ""}>
                    {isLoading ? (
                        <SectionLoader status="followup_executive" />
                    ) : (
                        <>
                            <TopSection>
                                <Title>Edit Associated College</Title>
                            </TopSection>
                            {edit ? (
                                <EditSection>
                                    <LeftSection>
                                        <ImgSection>
                                            <img
                                                src={
                                                    inputs.image.preview ??
                                                    require("../../../assets/images/add.svg")
                                                        .default
                                                }
                                                alt=""
                                            />
                                        </ImgSection>
                                    </LeftSection>
                                    <RightSection>
                                        <div>
                                            <p>{inputs.image.name}</p>
                                            <input
                                                type="file"
                                                onChange={handleFileChange}
                                                style={{ display: "none" }}
                                                ref={inputRef}
                                            />
                                            <button
                                                onClick={() =>
                                                    inputRef.current.click()
                                                }
                                            >
                                                Upload File
                                            </button>
                                        </div>
                                        <ButtonSection className="new">
                                            <Button
                                                onClick={() =>
                                                    inputRef.current.click()
                                                }
                                                className="upload"
                                                type="button"
                                            >
                                                Upload now
                                            </Button>
                                            <ImageContainer
                                                className="image"
                                                onClick={() =>
                                                    setInputs({
                                                        ...inputs,
                                                        image: {
                                                            name: "",
                                                            preview: null,
                                                            src: "",
                                                        },
                                                    })
                                                }
                                            >
                                                <img
                                                    src={
                                                        require("../../../assets/images/delete.svg")
                                                            .default
                                                    }
                                                    alt="delete"
                                                />
                                            </ImageContainer>
                                        </ButtonSection>
                                    </RightSection>
                                </EditSection>
                            ) : (
                                <EditSection>
                                    <LeftSection>
                                        <ImgSection>
                                            <img
                                                src={
                                                    inputs.image.preview ??
                                                    require("../../../assets/images/add.svg")
                                                        .default
                                                }
                                                alt=""
                                            />
                                        </ImgSection>
                                    </LeftSection>
                                    <RightSection className="new">
                                        <Button
                                            onClick={() => {
                                                setEdit(true);
                                            }}
                                        >
                                            Upload new image
                                        </Button>
                                    </RightSection>
                                </EditSection>
                            )}

                            <EditContainer>
                                <InputSection>
                                    <Label>College Name</Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        placeholder="Enter campus name"
                                        value={inputs.name}
                                        onChange={onInputChange}
                                    />
                                </InputSection>
                                <InputSection>
                                    <Label>Reference Code</Label>
                                    <Input
                                        type="number"
                                        value={inputs.campusCode}
                                        name="campusCode"
                                        placeholder="Enter reference code"
                                        disabled
                                        // onChange={onInputChange}
                                    />
                                </InputSection>
                                <InputSection>
                                    <Label>Phone Number</Label>
                                    <UserInputs>
                                        <DropDownSection>
                                            <img
                                                src={
                                                    require("../../../assets/images/flag.svg")
                                                        .default
                                                }
                                                alt="dropdown"
                                            />
                                        </DropDownSection>
                                        <CountryCode>+91</CountryCode>
                                        <Inputs
                                            className="phone_field"
                                            type="number"
                                            placeholder="Enter phone number"
                                            value={inputs.phone}
                                            name="phone"
                                            onChange={onInputChange}
                                        />
                                    </UserInputs>
                                </InputSection>
                                <InputSection>
                                    <Label>Location</Label>
                                    <Input
                                        type="text"
                                        value={inputs.location}
                                        placeholder="Enter location"
                                        name="location"
                                        onChange={onInputChange}
                                    />
                                </InputSection>
                                <InputSection>
                                    <Label>Pin Code</Label>
                                    <Input
                                        type="text"
                                        value={inputs.pinCode}
                                        name="pinCode"
                                        placeholder="Enter pin code"
                                        onChange={onInputChange}
                                    />
                                </InputSection>
                                {/* <InputSection>
                            <Label>Selected Students</Label>
                            <Input
                                type="text"
                                disabled
                            // style={{ border: "none" }}
                            />
                        </InputSection>
                        <InputSection>
                            <Label>Subscribed Students</Label>
                            <Input type="text" disabled />
                        </InputSection> */}
                            </EditContainer>
                            <BottomSection>
                                <Cancel onClick={() => setCollege(false)}>
                                    cancel
                                </Cancel>
                                <Save onClick={submitHandler}>
                                    save changes
                                </Save>
                            </BottomSection>
                        </>
                    )}
                </Section>
            </Container>
        </>
    );
}

export default EditCollegeModal;
const videoAnimation = keyframes`
    0% { 
        transform:scale(0,0); 
        opacity:0;
     }
    100% { 
        transform:scale(1,1); 
        opacity:1;
     }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
`;
const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 45%;
    max-width: 980px;
    max-height: 94vh;
    height: 79vh;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    @media all and (max-width: 1280px) {
        width: 60%;
        height: 88vh;
    }
    @media all and (max-width: 1080px) {
        width: 60%;
    }
    @media all and (max-width: 980px) {
        width: 70%;
    }

    &.loading {
        justify-content: center;
        align-items: center;
    }
`;
const TopSection = styled.div`
    border-bottom: 2px solid #eeeeee;
    padding-bottom: 20px;
`;
const Title = styled.h4`
    color: #0a0a0a;
    font-size: 20px;
    font-family: gordita_medium;
`;
const EditSection = styled.div`
    border-radius: 8px;
    position: relative;
    padding: 24px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const UserInputs = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    height: 44px;
    padding: 0 5px;
    border-radius: 8px;
    position: relative;
    border-radius: 8px;
    position: relative;
    &:hover {
        border: 1px solid #32bcad;
    }
    &.valid-phone {
        border: 2px solid #32bcad;
    }

    @media all and (max-width: 1280px) {
        padding: 7px 12px;
    }
`;
const DropDownSection = styled.div`
    width: 50px;
    margin-right: 5px;
    cursor: pointer;
    img {
        display: block;
        width: 100%;
    }
`;
const CountryCode = styled.span`
    margin-right: 10px;
    font-size: 14px;
    font-family: gordita_medium;
    @media all and (max-width: 1080px) {
        margin-right: 5px;
        font-size: 14px;
    }
`;
const Inputs = styled.input`
    font-size: 14px;
    width: 80%;
    &::placeholder {
        font-family: gordita_regular;
        color: #adadad;
    }
`;
const LeftSection = styled.div``;
const ImgSection = styled.div`
    width: 95px;
    margin-right: 16px;

    img {
        border-radius: 50%;
    }
`;
const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85px;
    &.new {
        justify-content: center;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 12px;
            margin-right: 10px;
            border: 1px solid #d4d4d4;
            padding: 5px;
            overflow: scroll;
            border-radius: 6px;
            height: 30px;
            white-space: nowrap;
            width: 148px;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        button {
            color: #4eaaff;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    input {
        border: 1px solid #adadad;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 8px 5px;
    }
`;
const Button = styled.a`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    width: 180px;
    height: 40px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: gordita_medium;
    &.upload {
        text-align: center;
        padding: 8px 6px;
        font-size: 12px;
        width: 115px;
        margin-right: 10px;
    }
`;
const ButtonSection = styled.div`
    &.new {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;
const ImageContainer = styled.div`
    &.image {
        width: 20px;
        margin-bottom: 0;
        cursor: pointer;
    }
`;
const EditContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;
const InputSection = styled.div`
    width: 49%;
    margin-bottom: 20px;
    flex-direction: column;
    display: flex;
    /* &:last-child {
        margin-bottom: 0;
    }
    &:nth-last-child(2) {
        margin-bottom: 0;
    } */
`;
const Label = styled.label`
    color: #747474;
    font-family: gordita_medium;
    font-size: 14px;
    margin-bottom: 10px;
`;

const Input = styled.input`
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    height: 45px;
    padding: 0 10px;
    &:hover {
        border: 1px solid #32bcad;
    }

    :disabled {
        cursor: not-allowed;
    }
    /* &:last-child {
        &:hover {
            border: 1px solid #d4d4d4;
        }
    } */
`;
const BottomSection = styled.div`
    /* width: 46%; */
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
`;
const Cancel = styled.div`
    color: #747474;
    text-transform: capitalize;
    font-size: 13px;
    font-family: gordita_medium;
    border: 1px solid #adadad;
    width: 140px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
`;
const Save = styled.div`
    font-size: 13px;
    cursor: pointer;
    font-family: gordita_medium;
    text-transform: capitalize;
    color: #fff;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    width: 140px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`;
