import React from "react";
import styled from "styled-components";

function InterviewStatusModal({
    isInterviewStatusOpen,
    setInterviewStatusOpen,
    interviewStatus,
    setInterviewStatus,
}) {
    const interviewStatusList = [
        {
            id: 1,
            value: "selected",
        },
        {
            id: 2,
            value: "pending",
        },
        {
            id: 3,
            value: "rejected",
        },
    ];
    return (
        <>
            {isInterviewStatusOpen && (
                <Container>
                    <Section>
                        <ListItem>
                            {interviewStatusList.map((data) => (
                                <Item
                                    onClick={() => {
                                        setInterviewStatus(data.value);
                                        setInterviewStatusOpen(
                                            !isInterviewStatusOpen
                                        );
                                    }}
                                >
                                    {data.value}
                                </Item>
                            ))}
                        </ListItem>
                    </Section>
                </Container>
            )}
        </>
    );
}

export default InterviewStatusModal;
const Container = styled.div`
    z-index: 100001;
    position: absolute;
    width: 330px;
    background: #fff;
    height: 100px;
    padding: 10px;
    overflow: scroll;
    right: 420px;
    top: 466px;
    border-radius: 5px;
    border: 1px solid #adadad;
    @media all and (max-width: 1280px) {
        width: 275px;
        right: 253px;
        top: 437px;
    }
    @media all and (max-width: 1080px) {
        width: 294px;
        right: 183px;
        top: 437px;
    }
    @media all and (max-width: 980px) {
        width: 260px;
        right: 112px;
        top: 440px;
    }
`;
const Section = styled.div`
    height: 90px;
    overflow: scroll;
`;
const ListItem = styled.div``;
const Item = styled.div`
    cursor: pointer;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 8px;
    &:hover {
        background-color: #adadad;
    }
`;
