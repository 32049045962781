import React from "react";
import Lottie from "react-lottie";
import loader from "../../assets/lotties/button_loader.json";

export default function ButtonLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <Lottie
            options={defaultOptions}
            height={30}
            width={30}
            colour={"000"}
        />
    );
}
