import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
// import { Context } from "../contexts/Store";

function Sidebar({ isMenu, SetMenu }) {
    // const { state, dispatch } = useContext(Context);
    // const activeMenu = state.activeMenu;

    return (
        <>
            <Container type={isMenu}>
                <Section>
                    <List>
                        <ItemContainer
                            type={isMenu}
                            to="dashboard"
                            // className={
                            //     activeMenu === "dashboard" ? "active" : ""
                            // }
                            className={(isActive) => (isActive ? "active" : "")}
                        >
                            <LeftSection>
                                <img
                                    className="dashboard"
                                    src={
                                        require("../../assets/images/element-3.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                                <img
                                    className="dashboard-white"
                                    src={
                                        require("../../assets/images/dashboard-3.svg")
                                            .default
                                    }
                                    alt="white"
                                />
                            </LeftSection>
                            <Details className="dashboard">Dashboard</Details>
                        </ItemContainer>
                        <ItemContainer
                            type={isMenu}
                            to="orientation"
                            // className={
                            //     activeMenu === "orientation" ? "active" : ""
                            // }
                            className={(isActive) => (isActive ? "active" : "")}
                        >
                            <LeftSection>
                                <img
                                    className="videos"
                                    src={
                                        require("../../assets/images/video-icon.svg")
                                            .default
                                    }
                                    alt="image_"
                                />
                                <img
                                    className="video-white"
                                    src={
                                        require("../../assets/images/video-square.svg")
                                            .default
                                    }
                                    alt="white"
                                />
                            </LeftSection>
                            <Details className="video">
                                Orientation Videos
                            </Details>
                        </ItemContainer>
                        <ItemContainer
                            type={isMenu}
                            to="college"
                            // className={activeMenu === "college" ? "active" : ""}
                            className={(isActive) => (isActive ? "active" : "")}
                        >
                            <LeftSection>
                                <img
                                    className="college"
                                    src={
                                        require("../../assets/images/teacher.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                                <img
                                    className="college-white"
                                    src={
                                        require("../../assets/images/teacher-2.svg")
                                            .default
                                    }
                                    alt="white"
                                />
                            </LeftSection>
                            <Details className="college">
                                Associated College
                            </Details>
                        </ItemContainer>
                        <ItemContainer
                            type={isMenu}
                            to="candidates"
                            // className={
                            //     activeMenu === "candidates" ? "active" : ""
                            // }
                            className={(isActive) => (isActive ? "active" : "")}
                        >
                            <LeftSection>
                                <img
                                    className="candidates"
                                    src={
                                        require("../../assets/images/user-square.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                                <img
                                    className="candidates-white"
                                    src={
                                        require("../../assets/images/user-square-1.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                            </LeftSection>
                            <Details className="candidates">Candidates</Details>
                        </ItemContainer>
                        <ItemContainer
                            type={isMenu}
                            to="members"
                            className={(isActive) => (isActive ? "active" : "")}
                        >
                            <LeftSection>
                                <img
                                    className="candidates"
                                    src={
                                        require("../../assets/images/team-members.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                                <img
                                    className="candidates-white"
                                    src={
                                        require("../../assets/images/team-members-active.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                            </LeftSection>
                            <Details className="candidates">
                                Team Members
                            </Details>
                        </ItemContainer>
                        <ItemContainer
                            type={isMenu}
                            to="interview"
                            // className={
                            //     activeMenu === "interview" ? "active" : ""
                            // }
                            className={(isActive) => (isActive ? "active" : "")}
                        >
                            <LeftSection>
                                <img
                                    className="interview"
                                    src={
                                        require("../../assets/images/clipboard-text.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                                <img
                                    className="interview-white"
                                    src={
                                        require("../../assets/images/clipboard-text-1.svg")
                                            .default
                                    }
                                    alt="dashboard"
                                />
                            </LeftSection>
                            <Details className="interview">Interview</Details>
                        </ItemContainer>
                    </List>
                </Section>
            </Container>
        </>
    );
}

export default Sidebar;
const Container = styled.div`
    padding: 102px 13px;
    width: 273px;
    height: 100vh;
    overflow-y: scroll;
    position: fixed;
    z-index: 10;
    border-right: 1px solid #eeeeee;
    background: #f8f8f8;
    -webkit-transition: 0.3s;
    transition: all 0.4s ease-in-out;

    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        padding: 94px 13px;
        width: ${({ type }) => (type ? "240px" : "80px")};
        &:hover {
            width: 274px;
        }
        &:hover h4 {
            display: block;
            margin-right: 0;
            margin-left: 10px;
        }
        &:hover .active {
            justify-content: flex-start;
        }
    }
    @media all and (max-width: 1080px) {
        padding: 94px 13px;
        width: ${({ type }) => (type ? "260px" : "80px")};
        &:hover {
            width: 249px;
        }
        &:hover h4 {
            display: block;
            margin-right: 0;
            margin-left: 10px;
        }
        padding-left: ${({ type }) => (type ? "10px" : "0px")};
        padding-right: ${({ type }) => (type ? "10px" : "0px")};
    }
    @media all and (max-width: 980px) {
        width: ${({ type }) => (type ? "225px" : "80px")};
        &:hover {
            width: 225px;
        }
    }
    @media all and (max-width: 768px) {
    }
    @media all and (max-width: 640px) {
        width: ${({ type }) => (type ? "224px" : "0px")};
        padding-top: ${({ type }) => (type ? "92px" : "0px")};
        padding-left: ${({ type }) => (type ? "10px" : "0px")};
        padding-right: ${({ type }) => (type ? "10px" : "0px")};
    }
`;
const Section = styled.div``;
const List = styled.ul``;

const ItemContainer = styled(NavLink)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    color: #000;
    cursor: pointer;
    padding: 10px 5px;

    &:last-child {
        margin-bottom: 0;
    }
    &.active {
        display: flex;
        background-color: #2da99b;
        border-radius: 10px;
        align-items: center;
        color: #fff;
        cursor: pointer;
        @media all and (max-width: 1280px) {
            padding: 16px 11px;
            justify-content: center;
        }
        @media all and (max-width: 1080px) {
            padding: 16px 11px;
            justify-content: flex-start;
        }
        @media all and (max-width: 768px) {
            padding: 10px 15px;
        }
    }
`;
const LeftSection = styled.div`
    width: 24px;
    margin-right: 10px;
    @media all and (max-width: 1280px) {
        margin-right: 0;
    }
    @media all and (max-width: 1080px) {
        /* margin-right: 10px; */
        margin-right: 0;
    }
`;
const Details = styled.h4`
    font-family: gordita_medium;
    font-size: 18px;
    @media all and (max-width: 1280px) {
        display: none;
        &:hover {
            display: block;
        }
    }
    &.dashboard {
        @media all and (max-width: 1280px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 1080px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    &.video {
        margin-right: 30px;
        @media all and (max-width: 1280px) {
            display: ${({ type }) => (type ? "block" : "none")};
        }
        @media all and (max-width: 1080px) {
            margin-right: 9px;
        }
        @media all and (max-width: 980px) {
            margin-right: 3px;
        }
        @media all and (max-width: 768px) {
            margin-right: 5px;
        }
    }
    &.college {
        @media all and (max-width: 1280px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 1080px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    &.candidates {
        @media all and (max-width: 1280px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 1080px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    &.interview {
        @media all and (max-width: 1280px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 1080px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    &.members {
        @media all and (max-width: 1280px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 1080px) {
            display: none;
            &:hover {
                display: block;
            }
        }
        @media all and (max-width: 980px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 1080px) {
        display: none;
        &:hover {
            display: block;
        }
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
        display: ${({ type }) => (type ? "block" : "none")};
    }
`;
