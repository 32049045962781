import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

const AuthRoute = ({ children }) => {
    const is_verified = useSelector(state => state.auth.isAuthenticated);
    const [searchParams] = useSearchParams();
    const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

    return !is_verified ? children : <Navigate to={nextPath} />;
};

export default AuthRoute;
