const reducers = (state, action) => {
    switch (action.type) {
        case "UPDATE_COUNTRY_DATA":
            const selected_country = {
                ...state.selected_country,
                ...action.selected_country,
            };
            localStorage.setItem(
                "selected_country",
                JSON.stringify(selected_country)
            );
            return {
                ...state,
                selected_country: selected_country,
            };
    }
};
export default reducers;
