import React, { Suspense } from "react";
import styled from "styled-components";
import TopBar from "../../general/TopBar";
import SideBar from "../../general/SideBar";
import SectionLoader from "../../helpers/SectionLoader";
import { Navigate, Route, Routes } from "react-router-dom";
import DashBoard from "../../screens/DashBoard";
import OrientationVideo from "../../screens/OrientationVideo";
import AssociatedColleges from "../../screens/AssociatedColleges";
import Candidates from "../../screens/Candidates";
import Members from "../../screens/Interviewers";
import Interviewers from "../../screens/Interviewers";
import Interview from "../../screens/Interview";

function AppRouter() {
    return (
        <>
            <Container>
                <TopBar />
                <SideBar />
                <Suspense fallback={<SectionLoader />}>
                    <Routes>
                        <Route path="/*" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<DashBoard />} />
                        <Route path="/orientation" element={<OrientationVideo />} />
                        <Route path="/college" element={<AssociatedColleges />} />
                        <Route path="/candidates" element={<Candidates />} />
                        <Route path="/members" element={<Interviewers />} />
                        <Route path="/interview" element={<Interview />} />
                    </Routes>
                </Suspense>
            </Container>
        </>
    );
}

export default AppRouter;
const Container = styled.section`
    background: #ffff;
    min-height: 100vh;
    position: relative;
`;
