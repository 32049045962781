import React, { useState } from "react";
import styled from "styled-components";
import DeleteModal from "./modals/DeleteModal";
import EditModal from "./modals/EditModal";

function VideoCard({
    setItems,
    items,
    isEdit,
    startingCount,
    refresh,
    setRefresh,
}) {
    const [isDelete, setDelete] = useState(false);

    const [isId, setId] = useState("");
    const deleteCard = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };
    const [isModal, setModal] = useState(false);
    const [name, setName] = useState("");
    const [thumbnail, setThumbnail] = useState("");
    return (
        <>
            {isModal && (
                <EditModal
                    isModal={isModal}
                    setModal={setModal}
                    name={name}
                    thumbnail={thumbnail}
                    setName={setName}
                    isId={isId}
                    setId={setId}
                    refresh={refresh}
                    setRefresh={setRefresh}
                />
            )}
            {isDelete && (
                <DeleteModal
                    isDelete={isDelete}
                    setDelete={setDelete}
                    deleteCard={deleteCard}
                    isId={isId}
                    setId={setId}
                    items={items}
                    setItems={setItems}
                />
            )}

            {items.map((data, index) => (
                <Container id={data.id}>
                    <ImageSection>
                        <Img src={data.thumbnail} alt="image" />
                        <PlayIcon>
                            <img
                                src={
                                    require("../../assets/images/play-icon.svg")
                                        .default
                                }
                                alt="play-icon"
                            />
                        </PlayIcon>
                    </ImageSection>
                    <Section>
                        <Span>
                            #
                            {index + startingCount < 10
                                ? `0${index + startingCount}`
                                : index + startingCount}
                        </Span>
                        {isEdit && (
                            <Edit
                                onClick={() => {
                                    setModal(true);
                                    setName(data.title);
                                    setThumbnail(data.thumbnail);
                                    setId(data.id);
                                }}
                            >
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fedit-2.svg"
                                    }
                                    alt="edit"
                                />
                                Edit
                            </Edit>
                        )}
                    </Section>
                    <Heading>{data.title}</Heading>
                    <SubHeading>{data.description}</SubHeading>
                    {isEdit && (
                        <CloseIcon
                            onClick={() => {
                                setDelete(!isDelete);
                                setId(data.id);
                            }}
                        >
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fclose-icon.svg"
                                }
                                alt="close"
                            />
                        </CloseIcon>
                    )}
                </Container>
            ))}
        </>
    );
}

export default VideoCard;
const CloseIcon = styled.div`
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
`;
const Container = styled.div`
    padding: 20px;
    background-color: #f0f9f9;
    border-radius: 8px;
    position: relative;

    @media all and (max-width: 1080px) {
        padding: 14px;
    }
`;
const ImageSection = styled.div`
    width: 100%;
    margin-bottom: 10px;
    position: relative;
`;
const Edit = styled.span`
    color: #32bcad;
    display: flex;
    width: 45px;
    font-size: 12px;
    font-family: gordita_medium;
    cursor: pointer;
    img {
        width: 20px;
        display: block;
    }
`;
const Img = styled.img`
    display: block;
    width: 100%;
`;
const PlayIcon = styled.div`
    position: absolute;
    top: 30%;
    width: 52px;
    left: 40%;
    @media all and (max-width: 1280px) {
        width: 46px;
    }
    @media all and (max-width: 1080px) {
        width: 40px;
        left: 36%;
        top: 27%;
    }
`;
const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
`;
const Span = styled.span`
    color: #0fa76f;
    font-size: 14px;
    font-family: gordita_medium;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-size: 16px;
    font-family: gordita_medium;
    @media all and (max-width: 1080px) {
        font-size: 15px;
    }
`;
const SubHeading = styled.p`
    font-family: gordita_regular;
    color: #747474;
    font-size: 12px;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;
