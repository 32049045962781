import React, { Suspense } from "react";
import AppRouter from "./AppRouter";
import { Route, Routes } from "react-router-dom";
import AuthRouter from "./AuthRouter";
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";

function MainRouter() {
    return (
        <>
            <Suspense>
                <Routes>
                    <Route
                        path="/auth/*"
                        element={
                            <AuthRoute>
                                <AuthRouter />
                            </AuthRoute>
                        }
                    />
                    <Route
                        path="/*"
                        element={
                            <PrivateRoute>
                                <AppRouter />
                            </PrivateRoute>
                        }
                    />
                </Routes>
            </Suspense>
        </>
    );
}

export default MainRouter;
