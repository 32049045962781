import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jobifiedAuthConfig from "../../axiosConfig";

function InterviewersCard({
    item,
    index,
    selectedExaminerId,
    setSelectedExaminerId,
    setSelectedCandidateId,
    setAssignCandidate,
}) {
    //assign api
    const [clicked, setClicked] = useState(false);

    const [assign, setAssign] = useState([]);

    const [page, setPage] = useState(1);

    const fetchAssign = () => {
        jobifiedAuthConfig
            .get("/users/chief/candidates/scheduled/", {
                params: {
                    page: page,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setAssign([...assign, ...data.data]);
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        item.id === selectedExaminerId && fetchAssign();
    }, [page, selectedExaminerId]);

    const handleInfiniteScroll = () => {
        if (
            document.getElementById("college").scrollTop + 150 >=
            parseInt(
                getComputedStyle(document.getElementById("innerCollege")).height
            )
        ) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        document
            .getElementById("college")
            ?.addEventListener("scroll", handleInfiniteScroll);
    }, [clicked]);
    const [assignItem, setAssignItem] = useState("");
    const [isAssign, setIsAssign] = useState(false);

    return (
        <>
            <DataDiv id={item.id}>
                <Data>
                    {index + 1 < 10 && 0}
                    {index + 1}
                </Data>
                <NameData>
                    {item.name}
                    <Number>
                        <span>{item.country} </span> {item.phone}
                    </Number>
                </NameData>
                <Data>{item.email}</Data>
                <NameData className="assign">
                    {/* {item.candidate ? item.candidate?.name : "pending"} */}
                    <Li
                        style={{ fontSize: "14px" }}
                        value={
                            item.candidate ? item.candidate?.name : "pending"
                        }
                    />
                    <ImgContainer
                        onClick={() => {
                            setIsAssign(!isAssign);
                            setClicked(!clicked);
                            setSelectedExaminerId(item.id);
                        }}
                    >
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down.svg"
                            }
                            alt="Drop-down"
                        />
                    </ImgContainer>
                    <div
                        id="college"
                        className="list-page"
                        style={{
                            width: "max-content",
                            maxHeight: "150px",
                            overflowY: "scroll",
                            zIndex: "1",
                            position: "absolute",
                            border: "1px solid #adadad",
                            borderRadius: "5px",
                            top: "45px",
                            left: "0px",
                            opacity: isAssign ? "1" : "0",
                        }}
                    >
                        {item.id === selectedExaminerId && isAssign && (
                            <div
                                id="innerCollege"
                                style={{
                                    padding: "10px",
                                    background: "#fff",
                                    height: "100%",
                                }}
                            >
                                {assign.map((data) => (
                                    <div
                                        className="list"
                                        style={{
                                            fontSize: "14px",
                                            cursor: "pointer",
                                            marginBottom: "5px",
                                            padding: "2px",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => {
                                            setAssignItem(data.name);
                                            setIsAssign(!isAssign);
                                            setAssign([]);
                                            setSelectedCandidateId(data.id);
                                            setPage(1);
                                        }}
                                    >
                                        {data.name}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </NameData>
                <Data>{item.date}</Data>

                <Data>{item.time}</Data>
                <Data>{item.candidate?.interview_mode}</Data>
                <Data>{item.candidate?.campus_code}</Data>
                <Data>{item.candidate?.score}</Data>
                <Data
                    className="red"
                    style={{
                        cursor: item.candidate ? "pointer" : "not-allowed",
                    }}
                    onClick={() => {
                        if (item.candidate) {
                            setAssignCandidate(
                                item.candidate && item.candidate.id
                            );
                            setSelectedExaminerId(item.id);
                        }
                    }}
                >
                    cancel
                </Data>
            </DataDiv>
        </>
    );
}

export default InterviewersCard;
const DataDiv = styled.ul`
    display: grid;
    min-width: 1142px;
    grid-template-columns: 2fr 4fr 4fr 4fr 3fr 3fr 2fr 3fr 1fr 2fr;
    grid-column-gap: 20px;
    margin-bottom: 15px;
    background: #ffffff;
    border-radius: 8px;
`;
const NameData = styled.li`
    position: relative;
    font-size: 14px;
    color: #747474;
    height: 80px;
    display: flex;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        width: 10px;
        display: block;
    }
    &.assign {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: flex-start;
    }
`;
const Li = styled.input`
    width: 100%;
    padding: 0 0 0 10px;
    height: 100%;
    color: #adadad;
    font-size: 14px;
    font-family: gordita_regular;
`;
const ImgContainer = styled.div`
    width: 10px;
    cursor: pointer;
    margin-left: 30px;
`;
const Data = styled.li`
    font-size: 13px;
    color: #747474;
    height: 80px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: scroll;
    text-overflow: ellipsis;
    &::-webkit-scrollbar {
        display: none;
    }

    &:first-child {
        padding-left: 15px;
    }
    &:last-child {
        padding-right: 5px;
    }

    img {
        width: 15px;
    }
    &.red {
        color: red;
        cursor: pointer;
        text-transform: capitalize;
    }
`;
const Sem = styled.p`
    font-size: 12px;
`;
const Number = styled.p`
    font-size: 12px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    span {
        font-size: 12px;
    }
`;
