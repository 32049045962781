import React from "react";
import styled, { keyframes } from "styled-components";

function SuccessModal({ isSuccess, setSuccess, content, title }) {
    return (
        <>
            {isSuccess && (
                <Container>
                    <Overlay onClick={() => setSuccess(!isSuccess)}></Overlay>
                    <Section>
                        <ImgContainer>
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fsuccess.svg"
                                }
                                alt="success"
                            />
                        </ImgContainer>
                        <Title>{title}</Title>

                        <Description>{content}</Description>
                        <Button onClick={() => setSuccess(!isSuccess)}>
                            Continue
                        </Button>
                    </Section>
                </Container>
            )}
        </>
    );
}

export default SuccessModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    z-index: 100;
    transition: 0.3s;
    width: 100%;
    height: 100vh;
    z-index: 10002;
    left: 0;
    top: 0;
`;
const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    cursor: pointer;
    height: 100vh;
    backdrop-filter: blur(10px);
    cursor: pointer;
`;

const Section = styled.div`
    width: 35%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    background-color: #fff;
    position: absolute;
    left: 33%;
    top: 33%;
    justify-content: center;
    align-items: center;
    animation-name: ${videoAnimation};
    animation-duration: 0.3s;
    border-radius: 9px;

    @media all and (max-width: 1280px) {
        width: 55%;

        height: 400px;
        position: absolute;
        top: 155px;
        left: 227px;
        right: 0;
        bottom: 0;
    }
    @media all and (max-width: 980px) {
        width: 65%;
        position: absolute;
        top: 155px;
        left: 123px;
    }
    @media all and (max-width: 768px) {
        width: 70%;
        position: absolute;
        top: 155px;
        left: 96px;
    }
`;
const ImgContainer = styled.div`
    width: 60px;
    margin-bottom: 30px;
`;
const Title = styled.h2`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 20px;
`;
const Description = styled.p`
    font-size: 14px;
    text-align: center;
    color: #747474;
    width: 80%;
    margin: 20px auto;
`;
const Button = styled.button`
    cursor: pointer;
    width: 100px;
    height: 40px;
    font-size: 14px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    font-family: gordita_regular;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
