import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { jobifiedConfig } from "../../axiosConfig";
import { authActions } from "../store/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import buttonLoader from "../../assets/lotties/buttonLoader.json";
import debounce from "../functions/debounce";

const Login = () => {
    const [isHide, setHide] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [username, setusername] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("This field is required");

    const initialErrors = {
        username: "",
        password: "",
    };
    const [errorMessages, setErrorMessages] = useState(initialErrors);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 3000);
    }, [isError]);

    const onUsernameChange = (e) => {
        setUsernameError(false);
        setError(false);
        setErrorMessages({ ...errorMessages, username: "" });
        setusername(e.target.value);
    };

    const onPasswordChange = (e) => {
        setPasswordError(false);
        setError(false);
        setErrorMessages({ ...errorMessages, password: "" });
        setPassword(e.target.value);
    };

    const LoginHandler = () => {
        setError(false);
        var error = [];

        if (!username.length) {
            error.push(true);
            setUsernameError(true);
        }
        if (!password.length) {
            error.push(true);
            setPasswordError(true);
        }

        var tempErrors = { ...errorMessages };

        if (username.length && username.length < 4) {
            error.push(true);
            setUsernameError(true);
            tempErrors.username = "Username must have at least 4 characters";
            setErrorMessages({ ...tempErrors });
        }
        if (password.length && password.length < 4) {
            error.push(true);
            setPasswordError(true);
            tempErrors.password = "Password must have at least 4 characters";
            setErrorMessages({ ...tempErrors });
        }

        if (error.includes(true)) return;

        setLoading(true);
        let params = {
            username,
            password,
        };

        jobifiedConfig
            .post("/users/chief/login/", params)
            .then((response) => {
                const { data, statusCode } = response.data;
                console.log(data);
                if (statusCode === 6000) {
                    dispatch(
                        authActions.login({
                            access: data.access,
                            refresh: data.refresh,
                            isAuthenticated: true,
                            name: data.first_name,
                        })
                    );
                    setLoading(false);
                    navigate("/");
                } else {
                    setError(true);
                    setErrorMessage(data.message);
                    setLoading(false);
                    setusername("");
                    setPassword("");
                }
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };
    const debouncedApiAll = debounce(LoginHandler, 500);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: buttonLoader,
    };

    return (
        <MainWrapper>
            <Wrapper>
                <LeftSection>
                    <LogoWrapper>
                        <a href="https://jobified.ai/">
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fjobified-light.svg"
                                }
                                alt="jobified-logo"
                            />
                        </a>
                    </LogoWrapper>
                    <HeroWrapper>
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Flogin-hero.svg"
                            }
                            alt="jobified-hero"
                        />
                    </HeroWrapper>
                    <p>
                        A leader takes people where they want to go. <br /> A
                        great leader takes people where they don't necessarily
                        want to go, but ought to be.
                    </p>
                </LeftSection>
                <RightSection>
                    <FormContainer>
                        <Top>
                            <h1>Login</h1>
                        </Top>
                        <Form>
                            <InputContainer className="username">
                                <Label htmlFor="username">Username</Label>
                                <Input className={usernameError ? "error" : ""}>
                                    <input
                                        type="text"
                                        id="username"
                                        placeholder="Enter username"
                                        value={username}
                                        onChange={onUsernameChange}
                                    />
                                </Input>
                                {usernameError && (
                                    <ErrorMessage>
                                        {!!errorMessages.username.length
                                            ? errorMessages.username
                                            : errorMessage}
                                    </ErrorMessage>
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Label htmlFor="password">Password</Label>
                                <Input className={passwordError ? "error" : ""}>
                                    <input
                                        type={isHide ? "password" : "text"}
                                        id="password"
                                        placeholder="Enter password"
                                        value={password}
                                        onChange={onPasswordChange}
                                    />
                                    <HideContainer>
                                        <img
                                            onClick={() => setHide(!isHide)}
                                            src={
                                                isHide
                                                    ? "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fhide.svg"
                                                    : "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fshow.svg"
                                            }
                                            alt="hide-show"
                                        />
                                    </HideContainer>
                                </Input>
                                {passwordError && (
                                    <ErrorMessage>
                                        {!!errorMessages.password.length
                                            ? errorMessages.password
                                            : errorMessage}
                                    </ErrorMessage>
                                )}
                            </InputContainer>

                            <BottomContainer>
                                {isError && <Error>{errorMessage}</Error>}
                                <LoginBtn onClick={debouncedApiAll}>
                                    {isLoading ? (
                                        <Lottie
                                            options={defaultOptions}
                                            height={200}
                                            width={200}
                                        />
                                    ) : (
                                        "Login"
                                    )}
                                </LoginBtn>
                            </BottomContainer>
                        </Form>
                    </FormContainer>
                </RightSection>
            </Wrapper>
        </MainWrapper>
    );
};

export default Login;

const MainWrapper = styled.section``;
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100vh;

    * {
        font-family: gordita_regular;
    }
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;
const LeftSection = styled.aside`
    width: 40%;
    height: 100%;
    background: linear-gradient(96.55deg, #19244e 0.05%, #0a1231 99.48%);
    padding: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: #ffffff;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        text-align: center;
    }

    @media all and (max-width: 1440px) {
        width: 50%;
        padding: 60px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 48px 32px;
    }
`;
const RightSection = styled.main`
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 120px 0;

    @media all and (max-width: 1440px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;

const LogoWrapper = styled.div`
    width: 120px;
    img {
        width: 100%;
    }
`;
const HeroWrapper = styled.div`
    width: 80%;
    margin-top: 80px;
    /* margin-bottom: 48px ; */
    img {
        width: 100%;
    }
`;
const FormContainer = styled.div`
    width: 60%;

    img {
        width: 30px;
    }

    @media all and (max-width: 1440px) {
        width: 80%;
    }
    @media all and (max-width: 425px) {
        width: 90%;
    }
`;
const Top = styled.div`
    margin-bottom: 32px;
    h1 {
        font-weight: 600;
        font-size: 32px;
        color: #0a0a0a;
    }

    @media all and (max-width: 1440px) {
        h1 {
            font-size: 28px;
        }
    }
`;
const Form = styled.div`
    padding: 8px;
`;
const InputContainer = styled.div`
    position: relative;
    margin-bottom: 16px;

    &.username {
        margin-bottom: 26px;
    }
`;
const Label = styled.label`
    color: #747474;
    font-family: gordita_regular;
    font-weight: 400;
    font-size: 20px;
    display: inline-block;
    margin-bottom: 8px;

    @media all and (max-width: 1440px) {
        font-size: 18px;
    }
`;
const Input = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 12px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;

    :focus-within {
        border-color: #009262;
    }

    &.error {
        border-color: red;
    }

    input {
        font-family: gordita_regular;
        flex: 1;
        color: #0a0a0a;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 1px;
    }

    @media all and (max-width: 1440px) {
        input {
            font-size: 16px;
        }
    }
    @media all and (max-width: 480px) {
        padding: 12px;
        input {
            font-size: 14px;
        }
    }
`;
const HideContainer = styled.div`
    width: 22px;
    cursor: pointer;
    img {
        width: 100%;
    }
`;

// const ForgetContainer = styled.div`
//     display: flex;
//     justify-content: flex-end;
// `

// const Button = styled.button`
//     cursor: pointer;
//     color: #858585;
//     font-weight: 300;
//     font-family: gordita_medium;
//     font-size: 14px;
//     margin: 0 0 auto;
// `

const BottomContainer = styled.footer`
    position: relative;
    padding-top: 80px;
`;

const LoginBtn = styled.button`
    /* width: 100%;
    padding: 16px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
    cursor: pointer; */

    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);

    cursor: pointer;
    height: 58px;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 10px 50px;
    /* background: linear-gradient(272deg, rgb(34, 193, 195) 0%, rgb(99, 187, 76) 0%, rgb(24, 152, 175) 100%); */
    /* background-color: #4b1989; */
    border-radius: 10px;
    opacity: 1;
    letter-spacing: 0px;
    color: #fff;
    font-size: 18px;
    font-family: gordita_medium;
    text-align: center;

    &.invalid {
        cursor: not-allowed;
    }

    @media all and (max-width: 480px) {
        font-size: 18px;
        padding: 12px;
    }
    @media all and (max-width: 380px) {
        margin-top: 48px;
        font-size: 16px;
        padding: 9px;
    }
`;
const ErrorMessage = styled.p`
    position: absolute;
    left: 0;
    color: red;
    font-size: 14px;

    @media all and (max-width: 380px) {
        font-size: 13px;
    }
`;

const Error = styled.p`
    position: absolute;
    top: 41px;
    right: 0;
    /* margin-top: 60px; */
    margin-bottom: 8px;
    font-size: 16px;
    color: red;
`;
