import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import jobifiedAuthConfig from "../../../axiosConfig";

function AddCollegeModal({ setAdd, setPageNumber }) {
    const [inputs, setInputs] = useState({
        name: "",
        campusCode: "",
        phone: "",
        location: "",
        pinCode: "",
        image: {
            name: "",
            src: "",
            preview: "",
        },
    });
    const [edit, setEdit] = useState(false);
    const [isError, setError] = useState(false);

    const inputRef = useRef(null);

    useEffect(() => {
        const id = setTimeout(() => {
            setError(false);
        }, 3000);

        return () => {
            clearTimeout(id);
        };
    }, [isError]);

    // change file
    function handleFileChange(event) {
        if (event.target.files.length) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setInputs({
                    ...inputs,
                    image: {
                        name: event.target.files[0].name,
                        src: event.target.files[0],
                        preview: e.target.result,
                    },
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    const handleInputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

    const uploadHandler = () => {
        const formData = new FormData();

        formData.append("name", inputs.name);
        formData.append("number", inputs.phone);
        formData.append("pin_code", inputs.pinCode);
        formData.append("campus_code", inputs.campusCode);
        formData.append("image", inputs.image.src);
        formData.append("location", inputs.location);

        jobifiedAuthConfig
            .post("/campuses/chief/create/", formData)
            .then((res) => {
                const { statusCode, data } = res.data;

                if (statusCode === 6000) {
                    setPageNumber(1);
                    setAdd(false);
                } else {
                    setError(data.message);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <Container>
                <Overlay
                    onClick={() => {
                        setAdd(false);
                    }}
                ></Overlay>
                <Section>
                    <TopSection>
                        <Title>Add Associated College</Title>
                    </TopSection>
                    {edit ? (
                        <EditSection>
                            <LeftSection>
                                <ImgSection>
                                    <img
                                        src={
                                            inputs.image.preview
                                                ? inputs.image.preview
                                                : "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fadd.svg"
                                        }
                                        alt=""
                                    />
                                </ImgSection>
                            </LeftSection>
                            <RightSection>
                                <div>
                                    <p>{inputs.image.name}</p>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                        ref={inputRef}
                                    />
                                    <button
                                        onClick={() => inputRef.current.click()}
                                    >
                                        Upload File
                                    </button>
                                </div>
                                <ButtonSection className="new">
                                    <Button
                                        onClick={() => inputRef.current.click()}
                                        className="upload"
                                        type="button"
                                    >
                                        Upload now
                                    </Button>
                                    <ImageContainer
                                        className="image"
                                        onClick={() =>
                                            setInputs({
                                                ...inputs,
                                                image: {
                                                    name: "",
                                                    src: "",
                                                },
                                            })
                                        }
                                    >
                                        <img
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdelete.svg"
                                            }
                                            alt="delete"
                                        />
                                    </ImageContainer>
                                </ButtonSection>
                            </RightSection>
                        </EditSection>
                    ) : (
                        <EditSection>
                            <LeftSection>
                                <ImgSection>
                                    <img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fadd.svg"
                                        }
                                        alt=""
                                    />
                                </ImgSection>
                            </LeftSection>
                            <RightSection className="new-image">
                                <Button
                                    onClick={() => {
                                        setEdit(true);
                                    }}
                                >
                                    Upload image
                                </Button>
                            </RightSection>
                        </EditSection>
                    )}

                    <EditContainer>
                        <InputSection>
                            <Label>College Name</Label>
                            <Input
                                type="text"
                                placeholder="Enter College"
                                name="name"
                                value={inputs.name}
                                onChange={handleInputChange}
                            />
                        </InputSection>
                        <InputSection>
                            <Label>Reference Code</Label>
                            <Input
                                type="number"
                                placeholder="Enter the code"
                                name="campusCode"
                                value={inputs.campusCode}
                                onChange={handleInputChange}
                            />
                        </InputSection>
                        <InputSection>
                            <Label>Phone Number</Label>
                            <UserInputs>
                                <DropDownSection>
                                    <img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fflag.svg"
                                        }
                                        alt="dropdown"
                                    />
                                </DropDownSection>
                                <CountryCode>+91</CountryCode>
                                <Inputs
                                    className="phone_field"
                                    type="number"
                                    placeholder="Enter phone number"
                                    value={inputs.phone}
                                    name="phone"
                                    onChange={handleInputChange}
                                />
                            </UserInputs>
                        </InputSection>
                        <InputSection>
                            <Label>Location</Label>
                            <Input
                                type="text"
                                placeholder="Enter location"
                                name="location"
                                value={inputs.location}
                                onChange={handleInputChange}
                            />
                        </InputSection>
                        <InputSection>
                            <Label>Pin Code</Label>
                            <Input
                                type="number"
                                placeholder="Enter pin code"
                                name="pinCode"
                                value={inputs.pinCode}
                                onChange={handleInputChange}
                            />
                        </InputSection>
                    </EditContainer>
                    {isError && <p className="error">{isError}</p>}
                    <BottomSection>
                        <Cancel onClick={() => setAdd(false)}>cancel</Cancel>
                        <Save onClick={uploadHandler}>Submit</Save>
                    </BottomSection>
                </Section>
            </Container>
        </>
    );
}

export default AddCollegeModal;
const videoAnimation = keyframes`
    0% { 
        transform:scale(0,0); 
        opacity:0;
     }
    100% { 
        transform:scale(1,1); 
        opacity:1;
     }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
`;
const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 45%;
    max-width: 980px;
    max-height: 94vh;
    height: 84vh;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    transition: all 0.5s ease-in-out;

    @media all and (max-width: 1280px) {
        width: 60%;
        height: 88vh;
    }
    @media all and (max-width: 1080px) {
        width: 65%;
    }
    @media all and (max-width: 980px) {
        width: 75%;
    }

    p.error {
        width: auto;
        color: red;
        font-size: 14px;
        margin: 0 0 0 auto;
        transition: all 0.5s ease-in-out;
    }
`;
const TopSection = styled.div`
    border-bottom: 2px solid #eeeeee;
    padding-bottom: 20px;
`;
const Title = styled.h4`
    color: #0a0a0a;
    font-size: 20px;
    font-family: gordita_medium;
`;
const EditSection = styled.div`
    margin-top: 15px;
    border-radius: 8px;
    position: relative;
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const LeftSection = styled.div``;
const ImgSection = styled.div`
    width: 85px;
    margin-right: 16px;
`;
const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85px;
    &.new-image {
        justify-content: center;
    }
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        p {
            font-size: 12px;
            margin-right: 10px;
            border: 1px solid #d4d4d4;
            padding: 5px;
            overflow: scroll;
            border-radius: 6px;
            height: 30px;
            white-space: nowrap;
            width: 145px;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        button {
            color: #4eaaff;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    input {
        border: 1px solid #adadad;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 8px 5px;
    }
`;
const Button = styled.a`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    width: 140px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    &.upload {
        text-align: center;
        padding: 8px 6px;
        font-size: 12px;
        width: 110px;
        margin-right: 10px;
    }
`;
const ButtonSection = styled.div`
    &.new {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`;
const ImageContainer = styled.div`
    &.image {
        width: 20px;
        margin-bottom: 0;
        cursor: pointer;
    }
`;
const EditContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
`;
const InputSection = styled.div`
    width: 49%;
    margin-bottom: 20px;
    flex-direction: column;
    display: flex;
    /* &:last-child {
        margin-bottom: 0;
    }
    &:nth-last-child(1) {
        margin-bottom: 0;
    } */
`;
const Label = styled.label`
    color: #747474;
    font-family: gordita_medium;
    font-size: 14px;
    margin-bottom: 10px;
`;
const UserInputs = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    height: 44px;
    padding: 0 5px;
    border-radius: 8px;
    position: relative;
    border-radius: 8px;
    position: relative;
    &:hover {
        border: 1px solid #32bcad;
    }
    &.valid-phone {
        border: 2px solid #32bcad;
    }

    @media all and (max-width: 1280px) {
        padding: 7px 12px;
    }
`;
const CountryCode = styled.span`
    margin-right: 10px;
    font-size: 14px;
    font-family: gordita_medium;
    @media all and (max-width: 1080px) {
        margin-right: 5px;
        font-size: 14px;
    }
`;

const DropDownSection = styled.div`
    width: 30px;
    margin-right: 5px;
    cursor: pointer;
    @media all and (max-width: 1280px) {
        width: 35px;
    }
    img {
        display: block;
        width: 100%;
    }
`;

const Input = styled.input`
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 0px 10px;
    height: 44px;

    &::placeholder {
        font-size: 14px;
        font-family: gordita_regular;
        color: #adadad;
    }
    &:hover {
        border: 1px solid #32bcad;
    }
`;
const Inputs = styled.input`
    font-size: 14px;
    width: 80%;
    &::placeholder {
        font-family: gordita_regular;
        color: #adadad;
    }
`;
const BottomSection = styled.div`
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
`;
const Cancel = styled.div`
    color: #747474;
    text-transform: capitalize;
    font-size: 13px;
    font-family: gordita_medium;
    border: 1px solid #d4d4d4;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
`;
const Save = styled.div`
    font-size: 13px;
    cursor: pointer;
    font-family: gordita_medium;
    text-transform: capitalize;
    color: #fff;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`;
