import React, { useContext, useState } from "react";
import styled, { keyframes } from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import CountrySelector from "../CountrySelector";
import { Context } from "../../context/Store";
import ButtonLoader from "../../helpers/ButtonLoader";
function MembersModal({
    isMembers,
    setMembers,
    isLoading,
    countryselector,
    handleShow,
    onSelectHandler,
    selectedCountry,
    setSelected,
    onNameChange,
    setCountryselector,
    isError,
    phone,
    name,
    mailError,
    onEmailChange,
    onPhoneChange,
    email,
    handleSubmit,
    selected,
}) {
    const { state, dispatch } = useContext(Context);

    return (
        <>
            {isMembers && (
                <>
                    <CountrySelector
                        show={countryselector}
                        handleClick={handleShow}
                        onSelectHandler={onSelectHandler}
                        selectedCountry={selectedCountry}
                        selected={selected}
                        setSelected={setSelected}
                    />

                    <Container>
                        <Overlay
                            onClick={() => setMembers(!isMembers)}
                        ></Overlay>
                        <Section>
                            <TopSection>
                                <Heading>Create Team Members</Heading>
                            </TopSection>
                            <MiddleSection>
                                <Contents>
                                    <Label>Name*</Label>
                                    <Div>
                                        <Input
                                            type="text"
                                            placeholder="Enter name"
                                            onChange={onNameChange}
                                            value={name}
                                        />
                                        <Error
                                            className={
                                                isError && name === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This field required
                                        </Error>
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>Phone number*</Label>
                                    <UserInputs>
                                        <DropDownSection
                                            onClick={() =>
                                                setCountryselector(
                                                    !countryselector
                                                )
                                            }
                                        >
                                            <img
                                                src={
                                                    state.selected_country.flag
                                                        ? state.selected_country
                                                              .flag
                                                        : selected.flag
                                                }
                                                alt="flag"
                                            />
                                        </DropDownSection>
                                        <CountryCode>
                                            {state.selected_country.phone_code
                                                ? state.selected_country
                                                      .phone_code
                                                : selected.phone_code}
                                        </CountryCode>
                                        <Input
                                            className="phone_field"
                                            type="number"
                                            placeholder="Enter phone number"
                                            value={phone}
                                            onChange={onPhoneChange}
                                            onKeyDown={(evt) =>
                                                evt.key === "e" &&
                                                evt.preventDefault()
                                            }
                                        />
                                        <Error
                                            className={
                                                isError && phone === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This Field Required
                                        </Error>
                                    </UserInputs>
                                </Contents>
                                <Contents>
                                    <Label>Email*</Label>
                                    <Div className={mailError ? "active" : ""}>
                                        <Input
                                            type="text"
                                            placeholder="Enter email id"
                                            value={email}
                                            onChange={onEmailChange}
                                        />
                                        <Error
                                            className={
                                                (mailError ||
                                                    (isError &&
                                                        email === "")) &&
                                                "active"
                                            }
                                        >
                                            Enter a valid email id
                                        </Error>
                                    </Div>
                                </Contents>
                            </MiddleSection>
                            <BottomContainer>
                                <Cancel onClick={() => setMembers(false)}>
                                    Cancel
                                </Cancel>
                                <Submit onClick={handleSubmit}>
                                    {isLoading ? <ButtonLoader /> : "Submit"}
                                </Submit>
                            </BottomContainer>
                        </Section>
                    </Container>
                </>
            )}
        </>
    );
}

export default MembersModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
`;

const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 45%;
    max-width: 980px;
    max-height: 70vh;
    height: 50vh;
    padding: 35px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media all and (max-width: 1280px) {
        width: 65%;
        left: 19%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
        left: 10%;
    }
`;
const UserInputs = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    height: 40px;
    padding: 0 5px;
    border-radius: 8px;
    position: relative;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    position: relative;
    &.valid-phone {
        border: 2px solid #32bcad;
    }

    @media all and (max-width: 1280px) {
        padding: 7px 12px;
    }
`;
const CountryCode = styled.span`
    margin-right: 10px;
    font-size: 16px;
    font-family: gordita_medium;
    @media all and (max-width: 1080px) {
        margin-right: 5px;
        font-size: 14px;
    }
`;

const DropDownSection = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    img {
        display: block;
        width: 100%;
        border-radius: 50%;
    }
`;

const InputField = styled.input`
    flex: 1;
    font-size: 16px;
    top: 12px;
    font-family: "gordita_medium";
    &.phone_filed {
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 980px) {
        max-width: 63%;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
    &::placeholder {
        color: #adadad;
        font-family: "gordita_regular";
        font-size: 16px;
        @media all and (max-width: 768px) {
            font-size: 15px;
        }
        @media all and (max-width: 360px) {
            font-size: 13px;
        }
    }
`;
const TopSection = styled.div`
    border-bottom: 1px solid #d4d4d4;
`;
const Heading = styled.h2`
    color: #0a0a0a;
    font-size: 24px;
    padding-bottom: 25px;
    font-family: gordita_medium;
    @media all and (max-width: 1280px) {
        font-size: 22px;
        padding-bottom: 17px;
    }
`;
const MiddleSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 30px; */
    justify-content: space-between;
`;
const Contents = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &.mode {
        width: 100%;
    }
`;
const Label = styled.label`
    color: #747474;
    font-size: 16px;
    font-family: gordita_medium;
    margin-bottom: 8px;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
`;
const Input = styled.input`
    flex: 1 1 0%;
    font-size: 14px;
    top: 12px;
    font-family: gordita_medium;
    @media all and (max-width: 1080px) {
        font-size: 14px;
    }
    &::placeholder {
        color: #adadad;
        font-family: gordita_regular;
        font-size: 16px;
        @media all and (max-width: 1080px) {
            font-size: 14px;
        }
    }

    &.assign {
        width: 96%;
    }
    &.phone_field {
        @media all and (max-width: 1280px) {
            width: 70%;
        }
    }
`;
const ErrorMessage = styled.p`
    color: red;
    position: absolute;
    right: 0;
    bottom: -25px;
    font-size: 12px;
`;
const Error = styled.p`
    color: #e02b1d;
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    left: 0;
    display: none;
    &.active {
        display: block;
    }
`;
const Inputs = styled.input`
    font-size: 14px;
    /* flex: 1;
    font-size: 18px;
    top: 12px;
    font-family: "gordita_medium"; */
`;

const ImgContainer = styled.div`
    width: 45px;
    img {
        width: 100%;
        display: block;
    }
`;
const Div = styled.div`
    padding: 0 10px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    position: relative;
    &.assign {
        display: block;
    }
    .css-rh82gz-DropDown {
        width: 100%;
    }
    .css-wmy1p7-ReactDropdownSelect {
        border: none !important;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.css-wmy1p7-ReactDropdownSelec:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .css-wmy1p7-ReactDropdownSelect:focus,
    .css-wmy1p7-ReactDropdownSelect:focus-within {
        outline: none;
        box-shadow: 0 0 0;
    }
    .react-dropdown-select-input,
    ::placeholder {
        color: #adadad !important;
        font-family: "gordita_regular" !important;
        font-size: 14px !important;
    }
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    &.email {
        position: relative;
    }
    &.active {
        border: 1px solid red;
    }
    &.number {
        align-items: center;
        padding: 0 5px;
    }
    span {
        font-size: 14px;
        margin: 0 5px;
    }
`;
const Down = styled.img`
    width: 15px;
    display: block;
`;
const Date = styled.div`
    cursor: pointer;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    @media all and (max-width: 980px) {
        padding: 8px;
    }
    img {
        display: block;
        width: 25px;
        margin-right: 10px;
        @media all and (max-width: 640px) {
            width: 20px;
        }
    }
    span {
        font-size: 14px;
    }
`;
const CalenderBox = styled.div`
    width: 100%;
    border: none;
    outline: none;
    div.react-datepicker-wrapper > div {
        width: 100% !important;
        border: none !important;
    }
    .react-time-picker {
        box-sizing: border-box;
        border: none !important;
        width: 100% !important;
        display: flex;
        outline: none !important;
    }
    .react-time-picker__button:enabled {
        cursor: pointer;
        display: none !important;
    }
    .react-time-picker__wrapper {
        border: none !important;
    }
    .react-time-picker__inputGroup__input {
        cursor: pointer;
    }
    .react-time-picker__inputGroup__divider {
        margin-right: 6px !important;
    }

    .react-time-picker__clock {
        inset: 37px -11px 13px -6px !important;
    }
    .react-time-picker {
        @media all and (max-width: 640px) {
            height: 28px !important;
        }
    }
`;
const DropDown = styled.div`
    width: 10px;
    cursor: pointer;
    &.date {
        position: absolute;
        right: 8px;
        width: 14px;
    }
    img {
        display: block;
        width: 100%;
    }
    &.time {
        &.time {
            width: 10px;
        }
    }
`;
const TimeRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;

    span {
        font-size: 15px;
    }
`;
const Img = styled.img`
    width: 25px;
    @media all and (max-width: 768px) {
        width: 23px;
        margin-right: 10px;
    }
    @media all and (max-width: 640px) {
        width: 20px;
    }
`;
const Time = styled.div`
    cursor: pointer;
    position: relative;
    height: 40px;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.react-datepicker-wrapper > div > input {
        width: 100% !important;
    }
`;
const Modes = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Online = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    height: 40px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    width: 48%;
    display: flex;
    align-items: center;
    color: #adadad;

    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const OuterCircle = styled.div`
    cursor: pointer;
    border: 1px solid #adadad;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
`;
const InnerCircle = styled.div`
    display: none;
    &.active {
        background: #009262;
        width: 10px;
        height: 10px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: block;
    }
`;
const Offline = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    background: #f9f9f9;
    color: #adadad;
    border: 1px solid #d4d4d4;
    height: 40px;
    border-radius: 8px;
    width: 48%;
    display: flex;
    align-items: center;
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Location = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: #f9f9f9;
    height: 40px;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    margin-bottom: 35px;
    font-family: gordita_regular;
    width: 100%;
    color: #adadad;
    div {
        width: 100%;

        display: flex;
    }
    .css-rh82gz-DropDown {
        width: 100%;
    }
    .css-wmy1p7-ReactDropdownSelect {
        border: none !important;
    }
    .css-1aarvou-DropdownHandleComponent svg {
        width: 16px;
        height: 16px;
        display: none;
    }
    .css-z2hyoe-ItemComponent {
        padding: 5px 10px;
        cursor: pointer;
        border-bottom: 1px solid #fff;
        font-size: 14px;
    }
    &.css-wmy1p7-ReactDropdownSelec:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .css-wmy1p7-ReactDropdownSelect:focus,
    .css-wmy1p7-ReactDropdownSelect:focus-within {
        outline: none;
        box-shadow: 0 0 0;
    }
    &.active {
        color: #adadad;
    }

    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const LocationBox = styled.div`
    border: 1px solid #adadad;
    z-index: 1000;
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -70px;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;
const List = styled.div`
    text-transform: capitalize;
    color: #000;
    font-family: gordita_regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;

    :hover {
        background-color: rgb(240 255 244);
    }
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const Cancel = styled.div`
    width: 130px;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
    border: 1px solid #adadad;
    border-radius: 6px;
    margin-right: 20px;
`;
const Submit = styled.div`
    width: 130px;
    height: 40px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
`;
const InputContainer = styled.div`
    width: 48%;
    margin-top: 30px;
    position: relative;
    cursor: pointer;
    @media (max-width: 640px) {
        width: 100%;
    }
    p.error-msg {
        position: absolute;
        bottom: -25px;
    }
`;
const Title = styled.h4`
    font-size: 16px;
    color: #6b6b6b;
    margin-bottom: 5px;
`;
const InputFields = styled.div`
    color: #000;
    width: 100%;
    height: 48px;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    &.gendercontainer {
        border: 1px solid #e7e7e7;
        padding-left: 8px;
    }

    input {
        width: 100%;
        height: 100%;
        padding: 11px;
        font-size: 16px;
        color: #000;
        &::placeholder {
            font-size: 16px;
            color: #868282;
        }

        & .phones {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
    }
`;
const LeftFlag = styled.div`
    display: flex;
    margin-left: 8px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1280px) {
        width: 20%;
    }
    @media (max-width: 1080px) {
        width: 26%;
    }
    @media (max-width: 768px) {
        width: 29%;
    }
    @media (max-width: 640px) {
        width: 17%;
    }
    @media (max-width: 480px) {
        width: 20%;
    }
    @media (max-width: 420px) {
        width: 22%;
    }
    @media (max-width: 380px) {
        width: 26%;
    }
`;
const ImageCont = styled.div`
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;

    @media (max-width: 640px) {
        width: 28px;
        height: 28px;
    }
    img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
    }
`;
const DownArrowBox = styled.div`
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 5px;
    img {
        width: 100%;
        height: 100%;
    }
`;
const Code = styled.div`
    font-size: 15px;
    color: #000;
    margin-left: 8px;
    @media (max-width: 640px) {
    }
`;
