import axios from "axios";


const URL = "https://developers-api.jobified.ai/api/v1"
// const URL = "http://127.0.0.1:8000/api/v1/"

export const jobifiedConfig = axios.create({
    baseURL: URL
})

const jobifiedAuthConfig = axios.create({
    baseURL: URL
})

jobifiedAuthConfig.interceptors.request.use(
    (request) => {
        let accessToken = localStorage.getItem("accessToken")

        request.headers.Authorization = 'Bearer ' + accessToken

        return request
    },
)


export default jobifiedAuthConfig