import React, { useState } from "react";

import InterviewCardAll from "./InterviewCardAll";
import InterviewCardFilters from "./InterviewCardFilters";

function InterviewCard({
    filter,
    interviewItems,
    sendMail,
    setInterviewItems,
    fetchInterviews,
    deleteInterview,
}) {
    return (
        <>
            {filter !== ""
                ? interviewItems
                      ?.filter((data) => data.interview_mode === filter)
                      .map((item, index) => (
                          <InterviewCardFilters
                              item={item}
                              index={index}
                              filter={filter}
                              sendMail={sendMail}
                              setInterviewItems={setInterviewItems}
                              fetchInterviews={fetchInterviews}
                              deleteInterview={deleteInterview}
                          />
                      ))
                : interviewItems?.map((item, index) => (
                      <InterviewCardAll
                          item={item}
                          index={index}
                          sendMail={sendMail}
                          setInterviewItems={setInterviewItems}
                          fetchInterviews={fetchInterviews}
                          deleteInterview={deleteInterview}
                      />
                  ))}
        </>
    );
}

export default InterviewCard;
