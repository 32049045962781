import React from "react";
import styled from "styled-components";
import Card from "../includes/Card";

function DashBoard() {
    return (
        <>
            <Container>
                <Section>
                    <Heading>Dashboard</Heading>
                    <Description>Check your today’s status</Description>
                    <Contents>
                        <Card />
                    </Contents>
                </Section>
            </Container>
        </>
    );
}

export default DashBoard;
const Container = styled.div`
    padding: 63px 0 0 273px;
    @media all and (max-width: 1280px) {
        padding-left: 81px;
    }
`;
const Section = styled.div`
    padding: 20px;
    min-height: 90vh;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 30px;
    margin-bottom: 8px;
    @media all and (max-width: 1280px) {
        font-size: 28px;
    }
    @media all and (max-width: 1080px) {
        font-size: 26px;
    }
    @media all and (max-width: 980px) {
        font-size: 24px;
    }
`;
const Description = styled.p`
    font-size: 18px;
    @media all and (max-width: 1280px) {
        font-size: 17px;
    }
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Contents = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;
