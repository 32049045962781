import React, { useState } from "react";
import "./assets/css/style.css";
import MainRouter from "./components/routing/routers/MainRouter";
import { BrowserRouter as Router } from "react-router-dom";
import Store from "./components/context/Store";
function App() {
    return (
        <>
            <Store>
                <Router>
                    <MainRouter />
                </Router>
            </Store>
        </>
    );
}

export default App;
