import React from "react";
import Lottie from "react-lottie";
import styled from "styled-components";
import loaderAnimation from "../../assets/lotties/loader.json";

export default function SectionLoader({ status, parentHeight=false }) {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {},
    };

    const wrapperstyles = () => {
        if (parentHeight) {
            return {
                height: `${parentHeight}px`
            }
        }
        return {}
    }
    return (
        <Container type={status} style={{ ...wrapperstyles() }} >
            <Lottie options={defaultOptions} height={90} width={90} />
        </Container>
    );
}
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ type }) => (type === "activity" ? "600px" : "100%")};
    height: ${({ type }) => (type === "followup_executive" ? "40vh" : "100vh")};
`;
