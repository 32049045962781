import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

function FilterModal({ isFilter, setFilter, open, setOpen }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const toggleDrop = () => {
        setOpen(!open);
    };
    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <>
            {open && (
                <Container ref={wrapperRef}>
                    <DropDown onClick={toggleDrop}>
                        <Choice
                            className={isFilter === "all" ? "active" : ""}
                            onClick={() => (
                                setSearchParams({ filter: "all" }),
                                setFilter("all"),
                                setOpen(open)
                            )}
                        >
                            <Span>
                                {isFilter === "all" ? (
                                    <SelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                        }
                                    />
                                ) : (
                                    <UnSelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                        }
                                    />
                                )}
                            </Span>
                            <Text>All</Text>
                        </Choice>
                        <Choice
                            className={isFilter === "online" ? "active" : ""}
                            onClick={() => (
                                setSearchParams({ filter: "online" }),
                                setFilter("online"),
                                setOpen(open)
                            )}
                        >
                            <Span>
                                {isFilter === "online" ? (
                                    <SelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                        }
                                    />
                                ) : (
                                    <UnSelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                        }
                                    />
                                )}
                            </Span>
                            <Text>Online</Text>
                        </Choice>
                        <Choice
                            className={isFilter === "offline" ? "active" : ""}
                            onClick={() => (
                                setSearchParams({ filter: "offline" }),
                                setFilter("offline"),
                                setOpen(open)
                            )}
                        >
                            <Span>
                                {isFilter === "offline" ? (
                                    <SelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                        }
                                    />
                                ) : (
                                    <UnSelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                        }
                                    />
                                )}
                            </Span>
                            <Text>Offline</Text>
                        </Choice>
                    </DropDown>
                </Container>
            )}
        </>
    );
}

export default FilterModal;
const Overley = styled.div`
    /* width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    right: 0%; */
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
`;
const Container = styled.div`
    position: relative;
`;

const DropDown = styled.div`
    position: absolute;
    z-index: 200;
    right: 30px;
    top: 142px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    box-shadow: 0px 4px 60px rgb(0 0 0 / 50%);
    overflow: visible;
    background: #1f2934;
    max-height: 430px;
    transition: 0.5s ease;
    border-radius: 5px;
    width: 250px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    @media all and (max-width: 360px) {
        width: 230px;
    }
`;
const Choice = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    &.active {
        background: #eeeeee;
        border-radius: 4px;
        &::before {
            content: "";
            width: 3px;
            height: 27px;
            background: #26bf87;
            position: absolute;
            left: 0;
            border-radius: 0 8px 8px 0;
        }
    }
`;
const Span = styled.span`
    display: inline-block;
    margin-right: 10px;
    width: 20px;
`;
const SelectedImg = styled.img``;
const UnSelectedImg = styled.img`
    padding-right: 5px;
    margin-left: 2px;
`;
const Text = styled.p`
    color: #747474;
    font-size: 12px;
`;
