import React, { useEffect, useState } from "react";
import styled from "styled-components";
import jobifiedAuthConfig from "../../axiosConfig";
import moment from "moment/moment";

function InterviewCardFilters({
    item,
    index,
    filter,
    sendMail,
    deleteInterview,
}) {
    const [isAssign, setIsAssign] = useState(false);
    const [selectedExaminerId, setSelectedExaminerId] = useState("");
    const [selectedCandidateId, setSelectedCandidateId] = useState("");

    //assign api
    const [clicked, setClicked] = useState(false);

    const [assign, setAssign] = useState([]);

    const [page, setPage] = useState(1);

    const fetchAssign = () => {
        jobifiedAuthConfig
            .get("/users/chief/examiners/available-examiners/", {
                q: selectedExaminerId,
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setAssign([...assign, ...data.data]);
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        item.id === selectedCandidateId && fetchAssign();
    }, [page, selectedCandidateId]);

    const handleInfiniteScroll = () => {
        if (
            document.getElementById("college").scrollTop + 150 >=
            parseInt(
                getComputedStyle(document.getElementById("innerCollege")).height
            )
        ) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        document
            .getElementById("college")
            ?.addEventListener("scroll", handleInfiniteScroll);
    }, [clicked]);
    const [assignItem, setAssignItem] = useState("");
    return (
        <DataDiv id={item.id} filter={filter}>
            <Data>
                {index + 1 < 10 && 0}
                {index + 1}
            </Data>
            <NameData>{item.name}</NameData>
            <Data className="assign">
                {/* {item.candidate ? item.candidate?.name : "pending"} */}
                <Li
                    style={{ fontSize: "14px" }}
                    value={item.candidate ? item.candidate?.name : "pending"}
                />
                <ImgContainer
                    onClick={() => {
                        setIsAssign(!isAssign);
                        setClicked(!clicked);
                        setSelectedExaminerId(item.id);
                    }}
                >
                    <img
                        src={
                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down.svg"
                        }
                        alt="Drop-down"
                    />
                </ImgContainer>
                <div
                    id="college"
                    className="list-page"
                    style={{
                        width: "max-content",
                        maxHeight: "150px",
                        overflowY: "scroll",
                        zIndex: "1",
                        position: "absolute",
                        border: "1px solid #adadad",
                        borderRadius: "5px",
                        top: "35px",
                        left: "0px",
                        opacity: isAssign ? "1" : "0",
                    }}
                >
                    {item.id === selectedExaminerId && isAssign && (
                        <div
                            id="innerCollege"
                            style={{
                                padding: "10px",
                                background: "#fff",
                                height: "100%",
                            }}
                        >
                            {assign.map((data) => (
                                <div
                                    className="list"
                                    style={{
                                        fontSize: "14px",
                                        cursor: "pointer",
                                        marginBottom: "5px",
                                        padding: "2px",
                                        fontSize: "14px",
                                    }}
                                    onClick={() => {
                                        setAssignItem(data.name);
                                        setIsAssign(!isAssign);
                                        setAssign([]);
                                        setSelectedCandidateId(data.id);
                                        setPage(1);
                                    }}
                                >
                                    {data.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Data>
            <Data>{moment(item.date).format("DD-MM-YYYY")}</Data>
            <Data>{item.time}</Data>
            <Data>{item.interview_type}</Data>
            <Data>{item.campus_code}</Data>
            <Data>
                <Button
                    onClick={() => {
                        item.status !== "cancelled" && deleteInterview(item.id);
                    }}
                    style={{
                        cursor:
                            item.status === "cancelled"
                                ? "not-allowed"
                                : "pointer",
                    }}
                >
                    {item.status === "cancelled" ? "cancelled" : "cancel"}
                </Button>
                <Button
                    className="send"
                    onClick={() => {
                        sendMail(item.id);
                    }}
                >
                    sent
                </Button>
            </Data>
        </DataDiv>
    );
}

export default InterviewCardFilters;
const DataDiv = styled.ul`
    display: grid;
    min-width: 1145px;
    grid-template-columns: ${({ filter }) =>
        filter == "online"
            ? "1fr 5fr 3fr 3fr  3fr 5fr 3fr 4fr"
            : filter == "offline"
            ? "1fr 5fr 3fr 3fr  3fr 5fr 3fr 4fr"
            : "1fr 4fr 3fr 3fr 3fr 3fr 5fr 3fr 4fr"};
    grid-column-gap: 15px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 8px;
`;
const NameData = styled.li`
    font-size: 14px;
    color: #747474;
    height: 80px;
    display: flex;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Li = styled.input`
    width: 80%;
    height: 100%;
    color: #adadad;
    font-size: 14px;
    font-family: gordita_regular;
`;
const ImgContainer = styled.div`
    width: 10px;
    cursor: pointer;
`;
const Data = styled.li`
    font-size: 13px;
    color: #747474;
    height: 80px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: scroll;
    text-overflow: ellipsis;
    &::-webkit-scrollbar {
        display: none;
    }
    &.date {
        font-size: 12px;
    }
    &.assign {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #000;
        img {
            width: 10px;
            display: block;
            margin-left: 10px;
            cursor: pointer;
        }
    }
    &:first-child {
        padding-left: 15px;
    }
    &:last-child {
        padding-right: 10px;
    }
    &.link {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #4eaaff;
        font-family: gordita_regular;
        font-size: 14px;
        cursor: pointer;
        overflow: scroll;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    &.btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    img {
        width: 15px;
    }
`;
const Button = styled.span`
    font-size: 12px;
    color: #e02b1d;
    text-transform: capitalize;
    font-family: gordita_medium;
    cursor: pointer;
    border-radius: 6px;
    &.send {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 30px;
        margin-left: 10px;
        color: #fff;
        background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    }
`;
const Number = styled.p`
    font-size: 12px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    span {
        font-size: 12px;
    }
`;
