import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import jobifiedAuthConfig from "../../../axiosConfig";

function EditModal({
    isModal,
    setModal,
    setUpload,
    setName,
    name,
    thumbnail,
    isId,
    setId,
    refresh,
    setRefresh,
}) {
    const [isUploaderLoading, setUploaderLoading] = useState(false);
    const [video, setVideo] = useState(null);

    const [inputs, setInputs] = useState({ title: "", description: "" });

    // api connection
    const submitHandler = () => {
        const formData = new FormData();

        formData.append("title", inputs.title);
        formData.append("description", inputs.description);

        jobifiedAuthConfig
            .post(`/orientations/chief/edit/${isId}/`, formData)
            .then((response) => {
                console.log(response.data);
                const { statusCode } = response.data;

                if (statusCode === 6000) {
                    setName(inputs.title);
                    setRefresh(!refresh);
                    setModal(!isModal);
                }

                setUploaderLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setUploaderLoading(false);
            });
    };

    return (
        <>
            {isModal && (
                <Container
                    style={{
                        opacity: isModal && "1",
                        visibility: isModal && "unset",
                    }}
                >
                    <Overlay
                        onClick={() => {
                            setModal(false);
                        }}
                    ></Overlay>
                    <Section>
                        <TopSection>
                            <Title>Edit Orientation Class</Title>
                        </TopSection>
                        <UploadSection>
                            <CloseIcon>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fclose-circle.svg"
                                    }
                                    alt="cross"
                                />
                            </CloseIcon>
                            <ImgContainer>
                                <img src={thumbnail} alt="upload" />
                            </ImgContainer>
                            <Name>{name}</Name>
                            <Size>16MB</Size>
                        </UploadSection>
                        <Contents>
                            <Div>
                                <Label>Title</Label>
                                <Input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder="Type Your Title"
                                    value={inputs.title}
                                    onChange={(e) =>
                                        setInputs({
                                            ...inputs,
                                            title: e.target.value,
                                        })
                                    }
                                />
                            </Div>
                            <Div>
                                <Label>Description</Label>
                                <textarea
                                    id="review"
                                    name="review"
                                    rows="4"
                                    cols="50"
                                    placeholder="Add description"
                                    value={inputs.description}
                                    onChange={(e) =>
                                        setInputs({
                                            ...inputs,
                                            description: e.target.value,
                                        })
                                    }
                                >
                                    Talrop 01
                                </textarea>
                            </Div>
                        </Contents>
                        <BottomSection>
                            <Cancel onClick={() => setModal(!isModal)}>
                                cancel
                            </Cancel>
                            <Save
                                onClick={() => {
                                    submitHandler();
                                }}
                            >
                                save changes
                            </Save>
                        </BottomSection>
                    </Section>
                </Container>
            )}
        </>
    );
}

export default EditModal;
const videoAnimation = keyframes`
    0% { 
        transform:scale(0,0); 
        opacity:0;
     }
    100% { 
        transform:scale(1,1); 
        opacity:1;
     }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    opacity: 0;
    transition: 0.3s;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
`;
const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 38%;
    max-width: 980px;
    max-height: 94vh;
    height: 90vh;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        padding: 30px;
        width: 48%;
        height: 94vh;
    }
    @media all and (max-width: 1080px) {
        width: 56%;
    }
    @media all and (max-width: 980px) {
        width: 65%;
    }
`;
const TopSection = styled.div`
    border-bottom: 2px solid #eeeeee;
    padding-bottom: 20px;
`;
const UploadSection = styled.div`
    border: 2px dashed #adadad;
    border-radius: 8px;
    position: relative;
    height: 220px;
    margin-top: 20px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;
const Contents = styled.div`
    margin-top: 20px;
`;
const Title = styled.h4`
    color: #0a0a0a;
    font-size: 20px;
    font-family: gordita_medium;
`;

const CloseIcon = styled.div`
    width: 30px;
    position: absolute;
    right: 10px;
    top: 12px;
`;
const ImgContainer = styled.div`
    width: 60px;
    height: 60px;
    margin: 10px 0 10px 0;
    img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
`;
const Name = styled.h5`
    color: #747474;
    font-size: 16px;
    font-family: gordita_medium;
`;
const Size = styled.h6`
    color: #747474;
    font-size: 15px;
    margin-top: 8px;
`;
const Div = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    textarea {
        background: #f9f9f9;
        border: 1px solid #d4d4d4;
        border-radius: 8px;
        padding: 14px 10px;
        font-size: 16px;
        font-family: gordita_regular;
    }
`;
const Label = styled.label`
    color: #747474;
    font-size: 18px;
    margin-bottom: 8px;
    font-family: gordita_medium;
`;
const Input = styled.input`
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    padding: 14px 10px;
    font-size: 16px;
    font-family: gordita_regular;
    @media all and (max-width: 1280px) {
        padding: 10px;
    }
`;

const BottomSection = styled.div`
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;
const Cancel = styled.div`
    color: #747474;
    text-transform: capitalize;
    font-size: 14px;
    font-family: gordita_medium;
    border: 1px solid #747474;
    cursor: pointer;
    border-radius: 6px;
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Save = styled.div`
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
    font-family: gordita_medium;
    text-transform: capitalize;
    color: #fff;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    width: 130px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
