import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import styled from "styled-components";

import jobifiedConfig from "../../axiosConfig";
import loader from "../../assets/lotties/loader.json";

function Card() {
    const [isLoading, setLoading] = useState(false);
    const [counts, setCounts] = useState({
        selected: 0,
        subscribed: 0,
        students: 0,
        campuses: 0,
    });
    let items = [
        {
            id: 1,
            title: "Colleges",
            description: "Total colleges associated",
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fbuildings.svg",
            link: "/college",
            slug: "campuses",
        },
        {
            id: 2,
            title: "Subscription",
            description: "Total Subscriptions",
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fnotification.svg",
            count: "10",
            link: "/members",
            slug: "subscribed",
        },
        {
            id: 3,
            title: "Students",
            description: "Total Applied Students",
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fpeople.svg",
            link: "/candidates",
            slug: "students",
        },
        {
            id: 4,
            title: "Selected",
            description: "Total Students Selected",
            image: "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fmedal-star.svg",
            slug: "selected",
        },
    ];

    const fetchCandidatesCount = async () => {
        return jobifiedConfig
            .get("users/chief/candidates/dashboard/")
            .then((response) => {
                const { statusCode, data } = response.data;
                var tempCounts = {};

                if (statusCode === 6000) {
                    tempCounts = {
                        selected: data.selected_students,
                        subscribed: data.subscribed_candidates,
                        students: data.total_students,
                    };
                }
                return tempCounts;
            })
            .catch((error) => {
                setLoading(false);
                return null;
            });
    };

    const fetchCampusCount = async () => {
        return jobifiedConfig
            .get("campuses/chief/dashboard/")
            .then((response) => {
                const { statusCode, data } = response.data;

                var tempCounts = {};

                if (statusCode === 6000) {
                    tempCounts = {
                        campuses: data.campuses_count,
                    };
                }
                return tempCounts;
            })
            .catch((error) => {
                setLoading(false);
                return null;
            });
    };

    // for traditional api call
    const fetchCounts = async () => {
        setLoading(true);
        let candidateCounts = await fetchCandidatesCount();
        let campusCounts = await fetchCampusCount();

        setCounts({ ...candidateCounts, ...campusCounts });
        setLoading(false);
    };

    useEffect(() => {
        fetchCounts();
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };

    return (
        <>
            {items.map((data) => (
                <Container id={data.id} to={data.link}>
                    <Section id={data.id}>
                        <Title>{data.title}</Title>
                        <Description>{data.description}</Description>
                        <CountSection>
                            <Span>
                                {isLoading ? (
                                    <Lottie
                                        options={defaultOptions}
                                        style={{ overflow: "unset" }}
                                        height={22}
                                        width={22}
                                    />
                                ) : data.slug in counts ? (
                                    counts[data.slug]
                                ) : (
                                    0
                                )}
                            </Span>
                            <ImgContainer>
                                <img src={data.image} alt="image" />
                            </ImgContainer>
                        </CountSection>
                    </Section>
                </Container>
            ))}
        </>
    );
}

export default Card;

const LoaderWrapper = styled.section`
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled(Link)`
    width: 24%;
    cursor: pointer;
    border-radius: 14px;
    background-color: ${({ id }) =>
        id === 1
            ? "#ff9f52"
            : id === 2
            ? "#77CD77"
            : id === 3
            ? "#E069E4"
            : "#78CBE1"};
    @media all and (max-width: 980px) {
        width: 48%;
        margin-bottom: 20px;
        &:nth-child(3) {
            margin-bottom: 0;
        }
        &:nth-child(4) {
            margin-bottom: 0;
        }
    }
`;
const Section = styled.div`
    width: 97%;
    background-color: ${({ id }) =>
        id === 1
            ? "#FFEFE2"
            : id === 2
            ? "#EFFCEF"
            : id === 3
            ? "#FEE5FF"
            : "#E6F5F9"};

    margin-left: auto;
    border-radius: 10px;
    padding: 26px;
    @media all and (max-width: 1280px) {
        padding: 23px;
    }
    @media all and (max-width: 1080px) {
        padding: 16px;
    }
`;
const Title = styled.h4`
    color: #0a0a0a;
    font-size: 22px;
    font-family: "gordita_medium";
    @media all and (max-width: 1280px) {
        font-size: 22px;
    }
    @media all and (max-width: 1080px) {
        font-size: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 18px;
    }
`;
const Description = styled.p`
    font-size: 18px;
    color: #747474;
    margin-top: 10px;
    font-family: gordita_medium;
    @media all and (max-width: 1280px) {
        font-size: 14px;
    }
    @media all and (max-width: 1080px) {
        font-size: 13px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const CountSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
`;
const Span = styled.span`
    color: #0a0a0a;
    font-size: 30px;
    font-family: gordita_medium;
    @media all and (max-width: 1280px) {
        font-size: 25px;
    }
    @media all and (max-width: 1080px) {
        font-size: 20px;
    }
`;
const ImgContainer = styled.div`
    width: 50px;
    @media all and (max-width: 1280px) {
        width: 40px;
    }
    @media all and (max-width: 1080px) {
        width: 35px;
    }
`;
