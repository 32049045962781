import React, { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import jobifiedAuthConfig from "../../axiosConfig";
import SectionLoader from "../helpers/SectionLoader";
import InterviewersCard from "../includes/InterviewersCard";
import FilterModal from "../includes/modals/FilterModal";
import MembersModal from "../includes/modals/MembersModal";
import { Context } from "../context/Store";
import SuccessModal from "../includes/modals/SuccessModal";

function Interviewers({ activeClick, setActiveClick }) {
    const [selectedExaminerId, setSelectedExaminerId] = useState("");
    const [selectedCandidateId, setSelectedCandidateId] = useState("");
    const [assignedCandidate, setAssignCandidate] = useState("");

    // filter modal
    const [open, setOpen] = useState(false);
    const [isFilter, setFilter] = useState("all");

    // add new members
    const [isMembers, setMembers] = useState(false);

    const [interviewers, setInterviewers] = useState([]);

    const [isSuccess, setIsSuccess] = useState(false);

    // delete one item
    const [isDeleted, setDeleted] = useState(false);
    const [isId, setId] = useState([]);

    const deleteInverviewers = (id) => {
        setInterviewers(interviewers.filter((item) => item.id !== id));
    };

    // pagination_______________________
    const [pageNumber, setPageNumber] = useState(1);
    const [paginate, setPaginate] = useState({
        startingCount: 0,
        endingCount: 0,
        totalCount: 0,
        totalItems: 0,
    });

    // loading
    const [isLoading, setIsLoading] = useState(false);

    // filter
    const getFilterParams = () => {
        let filterParams = {};

        searchParams.get("filter") === "online" &&
            (filterParams.filter = "online") &&
            setFilter("online");
        searchParams.get("filter") === "offline" &&
            (filterParams.filter = "offline") &&
            setFilter("offline");

        return filterParams;
    };

    const [filterData, setFilterData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterparams, setFilterparams] = useState("");
    useEffect(() => {
        if (searchParams.get("filter") != "all") {
            setFilterparams(searchParams.get("filter"));
        } else {
            setFilterparams("");
        }
    }, [searchParams]);

    // api connection
    const fetchInterviewers = () => {
        const params = getFilterParams();
        setIsLoading(true);
        jobifiedAuthConfig
            .get("/users/chief/examiners/", {
                params: params,
            })
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(data);
                if (statusCode === 6000) {
                    setInterviewers(data.data);
                    setPaginate({
                        endingCount: data.last_item,
                        startingCount: data.first_item,
                        totalCount: data.total_pages,
                        totalItems: data.total_items,
                    });
                    setFilterData(data);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };
    const changePage = ({ selected }) => {
        setPageNumber(selected + 1);
        setSearchParams(pageNumber);
    };

    useEffect(() => {
        !isSuccess && fetchInterviewers();
    }, [pageNumber, searchParams, isSuccess]);

    // to assign
    const assignCandidate = () => {
        jobifiedAuthConfig
            .post(`/users/chief/examiners/assign/${selectedExaminerId}/`, {
                student_id: selectedCandidateId,
            })
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(response.data.data);
                if (statusCode === 6000) {
                    fetchInterviewers();
                    setSelectedCandidateId("");
                    setSelectedExaminerId("");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        selectedCandidateId && selectedExaminerId && assignCandidate();
    }, [selectedCandidateId, selectedExaminerId]);

    // to reassign
    const [buttonLoading, setButtonLoading] = useState(false);
    const reAssignCandidate = () => {
        jobifiedAuthConfig
            .post(
                `/users/chief/examiners/cancel-schedule/${selectedExaminerId}/`,
                {
                    student_id: assignedCandidate,
                }
            )
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(response.data.data);
                if (statusCode === 6000) {
                    fetchInterviewers();
                    setButtonLoading(false);
                    setSelectedCandidateId("");
                    setSelectedExaminerId("");
                }
            })
            .catch((err) => {
                console.log(err);
                setButtonLoading(false);
            });
    };

    useEffect(() => {
        assignedCandidate && selectedExaminerId && reAssignCandidate();
    }, [assignedCandidate, selectedExaminerId]);

    // error
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // loading
    const [loading, setLoading] = useState(false);

    // country
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryselector, setCountryselector] = useState(false);
    const { state, dispatch } = useContext(Context);

    const [selected, setSelected] = useState({
        country_code: "IND",
        flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
        name: "India",
        phone_code: "+91",
        phone_number_length: 10,
        web_code: "IN",
    });
    const handleShow = () => {
        setCountryselector((prevValue) => !prevValue);
    };
    const onSelectHandler = (selected) => {
        setSelectedCountry(selected);
    };

    // name
    const [name, setName] = useState("");
    const onNameChange = (e) => {
        setName(e.target.value);
    };

    // enter phone number
    const [phoneError, setPhoneError] = useState(false);

    const [phone, setPhone] = useState("");
    const onPhoneChange = (e) => {
        const re = /^[0-9\b]+$/;
        let value = e.target.value;
        if (value === "" || re.test(value)) {
            setPhone(value);
            if (value.length !== state.selected_country?.phone_number_length) {
                setPhoneError(true);
                setErrorMessage("Invalid Phone number");
            } else {
                setPhoneError(false);
                setErrorMessage("");
            }
        }
        setError(false);
    };

    // enter email
    const [mailError, setMailError] = useState(false);
    const [email, setMail] = useState("");
    const onEmailChange = (e) => {
        let str = e.target.value;
        setMail(e.target.value);

        if (
            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(str) &&
            str.includes(".com")
        ) {
            setMailError(false);
            setErrorMsg("");
        } else {
            setMailError(true);
            setErrorMsg("Enter a valid email");
        }
    };

    // submit function
    const handleSubmit = () => {
        setLoading(true);
        if (name && phone && phone.length < 15 && email && !mailError) {
            jobifiedAuthConfig
                .post("/users/chief/examiners/create/", {
                    name: name,
                    phone: phone,
                    country: selected.web_code,
                    email: email,
                })
                .then((response) => {
                    const { data, statusCode } = response.data;
                    if (statusCode === 6000) {
                        setError(false);
                        setMembers(false);
                        setLoading(false);
                        setIsSuccess(true);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
        if (name.length === 0 || phone.length === 0 || email.length === 0) {
            setError(true);
            setLoading(false);
        } else {
            setError(true);
            setLoading(false);
        }
    };
    return (
        <>
            <FilterModal
                isFilter={isFilter}
                setFilter={setFilter}
                open={open}
                setOpen={setOpen}
                activeClick={activeClick}
                setActiveClick={setActiveClick}
            />

            <SuccessModal
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                content={
                    "You have successfully created a new member. Click continue to go to the dashboard"
                }
                title={"Successfully Created!"}
            />
            <MembersModal
                isMembers={isMembers}
                setMembers={setMembers}
                isSuccess={isSuccess}
                setIsSuccess={setIsSuccess}
                countryselector={countryselector}
                handleShow={handleShow}
                onSelectHandler={onSelectHandler}
                selectedCountry={selectedCountry}
                setSelected={setSelected}
                onNameChange={onNameChange}
                phone={phone}
                mailError={mailError}
                onEmailChange={onEmailChange}
                selected={selected}
                onPhoneChange={onPhoneChange}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
            />
            <Container>
                <Section>
                    <TopSection>
                        <Heading>Team Members</Heading>
                        <RightSection>
                            <SearchSection>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fsearch-normal.svg"
                                    }
                                    alt="search"
                                />
                                <input type="text" placeholder="Search" />
                            </SearchSection>
                            <Edit onClick={() => setMembers(!isMembers)}>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fplus.svg"
                                    }
                                    alt="plus"
                                />
                                Create Members
                            </Edit>

                            <Add
                                onClick={() => setOpen(!open)}
                                setFilter={setFilter}
                                isFilter={isFilter}
                            >
                                Filter
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fsort.svg"
                                    }
                                    alt="filter"
                                />
                            </Add>
                        </RightSection>
                    </TopSection>
                    <MiddleSection>
                        <Bottom>
                            <Div>
                                <TitleDiv>
                                    <Title>Sl No</Title>
                                    <Title>Name</Title>
                                    <Title>Email</Title>
                                    <Title>Assigned to</Title>
                                    <Title>Date</Title>
                                    <Title>Time</Title>
                                    <Title>Mode</Title>
                                    <Title>College Ref.Code</Title>
                                    <Title>Score</Title>
                                    <Title>Action</Title>
                                </TitleDiv>
                                <Cover>
                                    {isLoading ? (
                                        <SectionLoader status="followup_executive" />
                                    ) : (
                                        interviewers.map((item, index) => (
                                            <InterviewersCard
                                                deleteInverviewers={
                                                    deleteInverviewers
                                                }
                                                item={item}
                                                index={index}
                                                setInterviewers={
                                                    setInterviewers
                                                }
                                                isFilter={isFilter}
                                                isDeleted={isDeleted}
                                                setDeleted={setDeleted}
                                                interviewers={interviewers}
                                                setId={setId}
                                                isId={isId}
                                                startingCount={
                                                    paginate.startingCount
                                                }
                                                selectedExaminerId={
                                                    selectedExaminerId
                                                }
                                                setSelectedExaminerId={
                                                    setSelectedExaminerId
                                                }
                                                selectedCandidateId={
                                                    selectedCandidateId
                                                }
                                                setSelectedCandidateId={
                                                    setSelectedCandidateId
                                                }
                                                setAssignCandidate={
                                                    setAssignCandidate
                                                }
                                                setButtonLoading={
                                                    setButtonLoading
                                                }
                                                buttonLoading={buttonLoading}
                                            />
                                        ))
                                    )}
                                </Cover>
                            </Div>
                            <BottomSection>
                                <Left>
                                    <LeftSection>
                                        Showing{" "}
                                        <span>{paginate.startingCount}</span> to{" "}
                                        <sapn>{paginate.endingCount}</sapn> of{" "}
                                        <span>{paginate.totalItems}</span>{" "}
                                        entries
                                    </LeftSection>
                                </Left>
                                <Right>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={paginate.totalCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationButtons"}
                                        previousLinkClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        breakLabel={"..."}
                                        disabledLinkClassName="disabled-button"
                                        breakLinkClassName="break-link"
                                        breakClassName={"item break-me "}
                                        nextClassName={"item next"}
                                    />
                                </Right>
                            </BottomSection>
                        </Bottom>
                    </MiddleSection>
                </Section>
            </Container>
        </>
    );
}

export default Interviewers;
const Container = styled.div`
    padding: 70px 0 0 273px;
    @media all and (max-width: 1280px) {
        padding-left: 57px;
    }
`;
const Section = styled.div`
    padding: 0px 30px;
    /* @media all and (max-width: 1280px) {
        padding: 0px 20px 0 17px;
    } */
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 26px;

    @media all and (max-width: 1280px) {
        font-size: 24px;
    }
`;
const RightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 39%;
    @media all and (max-width: 1280px) {
        width: 47%;
    }
    @media all and (max-width: 1080px) {
        width: 52%;
    }
    @media all and (max-width: 980px) {
        width: 66%;
    }
`;
const SearchSection = styled.div`
    width: 40%;
    border-radius: 8px;
    border: 2px solid #c4c1c1;
    height: 35px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    img {
        width: 20px;
        display: block;
        margin-right: 10px;
        cursor: pointer;
    }
    input {
        &::placeholder {
            color: #adadad;
            font-size: 14px;
        }
    }
`;
const Edit = styled.span`
    color: #f0f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: gordita_medium;
    font-size: 14px;
    height: 35px;
    width: 170px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    img {
        width: 25px;
        display: block;
        margin-right: 8px;
        @media all and (max-width: 1280px) {
            width: 18px;
            margin-right: 5px;
        }
    }
    @media all and (max-width: 1280px) {
        width: 170px;
    }
`;
const Add = styled.span`
    color: #747474;
    font-family: gordita_medium;
    font-size: 14px;
    display: flex;
    border: 2px solid #c4c1c1;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 35px;
    width: 90px;
    cursor: pointer;
    position: relative;
    img {
        margin-left: 8px;
        width: 20px;
    }
    @media all and (max-width: 1280px) {
        width: 80px;
    }
`;
const MiddleSection = styled.div`
    margin-top: 30px;
`;
const Bottom = styled.div`
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    border-radius: 8px;
    color: #fff;
    height: fit-content;
    min-width: 1110px;
    padding: 20px;
    overflow: hidden;
    position: relative;
    @media all and (max-width: 1280px) {
        min-width: 967px;
    }
    @media all and (max-width: 1080px) {
        min-width: 874px;
    }
    @media all and (max-width: 980px) {
        min-width: 665px;
    }
`;
const Div = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media all and (max-width: 1280px) {
        overflow: scroll;
    }
`;
const TitleDiv = styled.ul`
    display: grid;
    min-width: 1142px;
    grid-template-columns: 2fr 4fr 4fr 4fr 3fr 3fr 2fr 3fr 2fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 15px;
    @media all and (max-width: 1280px) {
        min-width: 1115px;
    }
    @media all and (max-width: 1080px) {
        min-width: 1115px;
    }
`;
const Title = styled.li`
    font-size: 14px;
    color: #747474;
`;
const Cover = styled.div`
    overflow-y: scroll;
    height: calc(100vh - 318px);
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        height: calc(100vh - 318px);
        min-width: 1115px;
    }
`;

const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    @media all and (max-width: 1280px) {
        margin-top: 18px;
    }
    @media all and (max-width: 1080px) {
        margin-top: 30px;
    }
`;
const LeftSection = styled.div`
    color: #747474;
    span {
        font-size: 14px;
    }
`;
const Left = styled.div``;
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    width: 230px;
    .paginationButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;
    }
    li {
        width: 30px;
        color: #747474;
        font-size: 14px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
            width: 30px;
            color: #747474;
            font-size: 14px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            :hover {
                background: #2da99b;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
`;
