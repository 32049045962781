import React, { useState, useRef, useEffect, useContext } from "react";
import Select from "react-dropdown-select";
import styled, { keyframes } from "styled-components";
import jobifiedAuthConfig from "../../../axiosConfig";
import { Context } from "../../context/Store";
import CountrySelector from "../CountrySelector";
import InterviewStatusModal from "./InterviewStatusModal";
import SelectionStatusModal from "./SelectionStatusModal";
import SemesterModal from "./SemesterModal";
import ButtonLoader from "../../helpers/ButtonLoader";

function CreateCandidateModal({ isCreate, setCreate, setSuccess, isSuccess }) {
    // error
    const [isError, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    // to set interview modes
    const [mode, setMode] = useState("online");

    // submit function
    const [name, setName] = useState("");

    const [course, setCourse] = useState("");

    const [college, setCollege] = useState("");

    const [score, setScore] = useState("");

    const [status, setStatus] = useState("");
    const [selection, setSelection] = useState("");

    // email
    const [mailError, setMailError] = useState(false);
    const [email, setMail] = useState("");

    // enter email
    const onEmailChange = (e) => {
        let str = e.target.value;
        setMail(e.target.value);

        if (
            /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,3})+$/.test(str) &&
            str.includes(".com")
        ) {
            setMailError(false);
            setErrorMsg("");
        } else {
            setMailError(true);
            setErrorMsg("Enter a valid email");
        }
    };

    // country
    const [selectedCountry, setSelectedCountry] = useState("");
    const [countryselector, setCountryselector] = useState(false);
    const { state, dispatch } = useContext(Context);

    const [selected, setSelected] = useState({
        country_code: "IND",
        flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
        name: "India",
        phone_code: "+91",
        phone_number_length: 10,
        web_code: "IN",
    });
    const handleShow = () => {
        setCountryselector((prevValue) => !prevValue);
    };
    const onSelectHandler = (selected) => {
        setSelectedCountry(selected);
    };

    // enter phone number
    const [number, setNumber] = useState("");
    const onPhoneChange = (e) => {
        setError(false);
        const re = /^[0-9\b]+$/;
        if (e.target.value === "" || re.test(e.target.value)) {
            setNumber(e.target.value);
        }
        return number;
    };

    // name
    const onNameChange = (e) => {
        setName(e.target.value);
    };

    // course
    const courseChange = (e) => {
        setCourse(e.target.value);
    };

    // semester
    const [semester, setSemester] = useState("");
    const [isSemesterOpen, setIsSemesterOpen] = useState(false);
    const [isCollegeModal, setCollegeModal] = useState(false);
    const onSemesterChange = (e) => {
        const value = e.target.value;
    };

    //campus api
    const [clicked, setClicked] = useState(false);
    const [campuses, setCampuses] = useState([]);
    const [page, setPage] = useState(1);

    const fetchCampuses = () => {
        jobifiedAuthConfig
            .get("/campuses/chief/campuses/", {
                params: {
                    page: page,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setCampuses([...campuses, ...data.data]);
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        fetchCampuses();
    }, [page]);

    const handleInfiniteScroll = () => {
        if (
            document.getElementById("college").scrollTop + 150 >=
            parseInt(
                getComputedStyle(document.getElementById("innerCollege")).height
            )
        ) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        document
            .getElementById("college")
            ?.addEventListener("scroll", handleInfiniteScroll);
    }, [clicked]);
    const [collegeItem, setCollegeItem] = useState({});

    // interview status
    const [isInterviewStatusOpen, setInterviewStatusOpen] = useState(false);
    const [interviewStatus, setInterviewStatus] = useState("");
    const onInterviewChange = (e) => {
        const value = e.target.value;
    };

    // selection status
    const [selectionStatus, setSelectionStatus] = useState("");
    const [isSelectionStatusOpen, setSelectionStatusOpen] = useState(false);

    const onSelectionChange = (e) => {
        const value = e.target.value;
    };

    // score
    const onScoreChange = (e) => {
        const re = /^[0-9\b]+$/;
        const value = e.target.value;
        if (value === "" || re.test(value)) {
            setScore(value);
        }
    };

    // submit function
    const [isLoading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        if (
            name &&
            number &&
            number.length < 15 &&
            course &&
            semester &&
            email &&
            campuses &&
            !mailError
        ) {
            jobifiedAuthConfig
                .post(`/users/chief/candidates/create/`, {
                    name: name,
                    phone_number: number,
                    course: course,
                    semester: semester,
                    email: email,
                    college: collegeItem.id,
                    score: score,
                    selection_status: selectionStatus,
                    interview_status: interviewStatus,
                    country: selected.phone_code,
                })
                .then(function (response) {
                    const { data, statusCode } = response.data;

                    if (statusCode === 6000) {
                        setError(false);
                        setSuccess(!isSuccess);
                        setCreate(!isCreate);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
        if (
            name.length === 0 ||
            number.length === 0 ||
            email.length === 0 ||
            semester.length === 0 ||
            college.length === 0 ||
            course.length === 0
        ) {
            setError(true);
            setLoading(false);
        } else {
            setError(true);
            setLoading(false);
        }
    };

    return (
        <>
            {isCreate && (
                <>
                    <InterviewStatusModal
                        isInterviewStatusOpen={isInterviewStatusOpen}
                        setInterviewStatusOpen={setInterviewStatusOpen}
                        interviewStatus={interviewStatus}
                        setInterviewStatus={setInterviewStatus}
                    />
                    <SelectionStatusModal
                        isSelectionStatusOpen={isSelectionStatusOpen}
                        setSelectionStatusOpen={setSelectionStatusOpen}
                        selectionStatus={selectionStatus}
                        setSelectionStatus={setSelectionStatus}
                    />

                    <SemesterModal
                        isSemesterOpen={isSemesterOpen}
                        setIsSemesterOpen={setIsSemesterOpen}
                        semester={semester}
                        setSemester={setSemester}
                    />
                    <CountrySelector
                        show={countryselector}
                        handleClick={handleShow}
                        onSelectHandler={onSelectHandler}
                        selectedCountry={selectedCountry}
                        selected={selected}
                        setSelected={setSelected}
                    />

                    <Container>
                        <Overlay onClick={() => setCreate(!isCreate)}></Overlay>
                        <Section>
                            <TopSection>
                                <Heading>Create Candidates</Heading>
                            </TopSection>
                            <MiddleSection>
                                <Contents>
                                    <Label>Candidate name*</Label>
                                    <Div>
                                        <Input
                                            className="input"
                                            type="text"
                                            placeholder="Enter name"
                                            onChange={onNameChange}
                                            value={name}
                                        />
                                        <Error
                                            className={
                                                isError && name === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This field required
                                        </Error>
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>Phone number*</Label>
                                    <UserInputs>
                                        <DropDownSection
                                            onClick={() =>
                                                setCountryselector(
                                                    !countryselector
                                                )
                                            }
                                        >
                                            <img
                                                src={
                                                    state.selected_country.flag
                                                        ? state.selected_country
                                                              .flag
                                                        : selected.flag
                                                }
                                                alt="flag"
                                            />
                                        </DropDownSection>
                                        <CountryCode>
                                            {" "}
                                            {state.selected_country.phone_code
                                                ? state.selected_country
                                                      .phone_code
                                                : selected.phone_code}
                                        </CountryCode>
                                        <Input
                                            className="number"
                                            type="phone"
                                            id="phone"
                                            placeholder="Enter your number"
                                            onChange={onPhoneChange}
                                            value={number}
                                            onKeyDown={(evt) =>
                                                evt.key === "e" &&
                                                evt.preventDefault()
                                            }
                                        />
                                        <Error
                                            className={
                                                isError && number === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This Field Required
                                        </Error>
                                    </UserInputs>
                                </Contents>
                                <Contents>
                                    <Label>Course*</Label>
                                    <Div className="select">
                                        <CourseFiled
                                            type="text"
                                            placeholder="Enter Course"
                                            onChange={courseChange}
                                            value={course}
                                        />
                                        <Error
                                            className={
                                                isError && course === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This Field Required
                                        </Error>
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>Semester*</Label>
                                    <Div className="select">
                                        <input
                                            type="text"
                                            value={semester}
                                            onChange={onSemesterChange}
                                        />
                                        <img
                                            onClick={() => {
                                                setIsSemesterOpen(
                                                    !isSemesterOpen
                                                );
                                            }}
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down-arrow.svg"
                                            }
                                            alt="arrow"
                                        />

                                        <Error
                                            className={
                                                isError && semester === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This Field Required
                                        </Error>
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>Email*</Label>
                                    <Div
                                        className={
                                            mailError ? "active" : "email"
                                        }
                                    >
                                        <Input
                                            type="text"
                                            placeholder="Enter email id"
                                            value={email}
                                            onChange={onEmailChange}
                                        />

                                        <Error
                                            className={
                                                (mailError ||
                                                    (isError &&
                                                        email === "")) &&
                                                "active"
                                            }
                                        >
                                            Enter a valid email id
                                        </Error>
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>College*</Label>
                                    <Div className="select">
                                        <Li
                                            style={{ fontSize: "14px" }}
                                            value={collegeItem.name}
                                            placeholder="college"
                                            onClick={() => {
                                                setCollegeModal(
                                                    !isCollegeModal
                                                );
                                                setClicked(!clicked);
                                            }}
                                        />

                                        <div
                                            id="college"
                                            className="list-page"
                                            style={{
                                                maxHeight: "150px",
                                                overflowY: "scroll",
                                                zIndex: "1",
                                                position: "absolute",
                                                border: "1px solid #adadad",
                                                borderRadius: "5px",
                                                width: "100%",
                                                top: "45px",
                                                left: "0",
                                                opacity: isCollegeModal
                                                    ? "1"
                                                    : "0",
                                            }}
                                        >
                                            {" "}
                                            {isCollegeModal && (
                                                <div
                                                    id="innerCollege"
                                                    style={{
                                                        padding: "10px",
                                                        background: "#fff",
                                                        height: "100%",
                                                    }}
                                                >
                                                    {campuses.map((data) => (
                                                        <div
                                                            className="list"
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                cursor: "pointer",
                                                                marginBottom:
                                                                    "5px",
                                                                padding: "2px",
                                                                fontSize:
                                                                    "14px",
                                                            }}
                                                            onClick={() => {
                                                                setCollegeItem(
                                                                    data
                                                                );
                                                                setCollegeModal(
                                                                    !isCollegeModal
                                                                );
                                                                setCampuses([]);

                                                                setPage(1);
                                                            }}
                                                        >
                                                            {data.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <Error
                                            className={
                                                isError && college === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This Field Required
                                        </Error>
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>Score</Label>
                                    <Div>
                                        <Input
                                            type="number"
                                            placeholder="Enter Score"
                                            onChange={onScoreChange}
                                        />
                                    </Div>
                                </Contents>
                                <Contents>
                                    <Label>Interview Status</Label>
                                    <Div className="select">
                                        <input
                                            className="input"
                                            type="text"
                                            value={interviewStatus}
                                            onChange={onInterviewChange}
                                        />
                                        <img
                                            onClick={() => {
                                                setInterviewStatusOpen(
                                                    !isInterviewStatusOpen
                                                );
                                            }}
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down-arrow.svg"
                                            }
                                            alt="arrow"
                                        />
                                    </Div>
                                </Contents>
                                <Contents className="mode">
                                    <Label>Mode</Label>
                                    <Modes>
                                        <Online
                                            onClick={() => setMode("online")}
                                        >
                                            <OuterCircle>
                                                <InnerCircle
                                                    className={
                                                        mode === "online"
                                                            ? "active"
                                                            : ""
                                                    }
                                                ></InnerCircle>
                                            </OuterCircle>
                                            Online
                                        </Online>
                                        <Offline
                                            onClick={() => setMode("offline")}
                                        >
                                            <OuterCircle>
                                                <InnerCircle
                                                    className={
                                                        mode === "offline"
                                                            ? "active"
                                                            : ""
                                                    }
                                                ></InnerCircle>
                                            </OuterCircle>
                                            Offline
                                        </Offline>
                                    </Modes>
                                </Contents>
                                <Contents>
                                    <Label>Selection Status</Label>
                                    <Div className="select">
                                        <input
                                            className="input"
                                            type="text"
                                            value={selectionStatus}
                                            onChange={onSelectionChange}
                                        />
                                        <img
                                            onClick={() => {
                                                setSelectionStatusOpen(
                                                    !isSelectionStatusOpen
                                                );
                                            }}
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down-arrow.svg"
                                            }
                                            alt="arrow"
                                        />
                                    </Div>
                                </Contents>
                            </MiddleSection>
                            <BottomContainer>
                                <Cancel onClick={() => setCreate(!isCreate)}>
                                    Cancel
                                </Cancel>
                                <Submit onClick={handleSubmit}>
                                    {isLoading ? <ButtonLoader /> : "Submit"}
                                </Submit>
                            </BottomContainer>
                        </Section>
                    </Container>
                </>
            )}
        </>
    );
}

export default CreateCandidateModal;

const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
`;

const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 50%;
    max-width: 980px;
    max-height: 95vh;
    height: 95vh;
    padding: 35px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media all and (max-width: 1280px) {
        width: 60%;
    }
    @media all and (max-width: 1080px) {
        width: 70%;
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
`;
const UserInputs = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #d4d4d4;
    height: 40px;
    padding: 0 5px;
    border-radius: 8px;
    position: relative;

    &.valid-phone {
        border: 2px solid #32bcad;
    }

    @media all and (max-width: 1280px) {
        padding: 7px 12px;
    }
`;
const CountryCode = styled.span`
    margin-right: 10px;
    font-size: 14px;
    font-family: "gordita_medium";
`;

const DropDownSection = styled.div`
    width: 40px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    img {
        display: block;
        width: 35px;
        border-radius: 50%;
    }
`;

const TopSection = styled.div`
    border-bottom: 1px solid #d4d4d4;
`;
const Heading = styled.h2`
    color: #0a0a0a;
    font-size: 24px;
    padding-bottom: 25px;
    font-family: gordita_medium;
    @media all and (max-width: 1280px) {
        font-size: 22px;
        padding-bottom: 17px;
    }
`;
const MiddleSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media all and (max-width: 1280px) {
        margin-top: 11px;
    }
    @media all and (max-width: 980px) {
        margin-top: 11px;
    }
`;
const Contents = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
    &.mode {
        width: 100%;
    }
    @media all and (max-width: 1280px) {
        margin-bottom: 11px;
    }
    @media all and (max-width: 980px) {
        margin-bottom: 11px;
    }
`;
const Label = styled.label`
    color: #747474;
    font-size: 16px;
    font-family: gordita_medium;
    margin-bottom: 8px;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
`;
const Input = styled.input`
    font-size: 15px;
    width: 100%;
    padding: 0 10px;
    flex: 1;

    &.number {
        padding: 0;
    }
    &::placeholder {
        color: #adadad;
        font-family: gordita_regular;
    }

    &.assign {
        width: 96%;
    }
`;

const Div = styled.div`
    padding: 0 5px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 44px;
    &.input {
        text-transform: capitalize;
    }
    &:hover {
        border: 1px solid #32bcad;
    }
    &.select {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    img {
        cursor: pointer;
        width: 15px;
        display: block;
    }
    input {
        font-size: 14px;
        color: #adadad;
        font-family: gordita_regular;
    }
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    &.email {
        position: relative;
    }
    &.active {
        border: 1px solid red;
    }
    &.number {
        align-items: center;
        padding: 0 5px;
    }
    span {
        font-size: 14px;
        margin: 0 5px;
    }
`;
const CourseFiled = styled.input`
    width: 100%;
    padding: 0 0 0 10px;
    height: 100%;
    color: #adadad;
    font-size: 14px;
    font-family: gordita_regular;
`;
const Li = styled.input`
    width: 100%;
    padding: 0 0 0 10px;
    height: 100%;
    color: #adadad;
    font-size: 14px;
    font-family: gordita_regular;
`;
const Error = styled.p`
    color: #e02b1d;
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    left: 0;
    display: none;
    &.active {
        display: block;
    }
`;
const Down = styled.img`
    width: 15px;
    display: block;
    @media all and (max-width: 1280px) {
        width: 13px;
    }
`;
const Date = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    padding: 10px;
    position: relative;
    @media all and (max-width: 980px) {
        padding: 8px;
    }
    img {
        display: block;
        width: 25px;
        margin-right: 10px;
        @media all and (max-width: 640px) {
            width: 20px;
        }
    }
    span {
        font-size: 14px;
    }
`;
const CalenderBox = styled.div`
    width: 100%;
    border: none;
    outline: none;
    div.react-datepicker-wrapper > div {
        width: 100% !important;
        border: none !important;
    }
    .react-time-picker {
        box-sizing: border-box;
        border: none !important;
        width: 100% !important;
        display: flex;
        outline: none !important;
    }
    .react-time-picker__button:enabled {
        cursor: pointer;
        display: none !important;
    }
    .react-time-picker__wrapper {
        border: none !important;
    }
    .react-time-picker__inputGroup__input {
        cursor: pointer;
    }
    .react-time-picker__inputGroup__divider {
        margin-right: 6px !important;
    }

    .react-time-picker__clock {
        inset: 37px -11px 13px -6px !important;
    }
    .react-time-picker {
        @media all and (max-width: 640px) {
            height: 28px !important;
        }
    }
`;
const DropDown = styled.div`
    width: 10px;
    cursor: pointer;
    &.date {
        position: absolute;
        right: 8px;
        width: 14px;
    }
    img {
        display: block;
        width: 100%;
    }
    &.time {
        &.time {
            width: 10px;
        }
    }
`;
const TimeRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;

    span {
        font-size: 15px;
    }
`;
const Img = styled.img`
    width: 25px;
    @media all and (max-width: 768px) {
        width: 23px;
        margin-right: 10px;
    }
    @media all and (max-width: 640px) {
        width: 20px;
    }
`;
const Time = styled.div`
    cursor: pointer;
    position: relative;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.react-datepicker-wrapper > div > input {
        width: 100% !important;
    }
`;
const Modes = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Online = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    width: 48%;
    display: flex;
    align-items: center;
    color: #adadad;

    @media all and (max-width: 1280px) {
        font-size: 14px;
        height: 39px;
    }
`;
const OuterCircle = styled.div`
    cursor: pointer;
    border: 1px solid #adadad;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
`;
const InnerCircle = styled.div`
    display: none;
    &.active {
        background: #009262;
        width: 13px;
        height: 13px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: block;
    }
`;
const Offline = styled.div`
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    background: #f9f9f9;
    color: #adadad;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    width: 48%;
    display: flex;
    align-items: center;
    @media all and (max-width: 1280px) {
        font-size: 14px;
        height: 39px;
    }
`;
const Location = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    margin-bottom: 35px;
    font-family: gordita_regular;
    width: 100%;
    color: #adadad;

    &.active {
        color: #adadad;
    }

    @media all and (max-width: 1280px) {
        padding: 7px 10px;
    }
`;
const LocationBox = styled.div`
    border: 1px solid #adadad;
    z-index: 1000;
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -70px;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;
const List = styled.div`
    text-transform: capitalize;
    color: #000;
    font-family: gordita_regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;

    :hover {
        background-color: rgb(240 255 244);
    }
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const Cancel = styled.div`
    width: 130px;
    height: 45px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
    border: 1px solid #adadad;
    border-radius: 6px;
    margin-right: 20px;
`;
const Submit = styled.div`
    width: 130px;
    height: 45px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
`;
