import React, { useState } from "react";
import styled from "styled-components";
import jobifiedAuthConfig from "../../axiosConfig";

function ListItemComponent({
    candidateItems,
    setDeleted,
    setId,
    changeSelectionStatus,
}) {
    // to filter interview status
    const [selectedItem, setSelectedItem] = useState("");

    const [clickedId, setClickedId] = useState("");

    // to take interview status values
    const [selectedStatus, setSelectedStatus] = useState("");

    // const status = [
    //     {
    //         id: 1,
    //         title: "completed",
    //         key: "completed",
    //     },
    //     {
    //         id: 2,
    //         title: "scheduled",
    //         key: "scheduled",
    //     },
    //     {
    //         id: 3,
    //         title: "pending",
    //         key: "pending",
    //     },
    // ];
    // to filter selection_status
    const [selectItem, setSelectItem] = useState("");
    const [selectionStatus, setSelectionStatus] = useState(false);
    const [selectionStatusId, setSelectionStatusId] = useState("");

    // to take selection_status values
    const select = [
        {
            id: 1,
            title: "Selected",
            key: "selected",
        },
        {
            id: 2,
            title: "Pending",
            key: "pending",
        },
        {
            id: 3,
            title: "Rejected",
            key: "rejected",
        },
    ];

    // api connection for deletion
    const deleteCandidate = (id) => {
        setDeleted(true);

        jobifiedAuthConfig
            .post(`/users/chief/candidates/delete/${id}/`, {})
            .then((response) => {
                console.log(response.data.data);
                const { statusCode, data } = response.data;
                if (statusCode === 6000) {
                    setId(data.id);
                    console.log("deleted");
                    setDeleted(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setDeleted(false);
            });
    };
    return (
        <>
            {candidateItems.map((item, index) => (
                <DataDiv id={item.id}>
                    <Data>
                        {index + 1 < 10 && 0}
                        {index + 1}
                    </Data>
                    <NameData>
                        {item.name}
                        <Number>
                            <span>+91 </span> {item.mobile}
                        </Number>
                    </NameData>
                    <Data>{item.email}</Data>
                    <NameData>
                        {item.course}
                        <Sem>{item.semester}</Sem>
                    </NameData>
                    <Data>{item.campus_code}</Data>

                    <Data className="score">{item.score}</Data>

                    <Data
                        className="status"
                        onClick={() => {
                            if (item.id !== clickedId) {
                                setClickedId(item.id);
                            } else {
                                setClickedId("");
                            }

                            setSelectedItem(item.id);
                        }}
                    >
                        {selectedStatus
                            ? selectedStatus
                            : item.interview_status}

                        {/* <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down.svg"
                            }
                            alt="arrow"
                        /> */}
                        {/* {item.id === clickedId && (
                            <Contents>
                                <List>
                                    {status.map((data) => (
                                        <ListItem
                                            onClick={() => {
                                                setSelectedStatus(data.key);
                                            }}
                                        >
                                            {data.title}
                                        </ListItem>
                                    ))}
                                </List>
                            </Contents>
                        )} */}
                    </Data>

                    <Data>{item.interview_mode}</Data>
                    <Data
                        className="mode"
                        onClick={() => {
                            setSelectionStatus(!selectionStatus);
                            setSelectionStatusId(item.id);
                        }}
                    >
                        {selectItem && item.popup === item.id
                            ? selectItem
                            : item.selection_status}

                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down.svg"
                            }
                            alt="arrow"
                        />
                        {selectionStatus && selectionStatusId === item.id && (
                            <Contents className="selection">
                                <List>
                                    {select.map((data) => (
                                        <ListItem
                                            onClick={() => {
                                                setSelectItem(data.title);
                                                changeSelectionStatus(
                                                    item.id,
                                                    data.key
                                                );
                                            }}
                                        >
                                            {data.title}
                                        </ListItem>
                                    ))}
                                </List>
                            </Contents>
                        )}
                    </Data>
                    <Data>
                        {/* loader */}
                        <img
                            src={
                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Ftrash.svg"
                            }
                            alt="action"
                            onClick={() => {
                                setId(item.id);
                                deleteCandidate(item.id);
                            }}
                        />
                    </Data>
                </DataDiv>
            ))}
        </>
    );
}

export default ListItemComponent;
const Contents = styled.div`
    z-index: 10000;
    position: absolute;
    top: 55%;
    right: 29%;
    width: 100px;
    height: 100px;
    box-shadow: 0px 1px 30px -6px #7f7f7f;
    padding: 15px;
    background-color: #fff;
    &.selection {
        top: 55%;
        right: 9%;
    }

    &.active {
        display: block;
        z-index: 10000;
        position: absolute;
        top: 0%;
        right: 0%;
        width: 100px;
        height: 110px;
        box-shadow: -3px -2px 80px -12px #7f7f7f;
        padding: 15px;
        background-color: #fff;
    }
`;
const ListItem = styled.div`
    cursor: pointer;
    font-size: 13px;
    font-family: gordita_regular;
    color: #7f7f7f;
`;
const List = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const DataDiv = styled.ul`
    display: grid;
    min-width: 1142px;
    grid-template-columns: 2fr 4fr 4fr 4fr 3fr 2fr 4fr 2fr 4fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 10px;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 10px;
    position: relative;
`;
const NameData = styled.li`
    font-size: 14px;
    color: #747474;
    height: 60px;
    display: flex;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Data = styled.li`
    font-size: 14px;
    color: #747474;
    height: 80px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: scroll;
    text-overflow: ellipsis;
    &::-webkit-scrollbar {
        display: none;
    }

    &.score {
        color: #000;
    }
    &.status {
        color: #000;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 14px;
            cursor: pointer;
            margin-right: 30px;
        }
    }
    &.mode {
        color: #000;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* position: relative; */

        img {
            width: 14px;
            margin-left: 5px;
        }
    }
    img {
        width: 20px;
        cursor: pointer;
    }
`;
const Sem = styled.p`
    font-size: 12px;
`;
const Number = styled.p`
    font-size: 12px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    span {
        font-size: 12px;
    }
`;
