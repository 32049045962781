import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import jobifiedAuthConfig from "../../axiosConfig";
import SectionLoader from "../helpers/SectionLoader";
import InterviewCard from "../includes/InterviewCard";
import AddInterviewModal from "../includes/modals/AddInterviewModal";
import "react-datepicker/dist/react-datepicker.css";
import { Context } from "../context/Store";
import { toFormData } from "axios";
import { getYYMMDDnew, toYYYYMMDD } from "../helpers/HelperFunctions";

function Interview() {
    const [typedCandidateName, setTypedCandidateName] = useState("");
    // add interview
    const [interview, setInterview] = useState(false);
    const [interviewItems, setInterviewItems] = useState([]);
    const [filter, setFilter] = useState("");

    // pagination
    const [paginate, setPaginate] = useState({
        startingCount: 0,
        endingCount: 0,
        totalCount: 0,
        totalItems: 0,
    });
    const [pageNumber, setPageNumber] = useState(1);

    const changePage = ({ selected }) => {
        setPageNumber(selected + 1);
    };
    // loading
    const [isLoading, setIsLoading] = useState(false);

    // search action
    const [searchKeyword, setSearch] = useState("");
    const onSearchChange = (e) => {
        setSearch(e.target.value);
    };

    //
    const [selectedCandidateId2, setSelectedCandidateId2] = useState("");
    const [isCandidateModal, setCandidateModal] = useState(false);
    const [selectedCandidateName, setSelectedCandidateName] = useState("");
    const [assignName, setAssignName] = useState("");
    const [assignClicked, setAssignClicked] = useState(false);
    const [selectedLocationName, setSelectedLocationName] = useState("");
    const [locationClick, setLocationClick] = useState(false);
    const [typedAssignValue, setTypedAssignValue] = useState("");
    const [typedLocationName, setTypedLocationName] = useState("");
    // filter
    const getFilterParams = () => {
        let filterParams = {};

        searchParams.get("filter") === "online" &&
            (filterParams.filter = "online") &&
            setFilter("online");
        searchParams.get("filter") === "offline" &&
            (filterParams.filter = "offline") &&
            setFilter("offline");

        return filterParams;
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const [filterparams, setFilterparams] = useState("");

    useEffect(() => {
        if (searchParams.get("filter") != "all") {
            setFilterparams(searchParams.get("filter"));
        } else {
            setFilterparams("");
        }
    }, [searchParams]);

    // api connection
    const fetchInterviews = () => {
        const filter = getFilterParams();

        jobifiedAuthConfig
            .get("/interviews/chief/interviews/", {
                params: {
                    page: pageNumber,
                    q: searchKeyword,
                    filter: filter,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;
                if (statusCode === 6000) {
                    setInterviewItems(data.data);
                    setPaginate({
                        endingCount: data.last_item,
                        startingCount: data.first_item,
                        totalCount: data.total_pages,
                        totalItems: data.total_items,
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchInterviews();
    }, [pageNumber, searchParams, filter]);

    const [selectedCandidateId, setSelectedCandidateId] = useState("");

    // sent mail to candidate
    const sendMail = (id) => {
        jobifiedAuthConfig
            .post(`/interviews/chief/send-mail/${id}/`, {})
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(response.data);
                if (statusCode === 6000) {
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // cancel
    const deleteInterview = (id) => {
        jobifiedAuthConfig
            .post(`/interviews/chief/cancel-schedule/${id}/`, {})
            .then((response) => {
                const { statusCode, data } = response.data;
                console.log(response.data);
                if (statusCode === 6000) {
                    setInterviewItems();
                    fetchInterviews();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const [clicked, setClicked] = useState(false);

    // listing candidate
    const [candidate, setCandidate] = useState("");
    const [adding, setAdding] = useState(false);
    const [candidates, setCandidates] = useState([]);

    const fetchAvailableCandidate = () => {
        jobifiedAuthConfig
            .get("/users/chief/candidates/available-candidates/", {
                params: {
                    q: typedCandidateName,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setCandidates([...candidates, ...data.data]);
                }
            })
            .catch((err) => {});
    };
    useEffect(() => {
        const delay = 500; // milliseconds
        const timeoutId = setTimeout(() => {
            fetchAvailableCandidate();
            if (typedCandidateName) {
                fetchAvailableCandidate();
            }
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [typedCandidateName, isCandidateModal]);

    // useEffect(() => {
    //     fetchAvailableCandidate();
    // }, [typedCandidateName, isCandidateModal]);

    const handleAvailable = () => {
        if (
            document.getElementById("assign").scrollTop + 150 >=
            parseInt(
                getComputedStyle(document.getElementById("innerCandidate"))
                    .height
            )
        ) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        document
            .getElementById("candidate")
            ?.addEventListener("scroll", handleAvailable);
    }, [clicked]);

    // assign api
    const [assigned, setAssigned] = useState([]);
    const [page, setPage] = useState(1);

    const fetchAssign = () => {
        console.log("varsha");
        jobifiedAuthConfig
            .get("/users/chief/examiners/", {
                params: {
                    page: page,
                    q: typedAssignValue,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setAssigned([...assigned, ...data.data]);
                }
            })
            .catch((err) => {});
    };
    useEffect(() => {
        const delay = 500;
        const timeoutId = setTimeout(() => {
            fetchAssign();
            if (typedAssignValue) {
                fetchAssign();
            }
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [page, assignClicked, typedAssignValue]);

    const handleInfiniteScroll = () => {
        if (
            document.getElementById("assign").scrollTop + 150 >=
            parseInt(
                getComputedStyle(document.getElementById("innerAssign")).height
            )
        ) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        document
            .getElementById("assign")
            ?.addEventListener("scroll", handleInfiniteScroll);
    }, [assignClicked]);
    const [assignItem, setAssignItem] = useState("");
    const [isAssignModal, setAssignModal] = useState(false);

    //campus api
    const [college, setCollege] = useState("");
    const [clicking, setClicking] = useState(false);
    const [campuses, setCampuses] = useState([]);
    const [pages, setPages] = useState(1);

    const fetchCampuses = () => {
        jobifiedAuthConfig
            .get("/interviews/locations/", {
                params: {
                    page: page,
                    q: typedLocationName,
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setCampuses([...campuses, ...data.data]);
                }
            })
            .catch((err) => {});
    };

    useEffect(() => {
        const delay = 500; // milliseconds
        const timeoutId = setTimeout(() => {
            fetchCampuses();
            if (typedLocationName) {
                fetchCampuses();
            }
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [page, locationClick, typedLocationName]);
    // useEffect(() => {
    //     fetchCampuses();
    // }, [page, locationClick, typedLocationName]);

    const handleInfiniteScrolling = () => {
        if (
            document.getElementById("assign").scrollTop + 150 >=
            parseInt(
                getComputedStyle(document.getElementById("innerCollege")).height
            )
        ) {
            setPage(page + 1);
        }
    };
    useEffect(() => {
        document
            .getElementById("college")
            ?.addEventListener("scroll", handleInfiniteScrolling);
    }, [clicked]);

    const [collegeItem, setCollegeItem] = useState("");
    const [isCollegeModal, setCollegeModal] = useState(false);

    // error function
    const [isError, setError] = useState(false);

    // to get location
    const [location, setLocation] = useState({
        name: "Choose location",
        opening_time: "10:00",
        closing_time: "04:00",
    });

    // to change mode
    const [mode, setMode] = useState("online");

    // meetlink
    const [meetlink, setMeetLink] = useState("");
    const onMeetlink = (e) => {
        setMeetLink(e.target.value);
    };
    // date
    const calenderRef = useRef();
    let minDate = new window.Date();
    const [startDate, setStartDate] = useState(new Date());

    const handleDateChange = (value) => {
        const selectedDate = toYYYYMMDD(value);

        setStartDate(new Date(selectedDate));
    };

    // time
    const [value, onChange] = useState("00:00");
    useEffect(() => {
        console.log(startDate, "---");
    }, [startDate]);

    // submit function
    const addInterviewSubmit = () => {
        setIsLoading(true);

        let params = {
            candidate_id: selectedCandidateId2,
            interview_date: toYYYYMMDD(startDate),
            interview_time: value,
            interview_mode: mode,
        };
        if (collegeItem && mode === "offline") {
            params.interview_location_id = collegeItem;
        }
        if (meetlink && mode === "offline") {
            params.meet_link = meetlink;
        }

        if (selectedCandidateId2) {
            jobifiedAuthConfig
                .post(`/interviews/chief/create/`, params)
                .then((response) => {
                    const { statusCode, data } = response.data;

                    if (statusCode === 6000) {
                        setError(false);
                        setInterview(false);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                });
        }
    };

    return (
        <>
            <AddInterviewModal
                interview={interview}
                setInterview={setInterview}
                selectedCandidateId2={selectedCandidateId2}
                setCandidateModal={setCandidateModal}
                isCandidateModal={isCandidateModal}
                setAdding={setAdding}
                adding={adding}
                fetchCampuses={fetchCampuses}
                searchKeyword={searchKeyword}
                candidates={candidates}
                setSelectedCandidateId2={setSelectedCandidateId2}
                setCollegeModal={setCollegeModal}
                isCollegeModal={isCollegeModal}
                setCandidates={setCandidates}
                setPages={setPages}
                isError={isError}
                setCandidate={setCandidate}
                assignItem={assignItem}
                setAssignModal={setAssignModal}
                isAssignModal={isAssignModal}
                setClicked={setClicked}
                clicked={clicked}
                assigned={assigned}
                setAssignItem={setAssignItem}
                setAssigned={setAssigned}
                setPage={setPage}
                calenderRef={calenderRef}
                startDate={startDate}
                setStartDate={setStartDate}
                minDate={minDate}
                onChange={onChange}
                value={value}
                setMode={setMode}
                mode={mode}
                collegeItem={collegeItem}
                setClicking={setClicking}
                clicking={clicking}
                campuses={campuses}
                college={college}
                setCollegeItem={setCollegeItem}
                addInterviewSubmit={addInterviewSubmit}
                setCampuses={setCampuses}
                meetlink={meetlink}
                setMeetLink={setMeetLink}
                onMeetlink={onMeetlink}
                handleDateChange={handleDateChange}
                selectedCandidateName={selectedCandidateName}
                setSelectedCandidateName={setSelectedCandidateName}
                assignName={assignName}
                setAssignName={setAssignName}
                assignClicked={assignClicked}
                setAssignClicked={setAssignClicked}
                selectedLocationName={selectedLocationName}
                setSelectedLocationName={setSelectedLocationName}
                locationClick={locationClick}
                setLocationClick={setLocationClick}
                typedCandidateName={typedCandidateName}
                setTypedCandidateName={setTypedCandidateName}
                typedAssignValue={typedAssignValue}
                setTypedAssignValue={setTypedAssignValue}
                typedLocationName={typedLocationName}
                setTypedLocationName={setTypedLocationName}
            />
            <Container>
                <Section>
                    <TopSection>
                        <Heading>Interview</Heading>
                        <RightSection>
                            <SearchSection>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fsearch-normal.svg"
                                    }
                                    alt="search"
                                />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={onSearchChange}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            fetchInterviews();
                                            console.log(searchKeyword);
                                        }
                                    }}
                                />
                            </SearchSection>
                            <Edit onClick={() => setInterview(!interview)}>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fplus.svg"
                                    }
                                    alt="plus"
                                />
                                Add Interview
                            </Edit>
                        </RightSection>
                    </TopSection>
                    <NavBar>
                        <NavSection>
                            <Link
                                onClick={() => {
                                    setFilter("");
                                    setSearchParams({ filter: "all" });
                                }}
                                className={filter === "" && "active"}
                            >
                                All
                            </Link>
                            <Link
                                onClick={() => {
                                    setFilter("online");
                                    setSearchParams({ filter: "online" });
                                }}
                                className={filter === "online" && "active"}
                            >
                                Online
                            </Link>
                            <Link
                                onClick={() => {
                                    setFilter("offline");
                                    setSearchParams({ filter: "offline" });
                                }}
                                className={filter === "offline" && "active"}
                            >
                                Offline
                            </Link>
                        </NavSection>
                    </NavBar>

                    <MiddleSection>
                        <Bottom>
                            <Div>
                                <TitleDiv filter={filter}>
                                    <Title>Si No</Title>
                                    <Title>Candidate Name</Title>
                                    <Title>Assigned to</Title>
                                    <Title>Date</Title>
                                    <Title>Time</Title>
                                    {filter !== "" ? (
                                        ""
                                    ) : (
                                        <Title>Interview Modes</Title>
                                    )}
                                    {filter == "online" ? (
                                        <Title>Meeting link</Title>
                                    ) : filter == "offline" ? (
                                        <Title>Location</Title>
                                    ) : (
                                        <Title>Meeting link/venue</Title>
                                    )}
                                    {filter !== "" ? (
                                        <Title> Ref.Code</Title>
                                    ) : (
                                        <Title>College Ref.Code</Title>
                                    )}

                                    <Title>Action</Title>
                                </TitleDiv>
                                <Cover>
                                    {isLoading ? (
                                        <SectionLoader status="followup_executive" />
                                    ) : (
                                        <InterviewCard
                                            filter={filter}
                                            interviewItems={interviewItems}
                                            setInterviewItems={
                                                setInterviewItems
                                            }
                                            selectedCandidateId={
                                                selectedCandidateId
                                            }
                                            setSelectedCandidateId={
                                                setSelectedCandidateId
                                            }
                                            deleteInterview={deleteInterview}
                                            sendMail={sendMail}
                                            fetchInterviews={fetchInterviews}
                                        />
                                    )}
                                </Cover>
                            </Div>

                            <BottomSection
                                className={isLoading ? "loading" : ""}
                            >
                                <Left>
                                    <LeftSection>
                                        Showing{" "}
                                        <span>{paginate.startingCount}</span> to{" "}
                                        <sapn>{paginate.endingCount}</sapn> of{" "}
                                        <span>{paginate.totalItems}</span>{" "}
                                        entries
                                    </LeftSection>
                                </Left>
                                <Right>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={paginate.totalCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationButtons"}
                                        previousLinkClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        breakLabel={"..."}
                                        disabledLinkClassName="disabled-button"
                                        breakLinkClassName="break-link"
                                        breakClassName={"item break-me "}
                                        nextClassName={"item next"}
                                    />
                                </Right>
                            </BottomSection>
                        </Bottom>
                    </MiddleSection>
                </Section>
            </Container>
        </>
    );
}

export default Interview;
const Container = styled.div`
    padding: 70px 0 0 273px;
    @media all and (max-width: 1280px) {
        padding-left: 81px;
    }
`;
const Section = styled.div`
    padding: 0px 30px;
    /* min-height: 90vh; */
`;
const NavBar = styled.div`
    width: 100%;
    margin: 10px 0 0px;
    padding-bottom: 20px;
    border-bottom: 2px solid #f1ebeb;
`;
const NavSection = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
`;
const Link = styled.span`
    color: #adadad;
    font-size: 14px;
    font-family: gordita_regular;
    cursor: pointer;
    position: relative;
    &::after {
        content: "";
        width: 0%;
        transition: 0.3s;
        height: 2px;
        background-color: #32bcad;
        border-radius: 10px;
        position: absolute;
        bottom: -20px;
        left: 0;
    }
    &.active {
        background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        transition: 0.3s;
        &::after {
            width: 100%;
        }
    }
`;

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 26px;
    margin-bottom: 8px;
`;
const RightSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const SearchSection = styled.div`
    width: 280px;
    height: 40px;
    padding: 0 10px;
    border-radius: 8px;
    border: 2px solid #c4c1c1;
    display: flex;
    align-items: center;
    img {
        width: 20px;
        display: block;
        margin-right: 10px;
    }
    input {
        &::placeholder {
            color: #adadad;
            font-size: 14px;
        }
    }
`;
const Edit = styled.div`
    color: #f0f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: gordita_regular;
    font-size: 14px;
    height: 40px;
    padding: 0 10px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    margin-left: 15px;
    cursor: pointer;
    width: 180px;
    img {
        width: 23px;
        display: block;
        margin-right: 8px;
    }
`;

const MiddleSection = styled.div`
    margin-top: 20px;
    height: 73vh;
`;
const Bottom = styled.div`
    max-height: 71vh;
    display: flex;
    height: 71vh;
    flex-direction: column;
    justify-content: space-between;
    background: #f8f8f8;
    border-radius: 8px;
    color: #fff;
    /* height: fit-content; */
    min-width: 1110px;
    padding: 20px;
    overflow: hidden;
    position: relative;
    @media all and (max-width: 1280px) {
        min-width: 946px;
        max-height: 65vh;
    }
    @media all and (max-width: 1080px) {
        min-width: 847px;
    }

    @media all and (max-width: 980px) {
        min-width: 637px;
    }
`;
const Div = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow-y: scroll;
    @media all and (max-width: 1280px) {
        overflow: scroll;
    }
`;
const TitleDiv = styled.ul`
    display: grid;
    min-width: 1145px;
    grid-template-columns: ${({ filter }) =>
        filter == "online"
            ? "1fr 5fr 3fr 3fr  3fr 5fr 3fr 4fr"
            : filter == "offline"
            ? "1fr 5fr 3fr 3fr  3fr 5fr 3fr 4fr"
            : "1fr 4fr 3fr 3fr 3fr 3fr 5fr 3fr 4fr"};
    grid-column-gap: 15px;
    margin-bottom: 10px;
    @media all and (max-width: 1280px) {
        min-width: 1150px;
    }
    @media all and (max-width: 1080px) {
        min-width: 1138px;
    }
`;
const Title = styled.li`
    font-size: 13px;
    color: #747474;
`;
const Cover = styled.div`
    /* overflow-y: scroll; */
    height: calc(100vh - 378px);
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        height: calc(100vh - 318px);
        min-width: 1150px;
    }
    @media all and (max-width: 1080px) {
        min-width: 1138px;
        height: calc(100vh - 404px);
    }
`;

const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    @media all and (max-width: 1280px) {
        margin-top: 18px;
    }
    @media all and (max-width: 1080px) {
        margin-top: 30px;
    }
`;
const LeftSection = styled.div`
    color: #747474;
    span {
        font-size: 14px;
    }
`;
const Left = styled.div``;
const Right = styled.div`
    width: 60%;
    display: flex;
    justify-content: flex-end;
    width: 280px;
    .paginationButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 265px;
        @media all and (max-width: 1280px) {
            width: 290px;
        }

        .disabled-button {
            cursor: not-allowed;
            /* background: #808080; */
        }
    }
    li {
        width: 30px;
        color: #747474;
        font-size: 14px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
            width: 30px;
            color: #747474;
            font-size: 14px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            :hover {
                background: #2da99b;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
    .paginationActive > a {
        background: #2da99b;
        border-radius: 4px;
        color: #fff;
    }
`;
