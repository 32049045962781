import React, { useState } from "react";
import styled from "styled-components";

import DeleteModal from "./modals/DeleteModal";
import EditCollegeModal from "./modals/EditCollegeModal";
import userIcon from "../../assets/images/Users.svg";
import medalIcon from "../../assets/images/medal.svg";
import notificationIcon from "../../assets/images/notifications.svg";
import jobifiedAuthConfig from "../../axiosConfig";

function CollegeCard({ isEdit, item, setItems }) {
    const [isEditModal, setEdit] = useState(false);
    const [activeCampusId, setActive] = useState("");
    const [activeCampus, setCampus] = useState({});

    // delete
    const [isDelete, setDelete] = useState(false);

    const icons = [
        {
            id: 1,
            slug: "subscribed_students",
            title: "Subscribed Students",
            icon: notificationIcon,
        },
        {
            id: 2,
            slug: "students",
            title: "Applied Students",
            icon: userIcon,
        },
        {
            id: 3,
            slug: "selected_students",
            title: "Selected Students",
            icon: medalIcon,
        },
    ];

    const updateCampusHandler = (campus) => {
        let items_ = [...item];
        const campusIndex = items_.findIndex((item) => item.id === campus.id);

        if (campusIndex !== -1) {
            items_[campusIndex] = campus;
            console.log(items_);
            setItems([...items_]);
        }
    };

    const deleteHandler = (campus_id) => {
        jobifiedAuthConfig
            .post(`/campuses/chief/campuses/delete/${campus_id}/`)
            .then((res) => {
                const { statusCode } = res.data;

                if (statusCode === 6000) {
                    let items_ = [...item];
                    const filteredCampuses = items_.filter(
                        (item) => item.id !== campus_id
                    );

                    setItems(filteredCampuses);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            {isEditModal && (
                <EditCollegeModal
                    setCollege={setEdit}
                    activeCampus={activeCampus}
                    activeCampusId={activeCampusId}
                    updateCampusHandler={updateCampusHandler}
                />
            )}
            {isDelete && (
                <DeleteModal
                    isDelete={isDelete}
                    setDelete={setDelete}
                    deleteHandler={deleteHandler}
                />
            )}
            {item.map((data) => (
                <Container id={data.id}>
                    <TopSection>
                        <Left>
                            <ImgContainer>
                                <img src={data.image} alt="icon" />
                            </ImgContainer>
                        </Left>
                        <Right>
                            <Name>{data.name}</Name>
                            <IdNumber>{data.campus_code}</IdNumber>
                            <div>
                                <Place>
                                    {data.district ?? "null"},
                                    <span>{data.contact_number}</span>
                                </Place>
                                {isEdit && (
                                    <Edit
                                        onClick={() => {
                                            setEdit(true);
                                            setCampus(data);
                                            setActive(data.id);
                                        }}
                                    >
                                        <img
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fedit-2.svg"
                                            }
                                            alt="edit"
                                        />
                                        Edit
                                    </Edit>
                                )}
                            </div>
                        </Right>
                    </TopSection>
                    <MiddleSection>
                        {icons.map((task) => (
                            <>
                                <Section>
                                    <Leftsection>
                                        <ImageContainer>
                                            <img
                                                src={task.icon}
                                                alt="task icon"
                                            />
                                        </ImageContainer>
                                        <Title>{task.title}</Title>
                                    </Leftsection>
                                    <Count>{data[task.slug]}</Count>
                                </Section>
                            </>
                        ))}
                    </MiddleSection>
                    {isEdit && (
                        <CloseIcon
                            onClick={() => {
                                setDelete(data.id);
                            }}
                        >
                            <img
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fclose-icon.svg"
                                }
                                alt="close"
                            />
                        </CloseIcon>
                    )}
                </Container>
            ))}
        </>
    );
}

export default CollegeCard;
const CloseIcon = styled.div`
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
    z-index: 1;
`;
const Container = styled.div`
    position: relative;
    background: #f8f8f8;
    border: 1px solid #eeeeee;
    border-radius: 12px;
    padding: 25px;
    height: fit-content;
    @media all and (max-width: 1280px) {
        width: 97%;
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
const Left = styled.div`
    margin-right: 20px;
`;
const ImgContainer = styled.div`
    width: 70px;

    img {
        border-radius: 50%;
    }
`;

const Right = styled.div`
    width: 100%;
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;
const Name = styled.h4`
    color: #0a0a0a;
    font-size: 16px;
    font-family: "gordita_medium";
`;
const IdNumber = styled.span`
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 14px;
    font-family: gordita_medium;
`;
const Place = styled.p`
    color: #747474;
    font-size: 12px;
    display: flex;
    span {
        font-size: 13px;
        margin-left: 6px;
    }
`;
const Edit = styled.span`
    color: #32bcad;
    display: flex;
    width: 45px;
    font-size: 12px;
    font-family: gordita_medium;
    cursor: pointer;
    img {
        width: 20px;
        display: block;
    }
`;
const MiddleSection = styled.div`
    margin-top: 16px;
`;
const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;
const Leftsection = styled.div`
    display: flex;
    align-items: center;
`;
const ImageContainer = styled.div`
    width: 32px;
    margin-right: 8px;
    border-radius: 50%;
`;
const Title = styled.h5`
    color: #747474;
    font-size: 14px;
    font-family: gordita_medium;
`;
const Count = styled.span`
    font-size: 14px;
    font-family: gordita_medium;
    color: #0a0a0a;
`;
