import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import jobifiedAuthConfig, { jobifiedConfig } from "../../axiosConfig";
import SectionLoader from "../helpers/SectionLoader";
import ListItemComponent from "../includes/ListItemComponent";
import CandidateFilter from "../includes/modals/CandidateFilter";
import CreateCandidateModal from "../includes/modals/CreateCandidateModal";
import SuccessModal from "../includes/modals/SuccessModal";
import { useSearchParams } from "react-router-dom";

function Candidates() {
    // is modal open
    const [isCreate, setCreate] = useState(false);

    const [isId, setId] = useState([]);
    const [candidateItems, setCandidateItems] = useState([]);
    const [isDeleted, setDeleted] = useState(false);

    // loading
    const [isLoading, setIsLoading] = useState(false);

    // pagination
    const [paginate, setPaginate] = useState({
        startingCount: 0,
        endingCount: 0,
        totalCount: 0,
        totalItems: 0,
    });
    const [pageNumber, setPageNumber] = useState(1);

    const changePage = ({ selected }) => {
        setPageNumber(selected + 1);
        setSearchParams({
            ...currentParams,
            page: selected + 1,
        });
    };

    // filter
    const [isFilter, setFilter] = useState(false);
    const [activeClick, setActiveClick] = useState("all");
    const [status, setStatus] = useState("interview");

    const [filterData, setFilterData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    useEffect(() => {
        if (searchParams.get("interview_status") === "pending") {
            setActiveClick("pending");
        } else if (searchParams.get("interview_status") === "scheduled") {
            setActiveClick("scheduled");
        } else if (searchParams.get("interview_status") === "completed") {
            setActiveClick("completed");
        } else if (searchParams.get("selection_status") === "pending") {
            setActiveClick("pending");
        } else if (searchParams.get("selection_status") === "scheduled") {
            setActiveClick("scheduled");
        } else if (searchParams.get("selection_status") === "completed") {
            setActiveClick("completed");
        } else if (searchParams.get("page")) {
            setPageNumber(searchParams.get("page"));
        }
    }, [searchParams]);

    const getFilterParams = () => {
        let filterParams = {};
        if (searchParams.get("interview_status") != null) {
            setStatus("interview");
        } else if (searchParams.get("selection_status") != null) {
            setStatus("selection");
        }

        searchParams.get("interview_status") === "pending" &&
            (filterParams.interview_status = "pending");
        searchParams.get("interview_status") === "scheduled" &&
            (filterParams.interview_status = "scheduled");
        searchParams.get("interview_status") === "completed" &&
            (filterParams.interview_status = "completed");

        searchParams.get("selection_status") === "pending" &&
            (filterParams.selection_status = "pending");
        searchParams.get("selection_status") === "scheduled" &&
            (filterParams.selection_status = "scheduled");
        searchParams.get("selection_status") === "completed" &&
            (filterParams.selection_status = "completed");
        return filterParams;
    };

    // search action
    const [searchKeyword, setSearch] = useState("");
    const onSearchChange = (e) => {
        setSearch(e.target.value);
    };

    // api connection
    const fetchCandidates = () => {
        // const filterParams = getFilterParams();
        setIsLoading(true);
        // console.log(filterParams, "filterParams");
        jobifiedAuthConfig
            .get("/users/chief/candidates/", {
                params: {
                    page: pageNumber,
                    filter: searchParams.get("filter"),
                    sort_by: searchParams.get("sort_by"),
                },
            })
            .then((response) => {
                const { statusCode, data } = response.data;
                if (statusCode === 6000) {
                    setCandidateItems(data.data);
                    setPaginate({
                        endingCount: data.last_item,
                        startingCount: data.first_item,
                        totalCount: data.total_pages,
                        totalItems: data.total_items,
                    });
                    setFilterData(data);
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    //changing the selection status
    const changeSelectionStatus = (id, status) => {
        jobifiedAuthConfig
            .post(`/users/chief/candidates/selection-status/change/${id}/`, {
                selection_status: status,
            })
            .then((response) => {
                const { statusCode, data } = response.data;
                if (statusCode === 6000) {
                    fetchCandidates();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        fetchCandidates();
    }, [isDeleted, searchParams]);

    const [isSuccess, setSuccess] = useState(false);

    // delete candidates
    const deleteCollege = (id) => {
        setCandidateItems(candidateItems.filter((item) => item.id !== id));
    };

    return (
        <>
            <CreateCandidateModal
                isCreate={isCreate}
                setCreate={setCreate}
                isSuccess={isSuccess}
                setSuccess={setSuccess}
            />
            <SuccessModal isSuccess={isSuccess} setSuccess={setSuccess} />
            <CandidateFilter
                type="filter"
                isFilter={isFilter}
                setFilter={setFilter}
                activeClick={activeClick}
                setActiveClick={setActiveClick}
                status={status}
                setStatus={setStatus}
            />
            <Container>
                <Section>
                    <TopSection>
                        <Heading>Candidates</Heading>
                        <RightSection>
                            <SearchSection>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fsearch-normal.svg"
                                    }
                                    alt="search"
                                />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    onChange={onSearchChange}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            fetchCandidates();
                                            console.log(searchKeyword);
                                        }
                                    }}
                                />
                            </SearchSection>
                            <Edit onClick={() => setCreate(true)}>
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fplus.svg"
                                    }
                                    alt="plus"
                                />
                                Create Candidate
                            </Edit>
                            <Add onClick={() => setFilter(!isFilter)}>
                                Filter
                                <img
                                    src={
                                        "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fsort.svg"
                                    }
                                    alt="filter"
                                />
                            </Add>
                        </RightSection>
                    </TopSection>
                    <MiddleSection className={isLoading ? "loading" : ""}>
                        <Bottom>
                            {isLoading ? (
                                <SectionLoader status="followup_executive" />
                            ) : (
                                <Div>
                                    <TitleDiv>
                                        <Title>Sl No</Title>
                                        <Title>Candidate Name</Title>
                                        <Title>Email</Title>
                                        <Title>Course & Sem</Title>
                                        <Title>College Ref.Code</Title>
                                        <Title>Score</Title>
                                        <Title>Interview Status</Title>
                                        <Title>Mode</Title>
                                        <Title>Selection Status</Title>
                                        <Title>Action</Title>
                                    </TitleDiv>
                                    <Cover>
                                        <ListItemComponent
                                            deleteCollege={deleteCollege}
                                            candidateItems={candidateItems}
                                            setCandidateItems={
                                                setCandidateItems
                                            }
                                            setId={setId}
                                            startingCount={
                                                paginate.startingCount
                                            }
                                            isDeleted={isDeleted}
                                            setDeleted={setDeleted}
                                            changeSelectionStatus={
                                                changeSelectionStatus
                                            }
                                            paginate={paginate}
                                        />
                                    </Cover>
                                </Div>
                            )}
                            <BottomSection
                                className={isLoading ? "loading" : ""}
                            >
                                <Left>
                                    <LeftSection>
                                        Showing{" "}
                                        <span>{paginate.startingCount}</span> to{" "}
                                        <sapn>{paginate.endingCount}</sapn> of{" "}
                                        <span>{paginate.totalItems}</span>{" "}
                                        entries
                                    </LeftSection>
                                </Left>
                                <Right>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={paginate.totalCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationButtons"}
                                        previousLinkClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        breakLabel={"..."}
                                        disabledLinkClassName="disabled-button"
                                        breakLinkClassName="break-link"
                                        breakClassName={"item break-me "}
                                        nextClassName={"item next"}
                                    />
                                </Right>
                            </BottomSection>
                        </Bottom>
                    </MiddleSection>
                </Section>
            </Container>
        </>
    );
}

export default Candidates;

const Content = styled.div`
    z-index: 10000;
    position: absolute;
    top: 50%;
    right: 9%;
    width: 100px;
    height: 100px;
    box-shadow: -3px -2px 80px -12px #7f7f7f;
    padding: 15px;
    background-color: #fff;
`;
const Lists = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const ListItems = styled.div`
    cursor: pointer;
    font-size: 12px;
    color: #7f7f7f;
    font-family: gordita_medium;
`;
const Container = styled.div`
    padding: 70px 0 0 273px;
    @media all and (max-width: 1280px) {
        padding-left: 57px;
    }
`;
const Section = styled.div`
    padding: 0px 30px;
    @media all and (max-width: 1280px) {
        overflow: hidden;
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 26px;

    @media all and (max-width: 1280px) {
        font-size: 24px;
    }
`;
const RightSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 43%;
    @media all and (max-width: 1280px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 69%;
    }
`;
const SearchSection = styled.div`
    width: 40%;
    border-radius: 8px;
    border: 2px solid #c4c1c1;
    height: 35px;
    padding: 0 6px;
    display: flex;
    align-items: center;
    img {
        width: 20px;
        display: block;
        margin-right: 10px;
        cursor: pointer;
    }
    input {
        &::placeholder {
            color: #adadad;
            font-size: 14px;
        }
    }
`;
const Edit = styled.span`
    color: #f0f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: gordita_medium;
    font-size: 14px;
    height: 35px;
    width: 190px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    img {
        width: 25px;
        display: block;
        margin-right: 8px;
        @media all and (max-width: 1280px) {
            width: 18px;
            margin-right: 5px;
        }
    }
    @media all and (max-width: 1280px) {
        width: 170px;
    }
`;
const Add = styled.span`
    color: #747474;
    font-family: gordita_medium;
    font-size: 14px;
    display: flex;
    border: 2px solid #c4c1c1;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 35px;
    width: 90px;
    cursor: pointer;
    position: relative;
    img {
        margin-left: 8px;
        width: 20px;
    }
    @media all and (max-width: 1280px) {
        width: 80px;
    }
`;
const MiddleSection = styled.div`
    margin-top: 30px;
`;
const Bottom = styled.div`
    max-height: 81vh;
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    border-radius: 8px;
    color: #fff;
    height: fit-content;
    min-width: 1110px;
    padding: 20px;
    overflow: hidden;
    position: relative;
    @media all and (max-width: 1280px) {
        min-width: 967px;
    }
    @media all and (max-width: 1080px) {
        min-width: 874px;
    }
    @media all and (max-width: 980px) {
        min-width: 665px;
    }
`;
const Div = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media all and (max-width: 1280px) {
        overflow: scroll;
    }
`;
const TitleDiv = styled.ul`
    display: grid;
    min-width: 1142px;
    /* max-width: 1150px; */
    grid-template-columns: 2fr 4fr 4fr 4fr 3fr 2fr 4fr 2fr 4fr 1fr;
    grid-column-gap: 20px;
    margin-bottom: 25px;
    @media all and (max-width: 1280px) {
        min-width: 1115px;
    }
    @media all and (max-width: 1080px) {
        min-width: 1115px;
    }
`;
const Title = styled.li`
    font-size: 14px;
    color: #747474;
`;
const Cover = styled.div`
    overflow-y: scroll;
    height: calc(100vh - 320px);
    &::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 1280px) {
        height: calc(100vh - 318px);
        min-width: 1115px;
    }
`;

const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20px; */

    @media all and (max-width: 1280px) {
        margin-top: 18px;
    }
    @media all and (max-width: 1080px) {
        margin-top: 30px;
    }

    &.loading{
        display: none;
    }
`;
const LeftSection = styled.div`
    color: #747474;
    span {
        font-size: 14px;
    }
`;
const Left = styled.div``;
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    width: 290px;
    .paginationButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 290px;
        .disabled-button {
            cursor: not-allowed;
            /* background: #808080; */
        }
    }
    li {
        width: 30px;
        color: #747474;
        font-size: 14px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        a {
            width: 30px;
            color: #747474;
            font-size: 14px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            :hover {
                background: #2da99b;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
    .paginationActive > a {
        background: #2da99b;
        border-radius: 4px;
        color: #fff;
    }
`;
