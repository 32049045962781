import React, { useEffect } from "react";
import { useState } from "react";
import styled, { keyframes } from "styled-components";
import jobifiedAuthConfig from "../../../axiosConfig";

function DeleteModal({
    isDelete,
    setDelete,
    deleteCard,
    isId,
    setId,
    items,
    setItems,
    deleteHandler,
}) {
    // delete modal
    const DeleteCard = () => {
        jobifiedAuthConfig
            .post(`/orientations/chief/delete/${isId}`, {})
            .then((response) => {
                console.log(response.data.data);
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    console.log("video Deleted");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // video list
    const [videoName, setVideoName] = useState("");
    useEffect(() => {
        jobifiedAuthConfig
            .get(`/orientations/videos/${isId}`, {})
            .then((response) => {
                console.log(response.data.data);
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setVideoName(data?.title);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <Container>
            <Overlay></Overlay>
            <Section>
                <Heading>Delete media?</Heading>
                <Description>
                    Are you sure you want to delete “{videoName}”?You can’t undo
                    this action.
                </Description>
                <Div>
                    <Confirm
                        onClick={() => {
                            DeleteCard();
                            deleteCard(isId);
                            setDelete(false);
                            deleteHandler(isDelete);
                        }}
                    >
                        Confirm
                    </Confirm>
                    <Cancel onClick={() => setDelete(false)}>Cancel</Cancel>
                </Div>
            </Section>
        </Container>
    );
}

export default DeleteModal;
const videoAnimation = keyframes`
    0% { 
        transform:scale(0,0); 
        opacity:0;
     }
    100% { 
        transform:scale(1,1); 
        opacity:1;
     }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 1000;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
`;
const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 30%;
    max-width: 980px;
    max-height: 90vh;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 1280px) {
        max-height: 94vh;
        padding: 30px;
        width: 40%;
    }

    @media all and (max-width: 980px) {
        width: 58%;
    }
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 20px;
    margin-bottom: 30px;
`;
const Description = styled.p`
    color: #747474;
    text-align: center;
    font-size: 14px;
    margin-bottom: 30px;
`;

const Div = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Confirm = styled.div`
    background: #e02b1d;
    color: #fff;
    border-radius: 6px;
    width: 90px;
    font-size: 13px;
    cursor: pointer;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Cancel = styled.div`
    margin-left: 10px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    border-radius: 6px;
    border: 1px solid #32bcad;
    width: 90px;
    cursor: pointer;
    font-size: 13px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
