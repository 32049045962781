import React, { useRef, useState } from "react";
import { Cropper } from "react-cropper";
import styled from "styled-components";
import "cropperjs/dist/cropper.css";

const Crop = ({ closeHandler, submitHandler, image, property }) => {
    const [croppedImage, setImage] = useState("");

    let ratios = {
        orientationVideo: 16 / 9,
    };

    const cropperRef = useRef();

    const onCrop = (e) => {
        setImage(cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL());
    };

    return (
        <Overlay>
            <Content>
                <CropWrapper>
                    <Cropper
                        ref={cropperRef}
                        src={image}
                        aspectRatio={ratios[property]}
                        crop={onCrop}
                    />
                </CropWrapper>
                <SubmitWrapper>
                    <CancelButton onClick={closeHandler}>Cancel</CancelButton>
                    <Button
                        onClick={() => submitHandler(croppedImage, property)}
                    >
                        Done
                    </Button>
                </SubmitWrapper>
            </Content>
        </Overlay>
    );
};

export default Crop;

const Overlay = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    background-color: #80808072;
`;

const Content = styled.main`
    width: 600px;
    max-height: 80vh;
    padding: 32px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 22px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const CropWrapper = styled.div`
    width: 100%;
    max-width: 100%;
    max-height: 600px !important;

    .cropper-crop {
        width: 100% !important;
        height: 100% !important;
    }

    * {
        /* width: 100%; */
        max-height: 500px;
    }
    /* .cropper-face, .cropper-line, .cropper-point{
        width: 100% ;
        height: 100% !important;
    } */
`;

const SubmitWrapper = styled.footer`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
`;
const Button = styled.button`
    padding: 14px 32px;
    font-size: 16px;
    font-weight: 600;
    background-color: #32bcad;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
`;

const CancelButton = styled(Button)`
    background: none;
    border: 1px solid #32bcad;
    color: #32bcad;
`;
