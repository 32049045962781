import React from "react";
import styled from "styled-components";

function SelectionStatusModal({
    isSelectionStatusOpen,
    setSelectionStatusOpen,
    setSelectionStatus,
}) {
    const selectionStatusList = [
        {
            id: 1,
            value: "selected",
        },
        {
            id: 2,
            value: "pending",
        },
        {
            id: 3,
            value: "rejected",
        },
    ];
    return (
        <>
            {isSelectionStatusOpen && (
                <Container>
                    <Section>
                        <ListItem>
                            {selectionStatusList.map((data) => (
                                <Item
                                    onClick={() => {
                                        setSelectionStatus(data.value);
                                        setSelectionStatusOpen(
                                            !setSelectionStatusOpen
                                        );
                                    }}
                                >
                                    {data.value}
                                </Item>
                            ))}
                        </ListItem>
                    </Section>
                </Container>
            )}
        </>
    );
}

export default SelectionStatusModal;
const Container = styled.div`
    z-index: 100001;
    position: absolute;
    width: 330px;
    background: #fff;
    height: 100px;
    padding: 10px;
    overflow: scroll;
    left: 418px;
    bottom: 46px;
    border-radius: 5px;
    border: 1px solid #adadad;
    @media all and (max-width: 1280px) {
        width: 278px;
        left: 251px;
        top: 585px;
    }
    @media all and (max-width: 1080px) {
        width: 296px;
        left: 182px;
        top: 585px;
    }
    @media all and (max-width: 980px) {
        width: 260px;
        left: 112px;
        bottom: 21px;
    }
`;
const Section = styled.div`
    height: 90px;
    overflow: scroll;
`;
const ListItem = styled.div``;
const Item = styled.div`
    cursor: pointer;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 8px;
    &:hover {
        background-color: #adadad;
    }
`;
