import React from "react";
import styled, { keyframes } from "styled-components";
// datepicker
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import { getYYMMDDnew, toYYYYMMDD } from "../../helpers/HelperFunctions";

function AddInterviewModal({
    interview,
    setInterview,
    candidateItem,
    setCandidateModal,
    setAdding,
    fetchCampuses,
    isCandidateModal,
    adding,
    searchKeyword,
    candidates,
    setSelectedCandidateId2,
    setCollegeModal,
    isCollegeModal,
    setCandidates,
    setPages,
    isError,
    setCandidate,
    assignItem,
    setAssignModal,
    isAssignModal,
    setClicked,
    clicked,
    assigned,
    setAssignItem,
    setAssigned,
    setPage,
    calenderRef,
    startDate,
    setStartDate,
    minDate,
    onChange,
    value,
    setMode,
    mode,
    campuses,
    setCollegeItem,
    setCampuses,
    college,
    addInterviewSubmit,
    meetlink,
    assignName,
    setAssignName,
    onMeetlink,
    handleDateChange,
    selectedCandidateName,
    setSelectedCandidateName,
    assignClicked,
    setAssignClicked,
    selectedLocationName,
    setSelectedLocationName,
    locationClick,
    setLocationClick,
    typedCandidateName,
    setTypedCandidateName,
    typedAssignValue,
    setTypedAssignValue,
    typedLocationName,
    setTypedLocationName,
}) {
    return (
        <>
            {interview && (
                <Container>
                    <Overlay onClick={() => setInterview(!interview)}></Overlay>
                    <Section>
                        <TopSection>
                            <Heading>Add Interview</Heading>
                        </TopSection>
                        <MiddleSection>
                            <Contents>
                                <Label>Candidate name*</Label>
                                <Div className="assign">
                                    <Li
                                        style={{ fontSize: "14px" }}
                                        value={
                                            selectedCandidateName
                                                ? selectedCandidateName
                                                : typedCandidateName
                                        }
                                        placeholder="Candidate name"
                                        onClick={() => {
                                            setCandidateModal(
                                                !isCandidateModal
                                            );
                                            setAdding(!adding);
                                        }}
                                        onChange={(e) => {
                                            setTypedCandidateName(
                                                e.target.value
                                            );
                                            setCandidates([]);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                fetchCampuses();
                                                console.log(searchKeyword);
                                            }
                                        }}
                                    />

                                    <div
                                        id="candidate"
                                        className="list-page"
                                        style={{
                                            maxHeight: "150px",
                                            overflowY: "scroll",
                                            zIndex: "1",
                                            position: "absolute",
                                            border: "1px solid #adadad",
                                            borderRadius: "5px",
                                            width: "100%",
                                            top: "45px",
                                            left: "0",
                                            opacity: isCandidateModal
                                                ? "1"
                                                : "0",
                                        }}
                                    >
                                        {" "}
                                        {isCandidateModal && (
                                            <div
                                                id="innerCandidate"
                                                style={{
                                                    padding: "10px",
                                                    background: "#fff",
                                                    height: "100%",
                                                }}
                                            >
                                                {candidates?.map((data) => (
                                                    <div
                                                        className="list"
                                                        style={{
                                                            fontSize: "14px",
                                                            cursor: "pointer",
                                                            marginBottom: "5px",
                                                            padding: "2px",
                                                        }}
                                                        onClick={() => {
                                                            setSelectedCandidateId2(
                                                                data.id
                                                            );
                                                            setSelectedCandidateName(
                                                                data.name
                                                            );
                                                            setCandidateModal(
                                                                !isCandidateModal
                                                            );
                                                            setCandidates([]);

                                                            setPage(1);
                                                        }}
                                                    >
                                                        {data.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <Error
                                        className={
                                            isError && setCandidate === ""
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        This Field Required
                                    </Error>
                                </Div>{" "}
                            </Contents>
                            <Contents>
                                <Label>Assigned to</Label>
                                <Div className="assign">
                                    <Li
                                        style={{ fontSize: "14px" }}
                                        value={
                                            assignName
                                                ? assignName
                                                : typedAssignValue
                                        }
                                        placeholder="assign"
                                        onChange={(e) => {
                                            setTypedAssignValue(e.target.value);
                                            setAssigned([]);
                                        }}
                                        onClick={() => {
                                            setAssignModal(!isAssignModal);
                                            setAssignClicked(!assignClicked);
                                        }}
                                    />

                                    <div
                                        id="college"
                                        className="list-page"
                                        style={{
                                            maxHeight: "150px",
                                            overflowY: "scroll",
                                            zIndex: "1",
                                            position: "absolute",
                                            border: "1px solid #adadad",
                                            borderRadius: "5px",
                                            width: "100%",
                                            top: "45px",
                                            left: "0",
                                            opacity: isAssignModal ? "1" : "0",
                                        }}
                                    >
                                        {" "}
                                        {isAssignModal && (
                                            <div
                                                id="innerAssign"
                                                style={{
                                                    padding: "10px",
                                                    background: "#fff",
                                                    height: "100%",
                                                }}
                                            >
                                                {assigned.map((data) => (
                                                    <div
                                                        className="list"
                                                        style={{
                                                            fontSize: "14px",
                                                            cursor: "pointer",
                                                            marginBottom: "5px",
                                                            padding: "2px",
                                                            fontSize: "14px",
                                                        }}
                                                        onClick={() => {
                                                            setAssignItem(
                                                                data.id
                                                            );
                                                            setAssignName(
                                                                data.name
                                                            );
                                                            setAssignModal(
                                                                !isAssignModal
                                                            );
                                                            setAssigned([]);

                                                            setPage(1);
                                                        }}
                                                    >
                                                        {data.name}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <Error
                                        className={
                                            isError && assigned === ""
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        This Field Required
                                    </Error>
                                </Div>
                            </Contents>

                            <Contents>
                                <Label>Date</Label>
                                <Date
                                    onClick={(e) => {
                                        if (!calenderRef.current.state.open) {
                                            calenderRef.current.setOpen(true);
                                        }
                                    }}
                                >
                                    <img
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/calendar.svg"
                                        }
                                        alt="calender"
                                    />
                                    <CalenderBox>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) =>
                                                handleDateChange(date)
                                            }
                                            dateFormat={"yyyy-M-dd"}
                                            placeholderText={"YYYY - MM - DD"}
                                            minDate={minDate}
                                        />
                                    </CalenderBox>
                                    <DropDown className="date">
                                        <img
                                            src={
                                                "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified/Vector.svg"
                                            }
                                            alt="arrow"
                                        />
                                    </DropDown>
                                </Date>
                            </Contents>
                            <Contents>
                                <Label>Time</Label>
                                <Time>
                                    <TimeRightContainer>
                                        <TimePicker
                                            onChange={onChange}
                                            value={value}
                                            hourPlaceholder={"00"}
                                            minutePlaceholder={"00"}
                                        />
                                    </TimeRightContainer>
                                </Time>
                            </Contents>
                            <Contents className="mode">
                                <Label>Mode</Label>
                                <Modes>
                                    <Online onClick={() => setMode("online")}>
                                        <OuterCircle>
                                            <InnerCircle
                                                className={
                                                    mode === "online"
                                                        ? "active"
                                                        : ""
                                                }
                                            ></InnerCircle>
                                        </OuterCircle>
                                        Online
                                    </Online>
                                    <Offline
                                        onClick={() => {
                                            setMode("offline");
                                        }}
                                    >
                                        <OuterCircle>
                                            <InnerCircle
                                                className={
                                                    mode === "offline"
                                                        ? "active"
                                                        : ""
                                                }
                                            ></InnerCircle>
                                        </OuterCircle>
                                        Offline
                                    </Offline>
                                </Modes>
                            </Contents>

                            <Contents>
                                {mode === "offline" ? (
                                    <Label>Location</Label>
                                ) : (
                                    <Label>Meeting Link</Label>
                                )}
                                {mode === "offline" ? (
                                    <Div>
                                        <Li
                                            style={{ fontSize: "14px" }}
                                            value={
                                                selectedLocationName
                                                    ? selectedLocationName
                                                    : typedLocationName
                                            }
                                            placeholder="college"
                                            onChange={(e) => {
                                                setTypedLocationName(
                                                    e.target.value
                                                );
                                                setCampuses([]);
                                            }}
                                            onClick={() => {
                                                setCollegeModal(
                                                    !isCollegeModal
                                                );
                                                setLocationClick(
                                                    !locationClick
                                                );
                                            }}
                                        />

                                        <div
                                            id="college"
                                            className="list-page"
                                            style={{
                                                maxHeight: "150px",
                                                overflowY: "scroll",
                                                zIndex: "1",
                                                position: "absolute",
                                                border: "1px solid #adadad",
                                                borderRadius: "5px",
                                                width: "100%",
                                                top: "45px",
                                                left: "0",
                                                opacity: isCollegeModal
                                                    ? "1"
                                                    : "0",
                                            }}
                                        >
                                            {" "}
                                            {isCollegeModal && (
                                                <div
                                                    id="innerCollege"
                                                    style={{
                                                        padding: "10px",
                                                        background: "#fff",
                                                        height: "100%",
                                                    }}
                                                >
                                                    {campuses.map((data) => (
                                                        <div
                                                            className="list"
                                                            style={{
                                                                fontSize:
                                                                    "14px",
                                                                cursor: "pointer",
                                                                marginBottom:
                                                                    "5px",
                                                                padding: "2px",
                                                            }}
                                                            onClick={() => {
                                                                setCollegeItem(
                                                                    data.id
                                                                );
                                                                setSelectedLocationName(
                                                                    data.name
                                                                );
                                                                setCollegeModal(
                                                                    !isCollegeModal
                                                                );

                                                                setPages(1);
                                                            }}
                                                        >
                                                            {data.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <Error
                                            className={
                                                isError && college === ""
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            This Field Required
                                        </Error>
                                    </Div>
                                ) : (
                                    <Div>
                                        <Input
                                            type="text"
                                            placeholder="Link"
                                            onChange={onMeetlink}
                                            value={meetlink}
                                        />
                                    </Div>
                                )}
                            </Contents>
                        </MiddleSection>
                        <BottomContainer>
                            <Cancel
                                onClick={() => {
                                    setInterview(!interview);
                                }}
                            >
                                Cancel
                            </Cancel>
                            <Submit onClick={addInterviewSubmit}>Submit</Submit>
                        </BottomContainer>
                    </Section>
                </Container>
            )}
        </>
    );
}

export default AddInterviewModal;
const videoAnimation = keyframes`
 0% { transform:scale(0,0); opacity:0; }
 100% { transform:scale(1,1); opacity:1; }
`;
const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Overlay = styled.div`
    position: fixed;
    cursor: pointer;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
`;

const Section = styled.div`
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: ${videoAnimation} 0.6s;
    transition: 0.3s;
    transform: scale(1);
    margin: 0 auto;
    width: 40%;
    max-width: 980px;
    max-height: 90vh;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;

    @media all and (max-width: 1280px) {
        max-height: 94vh;
        padding: 30px;
        width: 50%;
    }
    @media all and (max-width: 1080px) {
        width: 56%;
    }
    @media all and (max-width: 980px) {
        width: 71%;
    }
`;
const UserInputs = styled.div`
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #adadad;
    padding: 6px 12px;
    border-radius: 8px;
    position: relative;

    &.valid-phone {
        border: 2px solid #32bcad;
    }

    @media all and (max-width: 1080px) {
        padding: 8px 12px;
    }

    @media all and (max-width: 360px) {
        padding: 8px 6px;
    }
`;
const CountryCode = styled.span`
    margin-right: 10px;
    font-size: 16px;
    font-family: "gordita_medium";
    @media all and (max-width: 768px) {
        margin-right: 4px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
`;

const DropDownSection = styled.div`
    width: 50px;
    margin-right: 10px;
    cursor: pointer;
    img {
    }
`;

const InputField = styled.input`
    flex: 1;
    font-size: 16px;
    top: 12px;
    font-family: "gordita_medium";
    &.phone_filed {
        @media all and (max-width: 768px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 980px) {
        max-width: 63%;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 13px;
    }
    &::placeholder {
        color: #adadad;
        font-family: "gordita_regular";
        font-size: 16px;
        @media all and (max-width: 768px) {
            font-size: 15px;
        }
        @media all and (max-width: 360px) {
            font-size: 13px;
        }
    }
`;
const TopSection = styled.div`
    border-bottom: 1px solid #d4d4d4;
`;
const Heading = styled.h2`
    color: #0a0a0a;
    font-size: 24px;
    padding-bottom: 25px;
    font-family: gordita_medium;
`;
const MiddleSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;
`;
const Contents = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &.mode {
        width: 100%;
    }
    .css-rh82gz-DropDown {
        width: 100%;
    }
    .css-wmy1p7-ReactDropdownSelect {
        border: none !important;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.css-wmy1p7-ReactDropdownSelec:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .css-wmy1p7-ReactDropdownSelect:focus,
    .css-wmy1p7-ReactDropdownSelect:focus-within {
        outline: none;
        box-shadow: 0 0 0;
    }
    .react-dropdown-select-input,
    ::placeholder {
        color: #d4d4d4 !important;
        font-family: "gordita_regular" !important;
        font-size: 16px !important;
    }
`;
const Label = styled.label`
    color: #747474;
    font-size: 16px;
    font-family: gordita_medium;
    margin-bottom: 8px;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
`;
const Input = styled.input`
    font-size: 15px;
    &::placeholder {
        color: #adadad;
    }

    &.assign {
        width: 96%;
    }
`;
const Inputs = styled.input`
    font-size: 14px;
    /* flex: 1;
    font-size: 18px;
    top: 12px;
    font-family: "gordita_medium"; */
`;

const ImgContainer = styled.div`
    width: 45px;
    img {
        width: 100%;
        display: block;
    }
`;
const Div = styled.div`
    padding: 0 5px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    position: relative;
    &.assign {
        display: block;
    }
    .css-rh82gz-DropDown {
        width: 100%;
    }
    .css-wmy1p7-ReactDropdownSelect {
        border: none !important;
        position: absolute;
        top: 0;
        left: 0;
    }
    &.css-wmy1p7-ReactDropdownSelec:focus {
        border: none !important;
        box-shadow: none !important;
    }
    .css-wmy1p7-ReactDropdownSelect:focus,
    .css-wmy1p7-ReactDropdownSelect:focus-within {
        outline: none;
        box-shadow: 0 0 0;
    }
    .react-dropdown-select-input,
    ::placeholder {
        color: #d4d4d4 !important;
        font-family: "gordita_regular" !important;
        font-size: 16px !important;
    }
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    &.email {
        position: relative;
    }
    &.active {
        border: 1px solid red;
    }
    &.number {
        align-items: center;
        padding: 0 5px;
    }
    span {
        font-size: 14px;
        margin: 0 5px;
    }
`;
const Li = styled.input`
    width: 100%;
    padding: 0 0 0 10px;
    height: 100%;
    color: #000;
    font-size: 14px;
    font-family: gordita_regular;
`;
const Error = styled.p`
    color: #e02b1d;
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    left: 0;
    display: none;
    &.active {
        display: block;
    }
`;
const Down = styled.img`
    width: 15px;
    display: block;
`;
const Date = styled.div`
    padding: 0 5px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    position: relative;
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    img {
        display: block;
        width: 25px;
        margin-right: 10px;
        @media all and (max-width: 640px) {
            width: 20px;
        }
    }
    span {
        font-size: 14px;
    }
`;
const CalenderBox = styled.div`
    width: 100%;
    border: none;
    outline: none;
    div.react-datepicker-wrapper > div {
        width: 100% !important;
        border: none !important;
    }
    .react-time-picker {
        box-sizing: border-box;
        border: none !important;
        width: 100% !important;
        display: flex;
        outline: none !important;
    }
    .react-time-picker__button:enabled {
        cursor: pointer;
        display: none !important;
    }
    .react-time-picker__wrapper {
        border: none !important;
    }
    .react-time-picker__inputGroup__input {
        cursor: pointer;
    }
    .react-time-picker__inputGroup__divider {
        margin-right: 6px !important;
    }

    .react-time-picker__clock {
        inset: 37px -11px 13px -6px !important;
    }
    .react-time-picker {
        @media all and (max-width: 640px) {
            height: 28px !important;
        }
    }
`;
const DropDown = styled.div`
    width: 10px;
    cursor: pointer;
    &.date {
        position: absolute;
        right: 8px;
        width: 14px;
    }
    img {
        display: block;
        width: 100%;
    }
    &.time {
        &.time {
            width: 10px;
        }
    }
`;
const TimeRightContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 18px;

    span {
        font-size: 15px;
    }
`;
const Img = styled.img`
    width: 25px;
    @media all and (max-width: 768px) {
        width: 23px;
        margin-right: 10px;
    }
    @media all and (max-width: 640px) {
        width: 20px;
    }
`;
const Time = styled.div`
    padding: 0 5px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    position: relative;
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    div.react-datepicker-wrapper > div > input {
        width: 100% !important;
    }
`;
const Modes = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Online = styled.div`
    padding: 0 5px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 48%;
    justify-content: flex-start;
    height: 40px;
    position: relative;
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const OuterCircle = styled.div`
    cursor: pointer;
    border: 1px solid #adadad;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
`;
const InnerCircle = styled.div`
    display: none;
    &.active {
        background: #009262;
        width: 13px;
        height: 13px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: block;
    }
`;
const Offline = styled.div`
    padding: 0 5px;
    background: #f9f9f9;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 48%;
    justify-content: flex-start;
    height: 40px;
    position: relative;
    @media all and (max-width: 1280px) {
        padding: 9px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const Location = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 10px;
    background: #f9f9f9;
    border: 1px solid #adadad;
    border-radius: 8px;
    margin-bottom: 35px;
    font-family: gordita_regular;
    width: 100%;
    color: #adadad;

    &.active {
        color: #adadad;
    }

    @media all and (max-width: 980px) {
        font-size: 14px;
    }
`;
const LocationBox = styled.div`
    border: 1px solid #adadad;
    z-index: 1000;
    max-height: 100px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -70px;
    background: #ffffff;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;
const List = styled.div`
    text-transform: capitalize;
    color: #000;
    font-family: gordita_regular;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;

    :hover {
        background-color: rgb(240 255 244);
    }
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const Cancel = styled.div`
    width: 110px;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747474;
    border: 1px solid #adadad;
    border-radius: 6px;
    margin-right: 20px;
`;
const Submit = styled.div`
    width: 110px;
    height: 40px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
`;
