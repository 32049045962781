import React, { useState } from "react";
import styled from "styled-components";

function SemesterModal({ isSemesterOpen, setIsSemesterOpen, setSemester }) {
    const semesterList = [
        {
            id: 1,
            value: "1",
        },
        {
            id: 2,
            value: "2",
        },
        {
            id: 3,
            value: "3",
        },
        {
            id: 4,
            value: "4",
        },
        {
            id: 5,
            value: "5",
        },
        {
            id: 6,
            value: "6",
        },
        {
            id: 7,
            value: "7",
        },
        {
            id: 8,
            value: "8",
        },
    ];
    return (
        <>
            {isSemesterOpen && (
                <Container>
                    <Section>
                        <ListItem>
                            {semesterList.map((data) => (
                                <Item
                                    onClick={() => {
                                        setSemester(data.value);
                                        setIsSemesterOpen(!isSemesterOpen);
                                    }}
                                >
                                    {data.value}
                                </Item>
                            ))}
                        </ListItem>
                    </Section>
                </Container>
            )}
        </>
    );
}

export default SemesterModal;
const Container = styled.div`
    z-index: 100001;
    position: absolute;
    width: 330px;
    background: #fff;
    height: 100px;
    padding: 10px;
    overflow: scroll;
    right: 420px;
    top: 286px;
    border-radius: 5px;
    border: 1px solid #adadad;
    @media all and (max-width: 1280px) {
        width: 278px;
        right: 251px;
        top: 272px;
    }
    @media all and (max-width: 1080px) {
        width: 294px;
        right: 183px;
        top: 272px;
    }
    @media all and (max-width: 980px) {
        width: 261px;
        right: 112px;
        top: 272px;
    }
`;
const Section = styled.div`
    height: 90px;
    overflow: scroll;
`;
const ListItem = styled.div``;
const Item = styled.div`
    cursor: pointer;
    border-radius: 5px;
    padding: 8px;
    &:hover {
        background-color: #adadad;
    }
`;
