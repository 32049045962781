import React from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

function CandidateFilter({
    isFilter,
    setFilter,
    activeClick,
    setActiveClick,
    type,
    status,
    setStatus,
}) {
    const [searchParams, setSearchParams] = useSearchParams();

    // interview status
    // const [isActive, setActive] = useState(false);
    // // selection status
    // const [active, setIsActive] = useState(false);

    const [activeItem,setActiveItem] = useState(null)

    return (
        <>
            {isFilter && (
                <Container>
                    <ListItems>
                        <Contents
                            className={status === "interview" ? "active" : ""}
                            onClick={() => {
                                setStatus("interview");
                                setActiveItem("interview_status")
                            }}
                        >
                            <Items>
                                {status === "interview" ? (
                                    <SelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                        }
                                    />
                                ) : (
                                    <UnSelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                        }
                                    />
                                )}

                                <h4>Interview Status</h4>
                            </Items>
                            <Img
                                onClick={() => {
                                }}
                                className={
                                    status === "interview" ? "active" : ""
                                }
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down.svg"
                                }
                                alt="drop down"
                            />
                        </Contents>
                        {activeItem === "interview_status" && (
                            <FilterItems>
                                <Item
                                    className={
                                        activeClick === "all" ? "active" : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"interview_status",
                                            sort_by: "all",
                                        });
                                        setActiveClick("all");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "all" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>All</Text>
                                </Item>
                                <Item
                                    className={
                                        activeClick === "pending"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"interview_status",
                                            sort_by: "pending",
                                        });
                                        setActiveClick("pending");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "pending" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>Pending</Text>
                                </Item>
                                <Item
                                    className={
                                        activeClick === "scheduled"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"interview_status",
                                            sort_by: "scheduled",
                                        });
                                        setActiveClick("scheduled");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "scheduled" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>Scheduled</Text>
                                </Item>
                                <Item
                                    className={
                                        activeClick === "completed"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"interview_status",
                                            sort_by: "completed",
                                        });
                                        setActiveClick("completed");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "completed" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>Completed</Text>
                                </Item>
                            </FilterItems>
                        )}
                    </ListItems>

                    <ListItems>
                        <Contents
                            onClick={() => {
                                setStatus("selection");
                                setActiveItem("selection_status")
                            }}
                            className={status === "selection" ? "two" : ""}
                        >
                            <Items>
                                {status === "selection" ? (
                                    <SelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                        }
                                    />
                                ) : (
                                    <UnSelectedImg
                                        src={
                                            "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                        }
                                    />
                                )}

                                <h4>Selection Status</h4>
                            </Items>
                            <Img
                                className={
                                    status === "selection" ? "active" : ""
                                }
                                src={
                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fdrop-down.svg"
                                }
                                alt="drop down"
                            />
                        </Contents>

                        {activeItem === "selection_status" && (
                            <FilterItem>
                                <Content
                                    className={
                                        activeClick === "all" ? "active" : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"selection_status",
                                            sort_by: "all",
                                        });
                                        setActiveClick("all");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "all" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>All</Text>
                                </Content>
                                <Content
                                    className={
                                        activeClick === "pending"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"selection_status",
                                            sort_by: "pending",
                                        });
                                        setActiveClick("pending");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "pending" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>Pending</Text>
                                </Content>
                                <Content
                                    className={
                                        activeClick === "selected"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"selection_status",
                                            sort_by: "selected",
                                        });
                                        setActiveClick("selected");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "selected" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>Selected</Text>
                                </Content>
                                <Content
                                    className={
                                        activeClick === "rejected"
                                            ? "active"
                                            : ""
                                    }
                                    onClick={() => {
                                        setSearchParams({
                                            filter:"selection_status",
                                            sort_by: "rejected",
                                        });
                                        setActiveClick("rejected");
                                        setFilter(!isFilter);
                                    }}
                                >
                                    <Span>
                                        {activeClick === "rejected" ? (
                                            <SelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Fselected.svg"
                                                }
                                            />
                                        ) : (
                                            <UnSelectedImg
                                                src={
                                                    "https://talrop-techies-park-assets.sgp1.digitaloceanspaces.com/jobified%2Funselected.svg"
                                                }
                                            />
                                        )}
                                    </Span>
                                    <Text>Rejected</Text>
                                </Content>
                            </FilterItem>
                        )}
                    </ListItems>
                </Container>
            )}
        </>
    );
}

export default CandidateFilter;
const SelectedImg = styled.img`
    width: 20px;
    display: block;
`;
const UnSelectedImg = styled.img`
    padding-right: 5px;
    margin-left: 2px;
    width: 20px;
    display: block;
`;
const Span = styled.span`
    display: inline-block;
    margin-right: 10px;
    width: 20px;
`;
const Text = styled.p`
    color: #adadad;
    font-size: 12px;
`;
const Container = styled.div`
    padding: 10px;
    z-index: 10000;
    width: 206px;
    position: absolute;
    top: 135px;
    right: 34px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.09);
    border-radius: 4px;
`;
const ListItems = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
    flex-direction: column;
`;
const Contents = styled.div`
    display: flex;
    padding: 6px;
    align-items: center;
    width: 100%;
    &.active {
        background: #eeeeee;
        border-radius: 4px;
    }
    &.two {
        background: #eeeeee;
        border-radius: 4px;
    }
`;
const FilterItems = styled.div`
    margin-top: 6px;
    width: 90%;
    margin-left: auto;
    &.active {
        display: block;
    }
`;
const FilterItem = styled.div`
    margin-top: 6px;
    width: 90%;
    margin-left: auto;
    &.active {
        display: block;
    }
`;
const Img = styled.img`
    width: 10px;
    display: none;
    &.active {
        display: block;
    }
`;
const Items = styled.div`
    font-size: 13px;
    width: 100%;
    display: flex;
    align-items: center;
`;
const Item = styled.div`
    font-size: 13px;
    width: 100%;
    padding: 4px;
    display: flex;
    align-items: center;
    color: #adadad;
    &.active {
        background: #eeeeee;
        border-radius: 4px;
    }
`;
const Content = styled.div`
    font-size: 13px;
    width: 100%;
    display: flex;
    padding: 4px;
    align-items: center;
    color: #adadad;
    &.active {
        background: #eeeeee;
        border-radius: 4px;
    }
`;
const OuterCircle = styled.div`
    cursor: pointer;
    border: 1px solid #adadad;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
`;
const InnerCircle = styled.div`
    display: none;
    &.active {
        background: #009262;
        width: 10px;
        height: 10px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: block;
    }
`;
const OuterDot = styled.div`
    cursor: pointer;
    border: 1px solid #adadad;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
`;
const InnerDot = styled.div`
    display: none;
    &.active {
        background: #009262;
        width: 8px;
        height: 8px;
        min-height: 10px;
        min-width: 10px;
        border-radius: 50%;
        display: block;
    }
`;
