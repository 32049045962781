import React, { useEffect, useState } from "react";
import styled from "styled-components";

import UploadModal from "../includes/modals/UploadModal";
// pagination
import ReactPaginate from "react-paginate";

import VideoCard from "../includes/VideoCard";
import JobifiedConfig from "../../axiosConfig";
import SectionLoader from "../helpers/SectionLoader";

function OrientationVideo() {
    const [isEdit, setEdit] = useState(false);
    const [isClose, setClose] = useState(false);
    const [isUpload, setUpload] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [paginate, setPaginate] = useState({
        startingCount: 0,
        endingCount: 0,
        totalCount: 0,
        totalItems: 0,
    });

    // card items
    const [items, setItems] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const changePage = ({ selected }) => {
        setPageNumber(selected + 1);
    };

    const fetchOrientations = () => {
        setIsLoading(true);

        JobifiedConfig.get("/orientations/chief/orientation-videos/", {
            params: {
                page: pageNumber,
            },
        })
            .then((response) => {
                console.log(response.data.data);
                const { statusCode, data } = response.data;

                if (statusCode === 6000) {
                    setItems(data.data);
                    setPaginate({
                        endingCount: data.last_item,
                        startingCount: data.first_item,
                        totalCount: data.total_pages,
                        totalItems: data.total_items,
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchOrientations();
    }, [pageNumber, refresh]);

    const uploadModalCloseHandler = (uploaded = false) => {
        setUpload(false);

        if (uploaded) {
            fetchOrientations();
        }
    };

    return (
        <>
            {isUpload && <UploadModal closeHandler={uploadModalCloseHandler} />}
            <Container>
                <Section>
                    <TopSection>
                        <Left>
                            <Heading>Orientation Videos</Heading>
                        </Left>
                        <Right>
                            <Edit
                                onClick={() => {
                                    setEdit(!isEdit);
                                }}
                            >
                                Edit
                            </Edit>
                            <Upload
                                onClick={() => {
                                    setUpload(!isEdit);
                                }}
                            >
                                Upload Video
                            </Upload>
                        </Right>
                    </TopSection>
                    <MiddleSection className={isLoading ? "loading" : ""}>
                        <Bottom>
                            {isLoading ? (
                                <SectionLoader status="followup_executive" />
                            ) : (
                                <Contents>
                                    <VideoCard
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                        isEdit={isEdit}
                                        setEdit={setEdit}
                                        isClose={isClose}
                                        setClose={setClose}
                                        items={items}
                                        setItems={setItems}
                                        startingCount={paginate.startingCount}
                                    />
                                </Contents>
                            )}

                            <Pages className={isLoading ? "loading" : ""}>
                                <Left>
                                    <LeftSection>
                                        Showing{" "}
                                        <span>{paginate.startingCount}</span> to{" "}
                                        <sapn>{paginate.endingCount}</sapn> of{" "}
                                        <span>{paginate.totalItems}</span>{" "}
                                        entries
                                    </LeftSection>
                                </Left>
                                <Right>
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        pageCount={paginate.totalCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationButtons"}
                                        previousLinkClassName={"previousButton"}
                                        nextLinkClassName={"nextButton"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        breakLabel={"..."}
                                        disabledLinkClassName="disabled-button"
                                        breakLinkClassName="break-link"
                                        breakClassName={"item break-me "}
                                        nextClassName={"item next"}
                                    />
                                </Right>
                            </Pages>
                        </Bottom>
                    </MiddleSection>
                </Section>
            </Container>
        </>
    );
}

export default OrientationVideo;
const Container = styled.div`
    padding: 70px 0 0 273px;
    @media all and (max-width: 1280px) {
        padding-left: 81px;
    }
`;
const Pages = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    &.loading {
        display: none;
    }
`;

const Section = styled.div`
    padding: 20px;
    min-height: 91vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;
const Heading = styled.h3`
    color: #0a0a0a;
    font-family: gordita_medium;
    font-size: 26px;
    margin-bottom: 8px;
    @media all and (max-width: 1280px) {
        font-size: 24px;
    }
    @media all and (max-width: 1080px) {
        font-size: 22px;
    }
    @media all and (max-width: 980px) {
        font-size: 20px;
    }
`;

const Contents = styled.div`
    gap: 18px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* height: 65vh; */

    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 1080px) {
        gap: 10px;
    }
    @media all and (max-width: 980px) {
        overflow-y: scroll;
        grid-template-columns: 1fr 1fr 1fr;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Left = styled.div``;
const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-end;
    width: 230px;
    .paginationButtons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;

        .disabled-button {
            cursor: not-allowed;
            /* background: #808080; */
        }
    }
    li {
        width: 30px;
        color: #747474;
        font-size: 14px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        a {
            width: 30px;
            color: #747474;
            font-size: 14px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            :hover {
                background: #2da99b;
                border-radius: 4px;
                color: #fff;
            }
        }
    }
    .paginationActive > a {
        background: #2da99b;
        border-radius: 4px;
        color: #fff;
    }
`;
const Edit = styled.span`
    color: #747474;
    font-family: gordita_medium;
    font-size: 14px;
    border: 1px solid #747474;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    border-radius: 6px;
    cursor: pointer;
`;
const Upload = styled.span`
    font-size: 14px;
    background: linear-gradient(98.46deg, #32bcad -24.84%, #289a8e 144.56%);
    border-radius: 6px;
    width: 150px;
    display: flex;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    height: 35px;
    color: #fff;
    cursor: pointer;
`;
const MiddleSection = styled.div`
    max-height: 81vh;
    height: 81vh;

    &.loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const Bottom = styled.div`
    max-height: 81vh;
    height: 81vh;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    color: #fff;
    /* height: fit-content; */
    padding: 20px 0;
    position: relative;
    justify-content: space-between;
`;

const LeftSection = styled.div`
    color: #adadad;
    span {
        font-size: 13px;
        color: #adadad;
    }
`;
